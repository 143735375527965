const en = {
    title: "Limitation of liability",
    subtitle: "Statement of Insured",
    points: {
        1 : "I have read and provided answers and actual information to the questions in Roojai’s Website and understand that all information contained in my answers to the questions in Roojai’s Website and all of its attachments (if any) are complete, correct, and valid and form the basis for Insured participation in this coverage and is an inseparable part of the Policy, therefore any misrepresentation can result in the cancellation of coverage and the rejection of any claims for indemnification and/or claims by the Insurer. I hereby declare that in making the statement above, I am in good health and is conscious.  If the statement as above is not true, the Insurer has the right to cancel/refuse to pay the insurance benefits.",
        2 : "I understand that the Insurer could deny liability under the insurance policy according to article 251 of the Indonesian Commercial Code (Wetboek van Koophandel).",
        3 : "I also agree that this application will form the basis of an insurance agreement between the Insurer and I. I agree that the Insurer can check the following information, including but not limited to the personal history of the main driver, information pertaining to the insured vehicle, motor vehicle insurance claim history, or any other information as necessary for this insurance policy.",
        4 : "I agree that the Insurer is entitled to perform investigation upon the incident relating to my claim, if necessary, and I hereby shall grant the permit for the Insurer to carry out such investigation",
        5 : "I understand that if I refuse to provide access to the personal history of the main driver, information pertaining to the insured vehicle, motor vehicle insurance claim history, or any other information required for closing my insurance policy, the Insurer may re-consider the benefits that I will receive and/or even refuse coverage under this policy.",
        6 : "If I am not the main driver, I confirm that I have received consent from the main driver to enter into an insurance contract and submit their personal information and those of any relevant person, according to the applicable laws and regulations on personal data protection in Indonesia.",
        7 : "I declare that I agree to receive the policy in the form of an electronic policy in PDF format (e-policy) which cannot be edited if provided by the Insurer and I acknowledge that this electronic policy acts as a legal document and is equivalent to a printed policy.",
        8 : "I have agreed to and have clearly understood all of the Insured rights and obligations in relation to this insurance product, including the terms and conditions in the Policy.",
        9 : "I agree that the insurance coverage will apply if this insurance application has been approved, the Premium has been received by Insurer, and the vehicle that will be insured has passed the vehicle inspection. If the Premium is not paid up to 30 (thirty) calendar days from the date of the Premium Invoice or according to the grace period determined by the Policy, or if I fail to carry out the vehicle inspection process, or if the vehicle which will be insured fails to pass the vehicle inspection, no coverage will be given and no benefits will be paid, unless otherwise determined by the Insurer.",
        10 : "I guarantee that the source of funds for premium payment to the Insurer does not come from the proceeds of a criminal act or if there is an indication of a violation of the laws and regulations concerning Anti Money Laundering and Prevention of Terrorism Funding, the Insurer will carry out its obligations in accordance with the applicable laws and regulations.",
        11 : "I agree that the Insurer can collect, use and disclose your personal information or those whom you represent to buy this product, to insurance agents, insurance brokers, other relevant third party, such as third-party service providers of the insurance company, supervisory bodies such as the Indonesian Financial Services Authority (Otoritas Jasa Keuangan/OJK), and other relevant government institution. This shall include the personal history of the main driver under this policy. The data can be used for underwriting and claims purposes or any other insurance arrangements and regulations of the industry.",
        next : "For more see the",
        tnc: "Terms and Condition"
    }
};

const id = {
    title: "Batasan tanggung jawab",
    subtitle: "Pernyataan Tertanggung",
    points: {
        1 : "Saya telah membaca dan memberikan jawaban serta keterangan yang sebenarnya atas pertanyaan-pertanyaan dalam Situs Web Roojai dan memahami bahwa seluruh informasi atau keterangan tercantum dalam jawaban saya terhadap pertanyaan-pertanyaan di Situs Web Roojai dan semua lampirannya (apabila ada) adalah lengkap, benar, dan sah serta menjadi dasar keikutsertaan Tertanggung pada pertanggungan ini dan merupakan bagian yang tidak terpisahkan dari Polis, oleh karenanya setiap pernyataan yang tidak benar dapat mengakibatkan batalnya pertanggungan dan ditolaknya setiap tuntutan ganti rugi dan/atau klaim oleh Penanggung. Saya menyatakan bahwa dalam memberikan pernyataan di atas, saya dalam keadaan sehat dan sadar. Apabila pernyataan diatas tersebut tidak benar, maka Penanggung berhak membatalkan/menolak membayar manfaat asuransi.",
        2 : "Saya memahami bahwa Penanggung dapat menyangkal kewajibannya berdasarkan polis asuransi menurut Pasal 251 dari Kitab Undang-undang Hukum Dagang (Wetboek van Koophandel).",
        3 : "Saya juga setuju bahwa permohonan ini akan menjadi dasar perjanjian asuransi antara Penanggung dan saya. Saya setuju bahwa Penanggung dapat memeriksa informasi sebagai berikut, yang mencakup namun tidak terbatas pada riwayat pribadi pengemudi utama, informasi sehubungan dengan kendaraan yang ditanggung, riwayat klaim asuransi kendaraan, atau informasi lain sebagaimana diperlukan untuk polis asuransi ini.",
        4 : "Saya setuju bahwa Penanggung berhak untuk melakukan investigasi sehubungan dengan kejadian/kecelakaan yang terkait dengan klaim saya, jika perlu, dan saya dengan ini memberikan izin kepada Penanggung untuk melakukan investigasi tersebut.",
        5 : "Saya memahami bahwa jika saya menolak memberikan akses atas riwayat pribadi pengemudi utama, informasi sehubungan dengan kendaraan yang ditanggung, riwayat klaim asuransi kendaraan, atau informasi lain yang diperlukan untuk melakukan penutupan polis asuransi saya, maka Penanggung dapat mempertimbangkan kembali manfaat yang akan saya terima dan/atau bahkan menolak pertanggungan berdasarkan polis ini.",
        6 : "Jika saya bukan pengemudi utama, saya menegaskan bahwa saya telah menerima persetujuan dari pengemudi utama untuk masuk ke dalam kontrak asuransi dan menyerahkan informasi pribadi mereka dan orang-orang yang relevan, sesuai dengan peraturan perundang-undangan di bidang perlindungan data pribadi yang berlaku di Indonesia.",
        7 : "Saya menyatakan setuju untuk menerima dokumen polis dalam bentuk polis elektronik berupa dokumen PDF (e-policy) yang tidak dapat disunting apabila disediakan oleh Penanggung dan Pemohon menyatakan mengerti bahwa polis elektronik ini berperan sebagai dokumen yang sah dan setara dengan polis dalam bentuk cetak",
        8 : "Saya telah mengetahui secara jelas, menyetujui dan memahami segala hak dan kewajiban Tertanggung sehubungan dengan produk asuransi ini, termasuk syarat dan ketentuan dalam Polis.",
        9 : "Saya menyetujui bahwa pertanggungan Asuransi akan berlaku apabila permohonan asuransi telah disetujui, Premi telah diterima oleh Penanggung, dan kendaraan yang akan ditanggung telah lulus inspeksi kendaraan. Apabila Premi tidak dilunasi sampai dengan waktu 30 (tiga puluh) hari kalender sejak tanggal Tagihan Premi atau sesuai masa grace period yang ditentukan Polis, atau saya tidak melakukan proses inspeksi kendaraan, atau kendaraan yang akan ditanggung tidak lulus inspeksi kendaraan, maka tidak ada pertanggungan yang akan diberikan dan tidak ada manfaat yang akan dibayarkan, kecuali ditentukan lain oleh Penanggung",
        10 : "Saya menjamin bahwa sumber dana untuk pembayaran premi kepada Penanggung bukan berasal dari hasil tindak pidana atau apabila terdapat indikasi pelanggaran atas peraturan dan ketentuan perundang-undangan tentang Anti Pencucian Uang dan Pencegahan Pendanaan Terorisme, maka Penanggung akan melaksanakan kewajibannya sesuai dengan peraturan dan ketentuan perundang-undangan yang berlaku.",
        11 : "Saya setuju bahwa Penanggung dapat mengumpulkan, menggunakan, dan mengungkapkan informasi pribadi saya atau yang saya wakili untuk membeli produk ini, kepada agen asuransi, pialang asuransi, pihak ketiga lain yang relevan, seperti penyedia jasa pihak ketiga dari perusahaan asuransi, badan pengawas seperti Otoritas Jasa Keuangan (OJK), dan lembaga pemerintah lainnya yang terkait. Hal ini mencakup riwayat pribadi dari pengemudi utama dari polis ini. Data tersebut dapat digunakan untuk tujuan underwriting dan klaim atau peraturan asuransi lainnya.",
        next : "Untuk lebih lanjut lihat",
        tnc: "Syarat dan Ketentuan"
    }
};

export default { en, id }