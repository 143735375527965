import { createRouter, createWebHashHistory } from "vue-router";
import Global from "@/store/global.js";

const routes = [
  {
    // path: "/ci-input",
    path: "/",
    name: "basicinfo",
    component: () => import(/* webpackChunkName: "basicinfo" */ "../views/critical-illness/BasicInfoB.vue"),
    beforeEnter(to, from, next) {
      Global.productConfig = Global.productConfigData.find(p => p.param === "health");
      next();
    }
  },
  {
    path: "/packages",
    name: "packages",
    // component: () => import(/* webpackChunkName: "packages" */ "../views/critical-illness/Packages.vue"),
    component: () => import(/* webpackChunkName: "packages" */ "../views/Packages.vue"),
    beforeEnter(to, from, next) {
      if (
        !Global.restServer.token ||
        !Global.roojaiService.token ||
        !Global.packages?.coversList?.cover
      ) {
        next(getFirstPage(to.path));
      } else {
        next();
      }
    }
  },
  {
    path: "/policydetails1",
    name: "policydetails1",
    alias: ['/pa-policydetails1'],
    // alias: ['/pa-policydetails1','/hcp-policydetails1'],
    component: () =>
      import(
        /* webpackChunkName: "policydetails1" */ "../views/PolicyDetails1.vue"
      ),
    beforeEnter(to, from, next) {
      if (
        !Global.restServer.token ||
        !Global.roojaiService.token ||
        Object.keys(Global.selectedPackages).length === 0
      ) {
        next(getFirstPage(to.path));
      } else {
        next();
      }
    }
  },
  {
    path: "/pa-input",
    name: "pa-basicinfo",
    component: () =>
      import(/* webpackChunkName: "pa-basicinfo" */ "../views/PA/BasicInfo.vue"),
    beforeEnter(to, from, next) {
      Global.productConfig = Global.productConfigData.find(p => p.param === "pa");
      next();
    }
  },
  {
    path: "/pa-packages",
    name: "pa-packages",
    component: () =>
      import(/* webpackChunkName: "pa-packages" */ "../views/PA/Packages.vue"),
    beforeEnter(to, from, next) {
      if (
        !Global.restServer.token ||
        !Global.roojaiService.token ||
        !Global.packages?.coversList?.cover
      ) {
        next(getFirstPage(to.path));
      } else {
        next();
      }
    }
  },
  // {
  //   path: "/",
  //   name: "hcp-basicinfo",
  //   alias: ['/hcp-input'],
  //   component: () =>
  //     import(/* webpackChunkName: "hcp-basicinfo" */ "../views/HCP/BasicInfo.vue"),
  //   beforeEnter(to, from, next) {
  //     Global.productConfig = Global.productConfigData.find(p => p.param === "hcp");
  //     next();
  //   }
  // },
  // {
  //   path: "/hcp-packages",
  //   name: "hcp-packages",
  //   component: () =>
  //     import(/* webpackChunkName: "hcp-packages" */ "../views/HCP/Packages.vue"),
  //   beforeEnter(to, from, next) {
  //     if (
  //       !Global.restServer.token ||
  //       !Global.roojaiService.token ||
  //       !Global.packages?.coversList?.cover
  //     ) {
  //       next(getFirstPage(to.path));
  //     } else {
  //       next();
  //     }
  //   }
  // },
  {
    path: "/payment",
    name: "payment",
    alias: ['/pa-payment','/hcp-payment'],
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/Payment.vue"),
    beforeEnter(to, from, next) {
      if (
        (!Global.restServer.token ||
          !Global.roojaiService.token ||
          !Global.isOTPValid ||
          !Global.quote.opportunityNumber) &&
        Global.isRetrieveToPayment !== true
      ) {
        next(getFirstPage(to.path));
      } else {
        next();
      }
    }
  },
  {
    path: "/payment-info",
    name: "payment-info",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/CheckLastPayment.vue"),
    beforeEnter(to, from, next) {
      if (
        (!Global.restServer.token ||
          !Global.roojaiService.token ||
          !Global.isOTPValid ||
          !Global.quote.opportunityNumber) &&
        Global.isRetrieveToPayment !== true
      ) {
        next(getFirstPage(to.path));
      } else {
        next();
      }
    }
  },
  {
    path: "/payment-info-success",
    name: "payment-info-success",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/LastPaymentSuccess.vue"),
    beforeEnter(to, from, next) {
      if (
        (!Global.restServer.token ||
          !Global.roojaiService.token ||
          !Global.isOTPValid ||
          !Global.quote.opportunityNumber) &&
        Global.isRetrieveToPayment !== true
      ) {
        next(getFirstPage(to.path));
      } else {
        next();
      }
    }
  },
  {
    path: "/thankyou",
    name: "thankyou",
    alias: ['/pa-thankyou', '/hcp-thankyou'],
    component: () =>
      import(/* webpackChunkName: "thankyou" */ "../views/Thankyou.vue"),
      beforeEnter(to, from, next) {
        if (
          (!Global.restServer.token ||
            !Global.roojaiService.token)
        ) {
          next(getFirstPage(to.path));
        } else {
          next();
        }
      }
  },
  {
    path: "/thankyou2",
    name: "thankyou2",
    alias: ['/pa-thankyou2', '/hcp-thankyou2'],
    component: () =>
      import(/* webpackChunkName: "thankyou" */ "../views/Thankyou2.vue"),
      beforeEnter(to, from, next) {
        if (
          (!Global.restServer.token ||
            !Global.roojaiService.token)
        ) {
          next(getFirstPage(to.path));
        } else {
          next();
        }
      }
  },
  {
    path: "/blockpolicy",
    name: "blockpolicy",
    alias: ['/pa-blockpolicy', '/hcp-blockpolicy'],
    component: () =>
      import(/* webpackChunkName: "blockpolicy" */ "../views/Blockpolicy.vue"),
      beforeEnter(to, from, next) {
        if (
          (!Global.restServer.token ||
            !Global.roojaiService.token)
        ) {
          next(getFirstPage(to.path));
        } else {
          next();
        }
      }
  },
  {
    path: "/review",
    name: "review",
    alias: ['/pa-review', '/hcp-review'],
    component: () =>
      import(/* webpackChunkName: "review" */ "../views/Review.vue"),
      beforeEnter(to, from, next) {
        if (
          (!Global.restServer.token ||
            !Global.roojaiService.token)
        ) {
          next(getFirstPage(to.path));
        } else {
          next();
        }
      }
  },
  {
    path: "/debitcard",
    name: "debitcard",
    alias: ['/pa-debitcard', '/hcp-debitcard'],
    component: () =>
      import(/* webpackChunkName: "debitcard" */ "../views/debitcard.vue"),
      beforeEnter(to, from, next) {
        if (
          (!Global.restServer.token ||
            !Global.roojaiService.token)
        ) {
          next(getFirstPage(to.path));
        } else {
          next();
        }
      }
  },
  {
    path: "/feefo",
    name: "feefo",
    alias: ['/pa-feefo', '/hcp-feefo'],
    component: () =>
      import(/* webpackChunkName: "feefo" */ "../views/feefo.vue"),
      // beforeEnter(to, from, next) {
      //   if (
      //     (!Global.restServer.token ||
      //       !Global.roojaiService.token)
      //   ) {
      //     next(getFirstPage(to.path));
      //   } else {
      //     next();
      //   }
      // }
  },
  {
    path: "/policydetails2",
    name: "policydetails2",
    alias: ['/pa-policydetails2', '/hcp-policydetails2'],
    component: () =>
      import(/* webpackChunkName: "policydetails2" */ "../views/PolicyDetails2.vue"),
      beforeEnter(to, from, next) {
        if (
          (!Global.restServer.token ||
            !Global.roojaiService.token)
        ) {
          next(getFirstPage(to.path));
        } else {
          next();
        }
      }
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/"
  },
  {
    path: "/waitingpayment",
    name: "waitingpayment",
    alias: ['/pa-waitingpayment', '/hcp-waitingpayment'],
    component: () =>
      import(/* webpackChunkName: "waitingpayment" */ "../views/WaitingPayment.vue"),
      beforeEnter(to, from, next) {
        if (
          (!Global.restServer.token ||
            !Global.roojaiService.token)
        ) 
        {
          next(getFirstPage(to.path));
        }
         else {
          next();
        }
      }
  },
  {
    path: "/debit/thankyou",
    name: "debit-thankyou",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DebitThankyou.vue")
  },
  {
    path: "/product",
    name: "product",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Product.vue"),
      // beforeEnter(to, from, next) {
      //   if (
      //     (!Global.restServer.token ||
      //       !Global.roojaiService.token)
      //   ) 
      //   {
      //     next(getFirstPage(to.path));
      //   }
      //    else {
      //     next();
      //   }
      // }
  },
  {
    path: "/product/affiliate",
    name: "product-affiliate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductAffiliate.vue"),
      // beforeEnter(to, from, next) {
      //   if (
      //     (!Global.restServer.token ||
      //       !Global.roojaiService.token)
      //   ) 
      //   {
      //     next(getFirstPage(to.path));
      //   }
      //    else {
      //     next();
      //   }
      // }
  },
  {
    path: "/debit/failure",
    name: "debit-failure",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DebitThankyou.vue")
  },
  {
    path: "/mv",
    name: "carInsure",
    redirect: {name: 'mv-input'},
    component: () =>
      import(/* webpackChunkName: "motor-vehicle" */ "../views/motor-vehicle/IndexPage.vue"),
      children:[
        {
          path:"input",
          name: "mv-input",
          component: () =>
            import(/* webpackChunkName: "mv-input" */ "../views/motor-vehicle/BasicInfo.vue"),
        },
        {
          path:"packages",
          name: "mv-packages",
          component: () =>
            import(/* webpackChunkName: "mv-packages" */ "../views/motor-vehicle/Packages.vue"),
            beforeEnter(to, from, next) {
              if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
                next({ name: "mv-input" });
              } else {
                next();
              }
            },
        },
        {
          path:"policydetails",
          name: "mv-policydetails",
          component: () =>
            import(/* webpackChunkName: "mv-policydetails" */ "../views/motor-vehicle/PolicyDetails.vue"),
            beforeEnter(to, from, next) {
              if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
                next({ name: "mv-input" });
              } else {
                next();
              }
            },
        },
        {
          path:"payment",
          name: "mv-payment",
          component: () =>
            import(/* webpackChunkName: "mv-payment" */ "../views/motor-vehicle/Payment.vue"),
            beforeEnter(to, from, next) {
              if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
                next({ name: "mv-input" });
              } else {
                next();
              }
            },
        },
        {
          path:"waitingpayment",
          name: "mv-waitingpayment",
          component: () =>
            import(/* webpackChunkName: "mv-payment" */ "../views/motor-vehicle/WaitingPayment.vue"),
            beforeEnter(to, from, next) {
              if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
                next({ name: "mv-input" });
              } else {
                next();
              }
            },
        },
        {
          path:"thankyou",
          name: "mv-thankyou",
          component: () =>
            import(/* webpackChunkName: "mv-policydetails" */ "../views/motor-vehicle/Thankyou.vue"),
            beforeEnter(to, from, next) {
              if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
                next({ name: "mv-input" });
              } else {
                next();
              }
            },
        },
        {
          path:"redeem",
          name: "mv-redeem",
          component: () =>
            import(/* webpackChunkName: "mv-policydetails" */ "../views/motor-vehicle/Redeem.vue"),
            // beforeEnter(to, from, next) {
            //   if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
            //     next({ name: "mv-input" });
            //   } else {
            //     next();
            //   }
            // },
        },
        {
          path:"policydetails2",
          name: "mv-policydetails2",
          component: () =>
            import(/* webpackChunkName: "mv-policydetails" */ "../views/motor-vehicle/PolicyDetails2.vue"),
            beforeEnter(to, from, next) {
              if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
                next({ name: "mv-input" });
              } else {
                next();
              }
            },
        },
        {
          path:"thankyou2",
          name: "mv-thankyou2",
          component: () =>
            import(/* webpackChunkName: "mv-policydetails" */ "../views/motor-vehicle/Thankyou2.vue"),
            beforeEnter(to, from, next) {
              if ((!Global.restServer.token || !Global.roojaiService.token) && !Global.motorVehicle.isPackagesStage) {
                next({ name: "mv-input" });
              } else {
                next();
              }
            },
        },
      ]
    }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (
    [Global.productConfig?.route.basicinfo,
    Global.productConfig?.route.packages,
    Global.productConfig?.route.policydetails1,
    Global.productConfig?.route.payment].indexOf(to.path) >
    -1 &&
    Global.paymentCompleted === true &&
    Global.tp === "nb" &&
    Global.quote.isActivatePolicy !== true
  ) {
    next(Global.productConfig?.route.thankyou);
  } else if (
    to.name !== "review" &&
    Global.paymentCompleted === true &&
    Global.quote.isActivatePolicy === true
  ) {
    next(Global.productConfig.route.review);
  } else {
    next();
  }
});

export default router;

function getFirstPage(currentPath){
  var firstPage = Global.productConfigData.find(function(p){
    for(var r in p?.route)
      if(p.route[r] == currentPath) return p;
  });
  return firstPage.route.basicinfo;
}