<script>
import Global from "@/store/global.js";
import axios from "axios";
import dayjs from "@/dayjs";
import accounting from "accounting-js";
import { policyObject } from "../views/motor-vehicle/data/PolicyObject";

export default {
  directives:{
    birthdate:{
      created(el){
        el.addEventListener("input",function(){
          el.value = el.value.replace(/[^0-9\/]/g, '');
        })
      }
    },
    numberOnly:{
      created(el){
        el.addEventListener("input",function(){
            el.value = el.value.replace(/[^0-9]/g, '');
        })
      }
    }
  },
  data() {
    return {
      Global
    };
  },
  methods: {
    async setLeadSource(){
      var vm = this;
      if(Global.decryptReferralLink == '456gdsgudgsdyiisd34ds'){
        Global.quote.channel = 'ALODOKTER'
        sessionStorage.setItem("channel", Global.quote.channel);
      }else if(Global.decryptReferralLink == '742ykj67jlhay57hjkg') {
        Global.quote.channel = 'EMERHUB'
        sessionStorage.setItem("channel", Global.quote.channel);
      }else if(Global.decryptReferralLink == 'fter6754urhjgf'){
        Global.quote.channel = 'TMC Bellagio'
        sessionStorage.setItem("channel", Global.quote.channel);
      }else if(Global.decryptReferralLink == 'f3144rkdi20tte'){
        Global.quote.channel = 'TMC BSD'
        sessionStorage.setItem("channel", Global.quote.channel);
      }else{
        await vm.getDecryptReferral(Global.decryptReferralLink)
      }
    },
    async validateRenewalPayment(opportunityNumber){
      var vm = this;
      vm.loading("show");
      try {
        const response = await vm
          .serviceRequest()
          .post(Global.roojaiService.url + "/payments/validateRenewalPayment",{opportunityNumber});
        Global.validateRenewalPayment = response.data
        vm.loading("hide");
        return response.data
      } catch (error) {
        vm.loading("hide");
        vm.errorModal(vm.$t("error.connection.message"));
        return false;
        console.error("checkInstalmentLastPayment : ", error);
      }
    },
    scrollToTopPage(){
      $("html, body").animate({ scrollTop: 0 }, 1000)
    },
    isMobile: function() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    focusTextInput: function(el) {
      // var __tempEl__ = document.createElement('input');
      // __tempEl__.style.position = 'relative';
      // __tempEl__.style.top = el.offsetTop + 'px';
      // __tempEl__.style.left = el.offsetLeft + 'px';
      // __tempEl__.style.height = 0 + '!important';
      // __tempEl__.style.opacity = 0;
      // el.after(__tempEl__);
      // __tempEl__.focus()
      // __tempEl__.click()
      // setTimeout(() => {
      //   el.focus()
      //   el.focus()
      //   el.click()
      //   document.body.removeChild(__tempEl__)
      // }, 50);
      var __tempEl__ = document.createElement('input');

        if (el) {
            // Function to focus on the target element and remove the observer
            function focusOnElementAndCleanup() {
                el.focus();
                el.click();
                // Conditionally check these two as we only set them up when the target input is invisible.
                if (document.body.contains(__tempEl__)) { document.body.removeChild(__tempEl__); } // Remove the temporary element
                if (observer) { observer.disconnect(); }// Cleanup the observer
            }

            // Check if the target element is already visible
            if (isVisible(el)) {
                focusOnElementAndCleanup();
            } else {
                focusOnDummyElementToOpenIOSKeyboard();
                // Create a MutationObserver to watch for changes in the DOM
                var observer = new MutationObserver(function (mutationsList) {
                    for (var mutation of mutationsList) {
                        if (mutation.type === 'childList' && isVisible(el)) {
                            focusOnElementAndCleanup();
                            break;
                        }
                    }
                });

                // Start observing changes in the parent node (you can change this to a more appropriate parent)
                observer.observe(document.body, { childList: true, subtree: true });
            }

            // Create a temporary input element to focus on and open the keyboard
            function focusOnDummyElementToOpenIOSKeyboard() {
                __tempEl__.style.position = 'absolute';
                __tempEl__.style.top = (el.offsetTop + 7) + 'px';
                __tempEl__.style.left = el.offsetLeft + 'px';
                __tempEl__.style.height = 0;
                __tempEl__.style.opacity = 0; // Set opacity to 0 to make it invisible
                document.body.appendChild(__tempEl__);
                __tempEl__.focus();
            }

        }


        // Function to check if the element is visible in the DOM
        function isVisible(element) {
            return element && element.offsetParent !== null;
        }
    },
    makeToast(message) {
      this.$toast.open({
        message: message,
        type: "info",
        position: "top-right",
        duration: 5000,
        dismissible: true
      })
    },
    formatNumber: function(price, precision) {
      return accounting.formatNumber(price, { precision: precision, thousand : ".", decimal : "," });
    },
    ele: function(ele) {
      var dom = document.querySelector(ele) ? document.querySelector(ele) : {};

      if (dom.tagName) {
        dom.exist = true;
      } else {
        dom.exist = false;
      }

      dom.show = function() {
        if (dom.exist) {
          this.style.display = "block";
        }
      };
      dom.hide = function() {
        if (dom.exist) {
          this.style.display = "none";
        }
      };

      dom.overflow = function(value) {
        if (dom.exist) {
          this.style.overflow = value;
        }
      };

      dom.overflowX = function(value) {
        if (dom.exist) {
          this.style.overflowX = value;
        }
      };

      dom.overflowY = function(value) {
        if (dom.exist) {
          this.style.overflowY = value;
        }
      };

      dom.addClass = function(_class_) {
        this.classList.add(_class_);
        return dom;
      };

      dom.removeClass = function(_class_) {
        this.classList.remove(_class_);
        return dom;
      };

      dom.isShow = function() {
        if (dom.exist) {
          return this.style.display != "none"
        }

        return false;
      };

      return dom;
    },
    loading: function(action, sec, callback) {
      var vm = this;

      if (action == "show") {
        vm.ele("body").addClass("modal-open");
        vm.ele("#loading").show();

        if (Global.isLoading) {
          Global.isLoading.push(true);
        } else {
          Global.isLoading = [true];
        }
      } else {
        if (Global.isLoading && Global.isLoading.length) {
          Global.isLoading.shift();
        }

        if (!Global.isLoading || Global.isLoading.length < 1) {
          vm.ele("body").removeClass("modal-open");
          vm.ele("#loading").hide();
        }
      }

      if (typeof callback == "function" && !sec) {
        callback();
      }

      if (typeof sec == "function") {
        sec();
      }

      if (typeof sec == "number" && typeof callback == "function") {
        setTimeout(function() {
          if (Global.isLoading) {
            Global.isLoading.shift();
          }

          if (!Global.isLoading || Global.isLoading.length < 1) {
            vm.ele("body").removeClass("modal-open");
            vm.ele("#loading").hide();
          }

          callback();
        }, parseInt(sec) * 1000);
      }
    },
    is_yyyymmdd: function(d) {
      if (!d) {
        return false;
      }

      var yyyymmdd = /^(\d{4})[\/\-](0[1-9]|1[0-2])[\/\-](0[1-9]|(1|2)[0-9]|3[01])/g;
      return yyyymmdd.test(d);
    },
    is_ddmmyyyy: function(d) {
      if (!d) {
        return false;
      }

      var ddmmyyyy = /^(0[1-9]|(1|2)[0-9]|3[01])[\/\-](0[1-9]|1[0-2])[\/\-](\d{4})/g;
      return ddmmyyyy.test(d);
    },
    isDateValid: function(d) {
      return (this.is_yyyymmdd(d) && dayjs(d, "YYYY/MM/DD", true).isValid()) || (this.is_ddmmyyyy(d) && dayjs(d, "DD/MM/YYYY", true).isValid()) || ( dayjs(d, "D/M/YYYY", true).isValid()) ? d : false;
    },
    date: function(d) {
      var vm = this;

      function toYYYYMMDD(d) {
        if (vm.is_ddmmyyyy(d)) {
          // convert ddmmyyyy to yyyymmdd
          d = d
            .replace(/[\/\-]/g, "*")
            .split("*")
            .reverse()
            .join("/");
        }
        return d;
      }

      var lib = dayjs(toYYYYMMDD(d));

      lib.isBC = function() {
        var baseTime = dayjs(Global.serverTime);
        return this.diff(baseTime, "year") > 300;
      };

      lib.isAD = function() {
        var baseTime = dayjs(Global.serverTime);
        return this.diff(baseTime, "year") < 300;
      };

      lib.toAD = function() {
        if (!this.isAD()) {
          var yymmdd = this.subtract(543, "year").format("YYYY/MM/DD");
          return vm.date(yymmdd);
        }

        return this;
      };

      lib.toBC = function() {
        if (!this.isBC()) {
          var yymmdd = this.add(543, "year").format("YYYY/MM/DD");
          return vm.date(yymmdd);
        }

        return this;
      };

      return lib;
    },
    dob2age: function(dob) {
      if (!this.isDateValid(dob)) {
        return 0;
      }

      var base = this.date(Global.serverTime);
      var input = this.date(dob);
      input = input.toAD();

      if (input.isSameOrAfter(base)) {
        return 0;
      }

      return base.diff(input, "year");
    },
    reFormatDate: function(newValue, oldValue) {
      if (!newValue || !oldValue) {
        return newValue;
      }

      newValue = newValue.replace(/[^\d\/]/g, "").substring(0, 10);
      oldValue = oldValue.replace(/[^\d\/]/g, "").substring(0, 10);

      if (newValue.length > oldValue.length) {
        newValue = newValue.replace(/[^\d]/g, "");

        if (newValue.length >= 4) {
          newValue =
            newValue.substring(0, 2) +
            "/" +
            newValue.substring(2, 4) +
            "/" +
            newValue.substring(4, 8);
        } else if (newValue.length >= 2) {
          newValue = newValue.substring(0, 2) + "/" + newValue.substring(2, 4);
        }
      }
      return newValue;
    },
    request: function(config) {
      var defaultConfig = {
        headers: {
          Accept: "application/json"
        }
      };

      if (Global.restServer.token) {
        defaultConfig.headers.Authorization =
          "Bearer " + Global.restServer.token;
      }

      if (typeof config == "object" && config.headers) {
        defaultConfig = config;
      }

      var req = axios.create(defaultConfig);
      return req;
    },
    getToken: async function() {
      var vm = this;

      vm.loading("show");

      try {
        const response = await vm
          .request()
          .post(Global.restServer.url + "/login", {
            username: Global.restServer.username,
            password: Global.restServer.password
          });

        Global.restServer.token = response.data.access_token;

      } catch (error) {
        vm.loading("hide");
        vm.errorModal(vm.$t("error.connection.message"));
        console.error("getToken : ", error);
      }
    },
    getConfigurations: async function(_productType) {
      var vm = this;
      if(Global.serverTime && !_productType){
        vm.loading("hide");
      }else{
        try{
          if(!_productType){
            Global.productConfig = Global.productConfig ? Global.productConfig : Global.productConfigData.find(
              p => (!$.isEmptyObject(this.$route.query.product_type) && this.$route.query.product_type.toLowerCase() === p.param) ||
              (!$.isEmptyObject(this.$route.query.productType) && this.$route.query.productType.toLowerCase() === p.param));
            if((!$.isEmptyObject(this.$route.query.product_type) || !$.isEmptyObject(this.$route.query.productType)) && !Global.productConfig){
              vm.loading("hide");
              return false;
            }
            Global.productConfig = Global.productConfig ? Global.productConfig : Global.productConfigData.find(p => p.param === "health");
            Global.quote.productType = Global.productConfig.productType;
          }else{
            Global.productConfig = Global.productConfigData.find(p => p.productType === _productType);
          }

          var configurations = await axios.get(Global.restServer.url + "/configurations", {
            params: { product_type: Global.productConfig.productType, ABTesting:Global.quote.abTestingVersion },
            headers: { Authorization: "Bearer " + Global.restServer.token }
          });

          Global.abtest = configurations.data.ABTesting; // Set Global AB Testing
          Global.quote.abTestingVersion = Global.abtest;

          Global.serverTime = configurations?.data?.serverTime;
          Global.isTeleselling = configurations?.data?.isTeleselling;
          Global.xenditPublicKey = configurations?.data?.xenditPublicKey;
          Global.enableCoverList = configurations?.data?.enableCoverList;
          if(!_productType) vm.loading("hide");
        } catch (error) {
          console.error(error);
          vm.loading("hide");
          vm.errorModal(vm.$t("error.connection.message"));
        }
      }
      return true;
    },
    serviceRequest: function(config) {
      var defaultConfig = {
        headers: {
          Accept: "application/json"
        }
      };

      if (Global.roojaiService.token) {
        defaultConfig.headers.Authorization =
          "Bearer " + Global.roojaiService.token;
      }

      if (typeof config == "object" && config.headers) {
        defaultConfig = config;
      }

      var req = axios.create(defaultConfig);
      return req;
    },
    getServiceToken: async function() {
      var vm = this;

      vm.loading("show");
      //document.querySelector("#loading").style.display = 'block';

      try {
        const response = await vm
          .serviceRequest()
          .post(Global.roojaiService.url + "/login", {
            username: Global.roojaiService.username,
            password: Global.roojaiService.password
          });
        Global.roojaiService.token = response.data.access_token;
        Global.roojaiService.rjses = response.data.rj;
        vm.loading("hide");
        //document.querySelector("#loading").style.display = 'none';
      } catch (error) {
        vm.loading("hide");
        vm.errorModal(vm.$t("error.connection.message"));
        console.error("getServiceToken : ", error);
        //document.querySelector("#loading").style.display = 'none';
      }
    },
    sendWhatsApp: async function() {
      var vm = this;

      vm.loading("show");

      try {
        const response = await vm
          .serviceRequest()
          .post(Global.roojaiService.url + "/whatsapp/sendMessage", {
            to: Global.phaccount.phoneNumber,
            body: 'Hai, terima kasih atas minat kamu kepada Roojai. Balas chat ini dan dapatkan penawaran kamu sekarang!'
          });

        var response_service = response.data;
        if(response_service.model.status != "queued"){
          Global.phaccount.sendWaStatus = 'failed';
          vm.errorModal(vm.$t("error.whatsapp.message"));
          vm.loading("hide");
        }else if(response_service.model.status == "queued"){
          Global.phaccount.sendWaStatus = 'success';
          vm.loading("hide");
        }
        //document.querySelector("#loading").style.display = 'none';
      } catch (error) {
        vm.loading("hide");
        vm.errorModal(vm.$t("error.connection.message"));
        console.error("sendWhatsapp : ", error);
        //document.querySelector("#loading").style.display = 'none';
      }
    },
    getPlans: function() {
      var coverList = [],
        healthQuestionList = [],
        hardblockCover = [];
      Global.quote.preferredOption = "";
      if(Global.radarCallType == "2"){
        for (const [key, value] of Object.entries(Global.selectedPackages)) {
          if(!this.coverHardblock(key)){
            var coverages = [];
            value?.coverages?.coverage?.forEach(coverage => {
              // if (coverage?.additionalCoverageName?.value != "Hospital Daily Cash") {
                  var additionalCoverageSumAssured = "";
                  var additionalRenewalCoefficient = "";
                  if(coverage?.flagCoverageInterdependent?.value === "1"){
                    additionalCoverageSumAssured = coverage?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value;
                  } else {
                    additionalCoverageSumAssured = coverage?.additionalCoverageSumAssured?.value?.toString()
                  }
                  additionalRenewalCoefficient = coverage?.addRenewalCoefficient?.value?.toString()
                  var item = {
                    coverage: {
                      additionalCoverageName: coverage?.additionalCoverageName?.value || "",
                      additionalCoverageSumAssured: additionalCoverageSumAssured || "",
                      addRenewalCoefficient: additionalRenewalCoefficient || "",
                    }
                  };
                  if(Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP){
                    if(item.coverage.additionalCoverageSumAssured != "0"){
                      coverages.push(item);
                    }
                  }else{
                    coverages.push(item);
                  }
              // }
            });
            let sumAssured = value?.sumAssured?.value?.toString() || "";
            if (Global.quote.productType == Global.enumProductType.HCP) {
              let found = value?.coverages?.coverage.find(cov => cov.additionalCoverageName.value == key);
              sumAssured = found ? found.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured.value.toString() : sumAssured 
              // sumAssured = found ? found.additionalCoverageSumAssured.value.toString() : ""
            }
            var cover = {
              cover: {
                name: key,
                sumAssured: sumAssured,
                ...(Global.tp =='ren' && {renewalCoefficient: value?.renewalCoefficient?.value || ""}),
                coverages: coverages
              }
            };
            coverList.push(cover);
            if (Global.tp =='ren' && Global.quote.productType == Global.enumProductType.HCP) {
              Global.renewalPackages = value
            }
            
          }else{
            hardblockCover.push(key);
          }
        }
        for (const value of Global.questionResponses.values()) {
          if(value?.Question_Code__c != "TMQ051"){
            if(!hardblockCover.includes(this.getQuestionCategories(value?.Question_Code__c))){
              var healthQuestion = {
                question: {
                  code: value?.Question_Code__c,
                  response: value?.Response_Value__c
                }
              };
              healthQuestionList.push(healthQuestion);
            }
          }
        }
      } else {
        for (const value of Global.questionResponses.values()) {
          if(value?.Question_Code__c != "TMQ051"){
            var healthQuestion = {
              question: {
                code: value?.Question_Code__c,
                response: value?.Response_Value__c
              }
            };
            healthQuestionList.push(healthQuestion);
          }
        }
      }
      var data = {
        root: {
          quote: {
            productType: Global.quote.productType,
            radarCallType: Global.radarCallType,
            policyRenewalInviteCount: Global.policyRenewalInviteCount,
            finalNCB: Global.finalNCB,
            finalNCBPct: Global.finalNcbPct,
            lyPaymentFrequency: Global.lyPaymentFrequency,
            lyPaymentLoading: Global.lyPaymentLoading,
            suspectFraud: Global.suspectFraud,
            quarterlyPaymentLoadingFactor:
              Global?.packages?.quarterlyPaymentLoadingFactor?.value ===
              undefined
                ? ""
                : Global.packages.quarterlyPaymentLoadingFactor.value.toString(),
            nonAnnualPaymentLoadingFactor:
              Global?.packages?.nonAnnualPaymentLoadingFactor?.value ===
              undefined
                ? ""
                : Global.packages.nonAnnualPaymentLoadingFactor.value.toString(),
            monthlyNoDepositPaymentLoadingFactor:
              Global?.packages?.monthlyNoDepositPaymentLoadingFactor?.value ===
              undefined
                ? ""
                : Global.packages.monthlyNoDepositPaymentLoadingFactor.value.toString(),
            twelveMonthsNoDepositPaymentLoadingFactor: "",
            policyNumber: Global.quote.opportunityNumber,
            quoteDateOfQuote: this.date(Global.serverTime).format("YYYY-MM-DD"),
            quoteDateOfFirstNbQuote: "",
            policyStartDate:
              Global.policyStartDate === ""
                ? ""
                : this.date(Global.policyStartDate).format("YYYY-MM-DD"),
            endorsementEffectiveDate:
              Global.endorsementEffectiveDate === ""
                ? ""
                : this.date(Global.endorsementEffectiveDate).format(
                    "YYYY-MM-DD"
                  ),
            policyEndorsementType: Global.policyEndorsementType,
            policyEndDate:
              Global.policyEndDate === ""
                ? ""
                : this.date(Global.policyEndDate).format("YYYY-MM-DD"),
            healthQuestionSetCode:
              Global?.packages?.healthQuestionSetCode?.value === undefined
                ? ""
                : Global.packages.healthQuestionSetCode.value,
            healthQuestionList:
              healthQuestionList.length > 0 ? healthQuestionList : null,
            coverList: coverList,
            insuredList: {
              insured: {
                dob: this.date(Global.phaccount.dob)
                  .toAD()
                  .format("YYYY-MM-DD"),
                gender: Global.phaccount.gender,
                height: Global.phaccount.height,
                weight: Global.phaccount.weight,
                maritalStatus: Global.phaccount.maritialStatus,
                smokingHabit: Global.phaccount.smoker,
                occupation: Global.phaccount.occupation?.nameEn ? Global.phaccount.occupation?.nameEn : "",
                occupationGroup: Global.phaccount.occupation?.occupationGroup ? Global.phaccount.occupation.occupationGroup.toString() : "",
                income: Global.quote.salary,
                educational_Qualification: Global.phaccount.Educational_Qualification,
                company_Revenue: Global.phaccount.Company_Revenue
              }
            },
            productOptionName : Global.radarCallType == "1" ? "" :Global.productOptionName,
            productOptionNo: Global.radarCallType == "1" ? "" : Global.productOptionNo,
            policyPaymentFrequency: "",
            approvedCode: Global.quote.approvedCode,
            geoPostalCode: Global.quote.postalCode //geo postal code
          }
        },
        masterSetId: Global.quote.mastersetId
      };
      if(Global.quote.productType === Global.enumProductType.Health && Global.radarCallType == "1"){
        data.root.quote.coverName = Global.defaultPreSelected;
      }
      return axios.post(Global.roojaiService.url + "/plans", data, {
        headers: { Authorization: "Bearer " + Global.roojaiService.token }
      });
    },
    moveTo: function(ele, callback) {
      var vm = this;

      setTimeout(function() {
        if (!vm.ele(ele).exist) {
          return false;
        }

        $("html,body")
          .stop()
          .animate(
            {
              scrollTop: $(ele).offset().top - 150
            },
            "slow",
            callback
          );
      }, 300);
    },
    pushStep: function(step) {
      if (!Global.steps.includes(step)) {
        Global.steps.push(step);
      }
    },
    stepCompleted: function(step, isSkipRetrieve) {
      if (isSkipRetrieve) {
        return Global.steps.includes(step);
      }

      return  Global.steps.includes(step);
    },
    saveQuote: async function( options ) {
      Global.isSavingQuote = true;
      var quoteLineList = this.getSFMappingQuoteLineList();
      var coverList = this.getSFMappingCoverList();
      var payment;

      if (Global.paymentID !== null && Global.paymentID !== 0) {
        payment = {
          paymentID: Global.paymentID
        };
      } else {
        payment = Global.payment;
      }

      Global.quote.totalPremium = this.totalPremium.toFixed(0);
      Global.quote.onlineScreen = this.$route.path;
      Global.quote.isGAMarketeerlink = Global.brokerInfo.intermediaryReptID !== "" && Global.isAgentCustomerView ? 'Yes' : Global.brokerInfo.intermediaryReptID !== "" ? 'No' : '';
      // console.log("window GA : " + window.ga);
      //old GA
      if (window.ga !== undefined) {
        ga(function() {
          var tracker = ga.getAll()[0];
          Global.quote.googleClientID = tracker.get("clientId");
          policyObject.root.quoteData.googleClientId = tracker.get("clientId");
        });
      }
      // new GA4
      gtag('config', 'G-F6ZFKXYM8W');
      gtag('get', 'G-F6ZFKXYM8W', 'client_id', (clientID) => {      
        Global.quote.googleClientID = clientID;
        policyObject.root.quoteData.googleClientId = clientID;
        // console.log(Global.quote.googleClientID);
      });
      gtag('get', 'G-F6ZFKXYM8W', 'gclid', (gclid) => {  
        if (gclid !== undefined) {    
          Global.quote.googleClickId = gclid;
        }
      });

      var quote = Object.assign({}, Global.quote);
      quote.Start_date = Global.quote.Start_date
        ? this.date(Global.quote.Start_date).format("YYYY-MM-DD")
        : null;
      quote.End_date = Global.quote.End_date
        ? this.date(Global.quote.End_date).format("YYYY-MM-DD")
        : null;

      quote.preferredOption = ["Proceed to payment", "success"].includes(Global.quote.onlineStage) ? Global.quote.preferredOption : "";

      if( options && options.callMeBackRequest && ['Yes','Later'].includes(options.callMeBackRequest) ){
        quote.callMeBackRequest = options.callMeBackRequest;
        quote.callMeBack = options.callMeBack
      }else if( options && options.callMeBackRequest && ['No'].includes(options.callMeBackRequest)){
        quote.callMeBackRequest = options.callMeBackRequest;
      }
      else if(options && options.callMeBack){
        quote.callMeBack = options.callMeBack
        quote.callMeBackRequest = options.callMeBackRequest
      }
      // console.log("quote ",quote)
      var data = {
        productType: Global.quote.productType,
        item: {
          brokerInfo: {
            intermediaryReptID: Global.brokerInfo.intermediaryReptID,
            intermediaryReptEmail: Global.brokerInfo.intermediaryReptEmail,
            intermediaryAccountID: Global.brokerInfo.intermediaryAccountID
          },
          quote: quote,
          quoteLineList: quoteLineList,
          questionResponses: Global.questionResponses,
          phaccount: {
            lastName: Global.phaccount.lastName.toUpperCase(),
            districtCode: Global.phaccount.districtCode,
            gender: Global.phaccount.gender,
            maritialStatus: Global.phaccount.maritialStatus,
            postalCode: Global.phaccount.postalCode,
            houseNumber: Global.phaccount.houseNumber,
            countryOfIssuance: Global.phaccount.countryOfIssuance,
            soi: Global.phaccount.soi,
            province: Global.phaccount.province,
            village: Global.phaccount.village,
            email: Global.phaccount.email,
            PersonHasOptedOutOfEmail: Global.phaccount.PersonHasOptedOutOfEmail,
            residentStatus: Global.phaccount.residentStatus,
            provinceCode: Global.phaccount.provinceCode,
            thaiIdNumber: Global.phaccount.thaiIdNumber,
            firstName: Global.phaccount.firstName.toUpperCase(),
            phoneNumber: Global.phaccount.phoneNumber,
            passPortNumber: Global.phaccount.passPortNumber,
            dob: this.date(Global.phaccount.dob)
              .toAD()
              .format("YYYY-MM-DD"),
            district: Global.phaccount.district,
            driverAge: Global.phaccount.driverAge ? Global.phaccount.driverAge : this.dob2age(Global.phaccount.dob),
            subDistrict: Global.phaccount.subDistrict,
            subDistrictCode: Global.phaccount.subDistrictCode,
            smoker: Global.phaccount.smoker,
            height: Global.phaccount.height ? Global.phaccount.height : null,
            weight: Global.phaccount.weight ? Global.phaccount.weight : null,
            occupation: Global.phaccount.occupation?.apiName ? Global.phaccount.occupation?.apiName : "",
            occupationGroup: Global.phaccount.occupation?.occupationGroup ? Global.phaccount.occupation.occupationGroup.toString() : "",
            recordTypeName: "Person Account",
            Educational_Qualification: Global.phaccount.Educational_Qualification,
            Company_Revenue: Global.phaccount.Company_Revenue
          },
          payment: payment,
          beneficiary: Global.beneficiary,
        },
        // accountExternalId: Global.accountExternalId,
      };
      if(Global.sfAccountId) data.item.phaccount["sfAccountId"] = Global.sfAccountId      

      if(coverList.length > 0){
          data.item.coverList = coverList
        }

      try {
        const response = await axios.post(this.urlDecommission.urlSaveQuote, data,
          {
            headers: { Authorization: "Bearer " + this.urlDecommission.token }
          });

        if (response?.data?.success === true) {
          // console.log("response ",response);
          Global.quote.opportunityNumber = response.data.opportunityNumber;
          Global.accountExternalId = response.data.accountExternalId == undefined ? 0 : response.data.accountExternalId;
          Global.sfAccountId = response.data.sfAccountId

          // console.log("Global.accountExternalId ", Global.accountExternalId)
        }

        Global.isSavingQuote = false;
      } catch (error) {
        console.error(error);
        Global.isSavingQuote = false;
      }
    },
    validateQuote: async function() {
      try {
        const validateQuoteResponse = await axios.get(Global.restServer.url + "/quotations/validateQuote", {
            params: {
                // ThaiID_Number: Global.phaccount.thaiIdNumber,
                // Passport_Number: Global.phaccount.passPortNumber,
                Flag_Validate_Customer: true,
                dob: Global.phaccount.dob,
                gender: Global.phaccount.gender,
                accountExternalId: Global.accountExternalId,
                Opportunity_Number: Global.quote.opportunityNumber
            },
            headers: { Authorization: "Bearer " + Global.restServer.token }
          }
        );

        if(validateQuoteResponse == null){
          return false;
        }else if(validateQuoteResponse != null && validateQuoteResponse.data.flagBlockContact){
          this.loading("hide");
          // await this.$router.replace("/");
          // this.$router.push(Global.productConfig.route.blockpolicy);
          // return;
          // this.errorModal(this.$t("validateQuoteUnavailable"));
          return false;
        }else{
          return true;
        }

      } catch (error) {
        console.log(error)
        this.loading("hide");
        this.errorModal(this.$t("error.connection.message"));
        return false;
      }
    },
    validate: function(data) {
      var methods = {},
        vm = this;

      methods.isEmail = function() {
        var rex = /^[a-z0-9]{1,}(([a-z0-9]{1,})|([_\.][a-z0-9]{1,}){1,})@[a-z0-9]{1,}(([a-z0-9]{0,})|([\-_\.][a-z0-9]{1,}){1,})\.[a-z0-9]{2,}$/i;
        return data ? rex.test(data.toLowerCase()) : false;
      };

      methods.isMobileNumber = function() {
        return /((06|08|09)\d{8,13})$/i.test(data.replace(/[^0-9/]/g, ""));
      };

      methods.isAlphabet = function() {
        return /^[a-zA-Z ]+$/i.test(data);
      };

      methods.isThaiID = function() {
        // if (data.length != 13 || /^0/.test(data)) {//thai format
        if (data.length != 16) {//indo format
          return false;
        }

        /* thai format
        var i, sum;
        for (i = 0, sum = 0; i < 15; i++) {
          sum += parseFloat(data.charAt(i)) * (16 - i);
        }

        if ((11 - (sum % 11)) % 10 != parseFloat(data.charAt(15))) {
          return false;
        }
        */

        return true;
      };

      methods.isDOB = function() {
        var age = vm.dob2age(data);
        return age >= 1 && age <= 99;
      };

      methods.startDate = function() {
        try {
          if (!vm.isDateValid(data)) {
            return 0;
          }

          var base = vm.date(vm.Global.serverTime);

          if(Global.quote.productType == Global.enumProductType.PA){
            base = base.add(1, "day")
          }
          var input = vm.date(data);
          input = input.toAD();

          if (input.isBefore(base, "day")) {
            return 0;
          }

          var diff = input.diff(base, "day");

          return diff >= 0 && diff <= 90;
        } catch (err) {
          return 0;
        }
      };

      return methods;
    },
    calculatePrice: function(premiumBeforeTaxes) {
      // if (premiumBeforeTaxes > 5000000) {
      //   premiumBeforeTaxes =   premiumBeforeTaxes + 10000
      //   return parseFloat(accounting.toFixed(premiumBeforeTaxes, 2));
      // }else{
      //   premiumBeforeTaxes = Math.abs(premiumBeforeTaxes);
      //   // var stampDutyVoluntary = 10000;
      //   return parseFloat(accounting.toFixed(premiumBeforeTaxes, 2));
      // }
      premiumBeforeTaxes = Math.abs(premiumBeforeTaxes);
        // var stampDutyVoluntary = 10000;
        return parseFloat(accounting.toFixed(premiumBeforeTaxes, 2));
    },
    eventLog: function(name, value) {
      if(process.env.NODE_ENV !== 'development'){
        var clientId, productType;
        if (window.ga !== undefined) {
          ga(function() {
            var tracker = ga.getAll()[0];
            clientId = tracker.get("clientId");
          });
        }

        if (Global.quote.productType === Global.enumProductType.Health) {
          productType = Global.productSorting || "Cancer";
          // Global.quote.abTestingVersion = Global.abtest
        } else if (Global.quote.productType === Global.enumProductType.PA) {
          productType = "PA";
          // Global.quote.abTestingVersion = Global.abtest;
        } else if (Global.quote.productType === Global.enumProductType.HCP) {
          productType = "HCP";
          // Global.quote.abTestingVersion = Global.abtest;
        } else if (Global.quote.productType === Global.enumProductType.MV) {
          productType = "MotorCar";
        }

        if (clientId === undefined && Global.quote.googleClientID === "") {
          // new GA4
          gtag("config", "G-F6ZFKXYM8W");
          gtag("get", "G-F6ZFKXYM8W", "client_id", clientID => {
            Global.quote.googleClientID = clientID;
            policyObject.root.quoteData.googleClientId = clientID;
            axios.post(
              Global.restServer.url + "/eventLog",
              {
                opportunityNumber: Global.quote.opportunityNumber,
                accessToken: Global.restServer.token,
                value: value,
                productType:
                  productType === "Cancer" ? "Critical Illness" : productType,
                prefLang: Global.quote.prefLang,
                fieldName: name,
                testingVersion: Global.quote.abTestingVersion,
                onlineScreen:
                  this.$route.path.includes("ci-input") === true
                    ? Global.defaultPreSelected !== "Critical Illness"
                      ? this.$route.path +
                        "?preselected=" +
                        Global.defaultPreSelected
                      : this.$route.path
                    : this.$route.path,
                clientId: Global.quote.googleClientID,
                utmSource: Global.utmSource
              },
              {
                headers: { Authorization: "Bearer " + Global.restServer.token }
              }
            );
          });
        } else {
          axios.post(
            Global.restServer.url + "/eventLog",
            {
              opportunityNumber: Global.quote.opportunityNumber,
              accessToken: Global.restServer.token,
              value: value,
              productType:
                productType === "Cancer" ? "Critical Illness" : productType,
              prefLang: Global.quote.prefLang,
              fieldName: name,
              testingVersion: Global.quote.abTestingVersion,
              onlineScreen:
                this.$route.path.includes("ci-input") === true
                  ? Global.defaultPreSelected !== "Critical Illness"
                    ? this.$route.path +
                      "?preselected=" +
                      Global.defaultPreSelected
                    : this.$route.path
                  : this.$route.path,
              clientId: Global.quote.googleClientID,
              utmSource: Global.utmSource
            },
            {
              headers: { Authorization: "Bearer " + Global.restServer.token }
            }
          );
        }
      }
    },
    getQuotationDetails: function(encryptedOpportunityNumber) {
      return axios.get(
        Global.restServer.url + "/quotations/retrieveQuotation",
        {
          params: { opportunity_number: encryptedOpportunityNumber },
          headers: { Authorization: "Bearer " + Global.restServer.token }
        }
      );
    },
    giconsValidate: function(condition) {
      return condition ? ["glyphicons-ok"] : ["glyphicons-warning-sign"];
    },
    giconsCoverage: function(condition) {
      return condition ? ["glyphicons-ok"] : ["glyphicons-remove"];
    },
    setQuotationDetails: async function(quotationDetails) {
      Global.isRetrieve = true;
      Global.brokerInfo = quotationDetails.brokerInfo;
      Global.sfAccountId = quotationDetails.phaccount?.sfAccountId
      if(Global.brokerInfo.intermediaryReptEmail !== ''){
      //   await this.getAgentInfo();
        Global.isAgentCustomerView = true;
      }
      Global.accountExternalId = quotationDetails.phaccount.accountExternalId;
      if (quotationDetails.phaccount?.dob !== undefined) {
        quotationDetails.phaccount.dob = dayjs(
          quotationDetails.phaccount.dob,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
      }

      Global.quote.postalCode = quotationDetails.quote.postalCode;//geo postal code
      Global.quote.channel = quotationDetails.quote.channel;
      Global.quote.prefLang = quotationDetails.quote.prefLang.toLowerCase() === 'th' ? 'id' : quotationDetails.quote.prefLang.toLowerCase();
      Global.quote.tentativeStartDate = quotationDetails.quote.tentativeStartDate;
      Global.quote.mastersetId = quotationDetails.quote.mastersetId;
      Global.quote.ePolicyDocument = quotationDetails.quote.ePolicyDocument;
      Global.quote.abTestingVersion = quotationDetails.quote.abTestingVersion;
      Global.quote.opportunityNumber = quotationDetails.quote.opportunityNumber;
      Global.quote.approvedCode = quotationDetails.quote.approvedCode;
      if (Global.tp =='ren' ) {
        Global.finalNCB = quotationDetails.quote.finalNCB;
        Global.finalNcbPct = quotationDetails.quote.finalNCBPct;
        Global.lyPaymentFrequency = quotationDetails.quote.lyPaymentFrequency;
        Global.lyPaymentLoading = quotationDetails.quote.lyPaymentLoading;
        Global.suspectFraud = quotationDetails.quote.suspectFraud;
      }
      if(quotationDetails.quote.productType != Global.quote.productType){
        await this.getConfigurations(quotationDetails.quote.productType);
      }
      Global.quote.productType = quotationDetails.quote.productType;
      Global.quote.salary = quotationDetails.quote.salary;
      Global.phaccount.Educational_Qualification = quotationDetails.phaccount.Educational_Qualification;
      Global.phaccount.Company_Revenue = quotationDetails.phaccount.Company_Revenue;
      if (quotationDetails.quote?.Start_date !== undefined) {
        Global.quote.Start_date = dayjs(
          quotationDetails.quote.Start_date,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
      }
      if (quotationDetails.quote?.End_date !== undefined) {
        Global.quote.End_date = dayjs(
          quotationDetails.quote.End_date,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
      }

      Global.paymentCompleted = quotationDetails.quote.paymentStatus === "00";
      Global.beneficiary = quotationDetails.beneficiary;
      Global.questionResponses = quotationDetails.questionResponses;

      var coverages = [];
      for (var i = 0; i < quotationDetails.quoteLineList.length; i++) {
        var item;
        if (
          quotationDetails.quoteLineList[i].Additional_Coverage_Name__c !== ""
        ) {
          item = {
            coverage: {
              additionalCoverageName: {
                value:
                  quotationDetails.quoteLineList[i].Additional_Coverage_Name__c
              },
              additionalCoverageSumAssured: {
                value:
                  quotationDetails.quoteLineList[i]
                    .Additional_Coverage_Sum_Assured_1__c
              }
            }
          };
        }
        coverages.push(item);

        if (
          quotationDetails.quoteLineList[i].Additional_Coverage_Name_2__c !== ""
        ) {
          item = {
            coverage: {
              additionalCoverageName: {
                value:
                  quotationDetails.quoteLineList[i]
                    .Additional_Coverage_Name_2__c
              },
              additionalCoverageSumAssured: {
                value:
                  quotationDetails.quoteLineList[i]
                    .Additional_Coverage_Sum_Assured_2__c
              }
            }
          };
        }
        coverages.push(item);

        Global.selectedPackages[
          quotationDetails.quoteLineList[i].Cover_Code__c
        ] = {
          sumAssured: {
            value: quotationDetails.quoteLineList[i].Sum_Assured__c
          },
          renewalCoefficient: {
            value: quotationDetails.quoteLineList[i].Renewal_Coefficient__c 
          },
          coverages: coverages
        };
        if (Global.tp == 'ren') {
          Global.renewalPackages[quotationDetails.quoteLineList[i].Cover_Code__c] = 
          {
            sumAssured: {
              value: quotationDetails.quoteLineList[i].Sum_Assured__c
            },
            renewalCoefficient: {
              value: quotationDetails.quoteLineList[i].Renewal_Coefficient__c 
            },
            coverages: coverages
          }
        }
      }
      this.setSelectedPackageFromCoverList(quotationDetails.coverList);
      Global.phaccount = quotationDetails.phaccount;
      Global.phaccount.lastName = quotationDetails.phaccount.lastName.toLowerCase() == "unknown" ? "" : quotationDetails.phaccount.lastName.toLowerCase() == "roojai anonymous" ? "" : quotationDetails.phaccount.lastName; 
      Global.brokerInfo = quotationDetails.brokerInfo;
    },
    coverageTranslate: function(text) {
      switch (text) {
        case "Skin cancer":
          return this.$t("cancer.coverages.SkinCancer");
        case "Coma":
          return this.$t("covid.coverages.coma");
        case "Medical Expenses - Inpatient":
          return this.$t("covid.coverages.medicalExpensesInpatient");
        case "Medical Expenses - Outpatient":
          return this.$t("covid.coverages.medicalExpensesOutpatient");
        case "PA - Covid":
          return this.$t("covid.coverages.pa");
      }

      return text;
    },
    errorModal: function(message, callback, title, contact, additional,reload) {
      if (callback && callback == "debit card") {
        $("#debiterror-modal").modal("show");
      }else if(callback && callback == "mv error"){
        Global.errorModal_message = message;
        $("#refuse-modal-mv").modal("show");
      } else {
        if(callback == 'title'){
          Global.errorModal_title = title;
          Global.errorModal_contact = contact;
        }
        if(additional){
          Global.errorModal_additional = additional;
        }
        if(reload && reload == 'reload'){
          Global.errorModal_needreload = 'true'
        }
        Global.errorModal_message = message;
        $("#refuse-modal").modal("show");

        if (callback && typeof callback == "function") {
          $("#refuse-modal").on("hidden.bs.modal", function(e) {
            callback();
            $(this).off("hidden.bs.modal");
          });
        }
      }
    },
    blockPolicyModal: function(message, callback) {
      Global.errorModal_message = message;
      $("#blockPolicy-modal").modal("show");

      if (callback && typeof callback == "function") {
        $("#blockPolicy-modal").on("hidden.bs.modal", function(e) {
          callback();
          $(this).off("hidden.bs.modal");
        });
      }
    },
    thankyouModal: function(message, callback) {
      Global.thankyou_message = message;
      $("#thankyou-modal").modal("show");

      if (callback && typeof callback == "function") {
        $("#thankyou-modal").on("hidden.bs.modal", function(e) {
          callback();
          $(this).off("hidden.bs.modal");
        });
      }
    },
    eventTracking: function(event, eventCategory, eventAction, eventLabel) {
      if(process.env.NODE_ENV !== 'development'){
        dataLayer.push({
          event: event,
          eventCategory: eventCategory ? eventCategory : this.$route.path,
          eventAction: eventAction,
          eventLabel: eventLabel
        });
      }
    },
    checkAgentTraditionalBroker: function(){
        if((Global.brokerInfo.accountSource !== "" && Global.brokerInfo.accountSource.toLowerCase() === ("Traditional broker").toLowerCase()) || Global.brokerInfo.accountSource === ""){
            return true;
        }else if(Global.brokerInfo.accountSource !== "" && (Global.brokerInfo.accountSource.toLowerCase() === ("Ground agent").toLowerCase()
                                                    || Global.brokerInfo.accountSource.toLowerCase() === ("Salaried sale force(SSF)").toLowerCase())){
            return false;
        }
    },
    getSfmcsToken: function(){
        return axios.post(Global.restServer.url + "/agentInfo/login",{},
                {
                  headers: { Authorization: "Bearer " + Global.restServer.token }
                }
                ).then((response) => {
                  Global.sfmcsService.token = response.data.token;
                }, (response) => {
                    // console.log(response);
                });
    },
    getAgentInfo: async function(){
        var vm = this;

        // await vm.getSfmcsToken();
        try {
            const response = await axios.get(Global.sfmcsService.url + "/broker-details/details?rid=" + encodeURIComponent(Global.brokerInfo.rid),
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + Global.roojaiService.token
                }
            });
            if(response.status == 200){
              Global.brokerInfo.intermediaryReptID = response.data.intermediaryReptId
              Global.brokerInfo.intermediaryReptEmail = response.data.intermediaryReptEmail
              Global.brokerInfo.intermediaryAccountID = response.data.intermediaryAccountId
              Global.brokerInfo.firstname = response.data.firstname
              Global.brokerInfo.lastname = response.data.lastname

              Global.brokerInfo.useInstallment = response.data.useInstallment
              // intermediaryAccountId: "0016D00000xDG6LQAW"
              // intermediaryReptEmail: "bismark.joh@roojai.co.id"
              // intermediaryReptEmailEncrypt: "ueAGe1Lmg59k6gOJDm6QVglf0NDvVvZVrXAd6JOd9Rg="
              // intermediaryReptId: "0036D00000kpcXDQAY"
            //     Global.brokerInfo.accountSource = response.data.accountSource;
            }
            // Global.isAgentTraditionalBroker = vm.checkAgentTraditionalBroker();
        } catch (error) {
            this.errorModal(this.$t("error.connection.message"));
            console.error(error);
        }
    },
    frontEndLog: function(url, params, httpStatusCode){
      axios.post(Global.restServer + "log", {
          "productType": Global.quote.productType,
          "URL": url,
          "params": params,
          "HTTPStatusCode": httpStatusCode,
      }).then(function (response) {
          //console.log(response);
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    setSelectedPackages: function() {
      var initPackage = false;
      var updateSelectedPackage = {};
      if (Object.keys(Global.selectedPackages).length === 0) {
        initPackage = true;
      }

      if (Global.packages?.coversList?.cover?.length === undefined) {
        this.loading("hide");
        //$("#refuse-modal").modal("show");
          // console.log("pu v")
        this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2"))
        return;
      }
      
      Global.packages.coversList.cover.forEach(function(element) {
        if (initPackage === true) {
          if (Global.enableCoverList?.includes(element.name.value)) {
            if (
                Global.defaultSumAssured === undefined ||
                Global.defaultSumAssured === ""
            ) {
              // updateSelectedPackage[element.name.value] =
              //     element.optionList.option[0];
              if (Global.defaultPreSelected == element.name.value) {
                  updateSelectedPackage[element.name.value] = element.optionList.option[0]
              }
              else if(Global.defaultPreSelected == "" || Global.defaultPreSelected == "Critical Illness"){
                updateSelectedPackage[element.name.value] = element.optionList.option[0]
                // if (element.name.value == "Cancer") {
                // }
              }
            } else {
              if (Global.productSorting.toLowerCase() === element.name.value.toLowerCase()) {
                let optionData = element.optionList.option.find(
                    e => e.sumAssured.value  == Global.defaultSumAssured
                );
                if (optionData) {
                  updateSelectedPackage[element.name.value] = optionData;
                } else {
                  updateSelectedPackage[element.name.value] =
                      element.optionList.option[0];
                }
              } else {
                updateSelectedPackage[element.name.value] =
                    element.optionList.option[0];
              }
            }
          }
        } else {
          if (Global.selectedPackages[element?.name?.value]) {
            const option = element.optionList.option.find(
                e =>
                    e.sumAssured.value ===
                    Global.selectedPackages[element?.name?.value].sumAssured.value
            );
            if (option !== undefined) {
              updateSelectedPackage[element.name.value] = option;
            } else {
              updateSelectedPackage[element.name.value] =
                  element.optionList.option[0];
            }
          }
        }
      });
      Global.selectedPackages = updateSelectedPackage;
      this.setProductList();
      // this.setSelectedPackgeFirstProduct();
    },
    setProductList: function(){
      var vm = this;
      const _productList =  Global.productList;
      Global.productList = [];
      if (Global.packages?.coversList?.cover) {
        Global.packages.coversList.cover.forEach(function(element) {
          if (Global.enableCoverList?.includes(element.name.value)) {
              const existingPaymentFrequency = _productList.find(p => p.name.value == element.name.value);
              element.paymentFrequency = existingPaymentFrequency ? existingPaymentFrequency.paymentFrequency : 'Yearly';
            Global.productList.push(element)
          }
        });
        this.setDetaultPlanFromCMS(_productList.length == 0);
      }
    },
    setDetaultPlanFromCMS: function(isSetDefaultPlan){
      if(Global.productSorting != null){
        var selected = {};
        selected = Object.keys(Global.selectedPackages).filter(key =>
            Global.productSorting.includes(key.toLowerCase())).reduce((obj, key) =>
            {
              obj[key] = Global.selectedPackages[key];
              return obj;
            }, {}
        );
        if(!this.isEmpty(selected)){
          Global.productList = this.sortingProductList(Global.productSorting, Global.productList);
          if(isSetDefaultPlan) Global.selectedPackages = selected;
        }else{
          Global.productList = this.sortingProductList('cancer', Global.productList);
        }
      }else{ //default first product = cancer
        Global.productList = this.sortingProductList('cancer', Global.productList);
      }
      if (Global.defaultPreSelected == 'Cancer') {
        Global.productList = this.sortingProductList('cancer', Global.productList);
      }else if(Global.defaultPreSelected == 'Dengue'){
        Global.productList = this.sortingProductList('dengue', Global.productList);
      }else if(Global.defaultPreSelected == 'Cardiovascular'){
        Global.productList = this.sortingProductList('cardiovascular', Global.productList);
      }else{
        Global.productList = this.sortingProductList('cancer', Global.productList);
      }
    },
    sortingProductList: function(first, productList){
      return productList.sort(function(x,y){ return x.name.value.toLowerCase() == first ? -1 : y.name.value.toLowerCase() == first ? 1 : 0; });
    },
    isEmpty: function(obj) {
      for(var key in obj) {
        if(obj.hasOwnProperty(key))
          return false;
      }
      return true;
    },
    getOccupations: async function(productType, insurer){
      var vm = this;
      if(!Global.occupations){
        try {
          var response = await this.request().get(
            Global.restServer.url + "/occupations", {
              params: {
                product_type: productType,
                insurer: insurer
              }
            }
          );
          Global.occupations = response.data;
        } catch (error) {
          if (error.response) {
            vm.frontEndLog("occupations", "", error.response.status.toString());
          }
          console.error("getOccupations => ", error);
        }
      }
    },
    searchBlacklistAccountWithOpp: async function(opp){
      const response = await axios.get(
        Global.roojaiService.url +
          "/payment/validatehsi?oppName=" + opp,
        {
          headers: { Authorization: "Bearer " + Global.roojaiService.token }
        }
      );
      return response?.data?.success
    },
    getProductQuestions: async function(version, category){
      const response = await axios.get(
        Global.roojaiService.url +
          "/product-questions?v=" + version + category,
        {
          headers: { Authorization: "Bearer " + Global.roojaiService.token }
        }
      );

      Global.productQuestions = response?.data?.data;
    },
    getDecryptReferral: async function(channel){
      this.loading("show");
      const response = await axios.get(
        Global.roojaiService.url +
          "/quote/verifyAffiliate?channel=" + encodeURIComponent(channel),
        {
          headers: { Authorization: "Bearer " + Global.roojaiService.token }
        }
      );
        // console.log(response?.data);
      if(response?.data?.success === true){
        Global.quote.channel = response?.data?.data;
        sessionStorage.setItem("channel", Global.quote.channel);
        sessionStorage.setItem('productAffiliate', Global.productAffiliate);
        this.$router.push({name:'product-affiliate'});
        this.loading("hide");
        return response?.data?.data
      }else{
        Global.quote.channel = channel
        sessionStorage.setItem("channel", Global.quote.channel);
        this.loading("hide");
      }
    },
    getSFMappingQuoteLineList: function(){
      let quoteLineList = [];
      if(Global.quote.productType === Global.enumProductType.Health){
        for (const [key, value] of Object.entries(Global.selectedPackages)) {
          if (value?.sumAssured?.value) {
            var quoteLine = {
              Cover_Code__c: key,
              Sum_Assured__c: value?.sumAssured?.value?.toString() || "",
              ...(Global.tp =='ren' && {Renewal_Coefficient__c: Global.renewalPackages[key].renewalCoefficient?.value || ""}),
              Additional_Coverage_Name__c:
                value?.coverages?.coverage[0]?.additionalCoverageName?.value ||
                "",
              Additional_Coverage_Sum_Assured_1__c:
                value?.coverages?.coverage[0]?.additionalCoverageSumAssured?.value?.toString() ||
                "",
              Additional_Coverage_Name_2__c:
                value?.coverages?.coverage[1]?.additionalCoverageName?.value ||
                "",
              Additional_Coverage_Sum_Assured_2__c:
                value?.coverages?.coverage[1]?.additionalCoverageSumAssured?.value?.toString() ||
                "",
              Additional_Coverage_Name_3__c:
                value?.coverages?.coverage[2]?.additionalCoverageName?.value ||
                "",
              Additional_Coverage_Sum_Assured_3__c:
                value?.coverages?.coverage[2]?.additionalCoverageSumAssured?.value?.toString() ||
                "",
              Additional_Coverage_Name_4__c:
                value?.coverages?.coverage[3]?.additionalCoverageName?.value ||
                "",
              Additional_Coverage_Sum_Assured_4__c:
                value?.coverages?.coverage[3]?.additionalCoverageSumAssured?.value?.toString() ||
                ""
            };
            quoteLineList.push(quoteLine);
          }
        }
      }
      return quoteLineList;
    },
    checkHighestSumInsured: function() {
      if (!Global.isAgentCustomerView) {
        var productName = Object.keys(Global.selectedPackages);
        var highest = Global.quote.productType === "Health" && 1000000000
        var isEachProduct1bill = productName.filter(function(value) { 
          return Global.selectedPackages[value]
        }).map(function(value) {
          return Global.selectedPackages[value].sumAssured.value === highest;
        });

        const even = (element) => element === true;
        Global.isCustomerHaveHighestSumInsured = (isEachProduct1bill.some(even));
        Global.quote.isHighestSumInsured = Global.isCustomerHaveHighestSumInsured
      }
    },
    getSFMappingCoverList: function(){
      let coverList = [],
      addCoverageList = [];
      if((Global.quote.productType === Global.enumProductType.PA || Global.quote.productType === Global.enumProductType.HCP)){
        var coverName = Global.quote.productType === Global.enumProductType.PA ? Global.coverName.PA : Global.coverName.HCP;
        for (const [key, value] of Object.entries(Global?.selectedPackages[coverName]?.coverages?.coverage)) {
          // if (value?.additionalCoverageName?.value != "Hospital Daily Cash") {
              if((value?.flagCoverageInterdependent?.value === "1" && value?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value != 0)){
                if(value?.addCoveragesOptions == null){
                  addCoverageList.push({
                    "addCoverName": value.additionalCoverageName?.value,
                    "addCoverSI": value.additionalCoverageSumAssured.value.toString(),
                    ...(Global.tp =='ren' && {"addRenewalCoefficient": value.addRenewalCoefficient.value.toString() || ""}),
                  });
                }else{
                  addCoverageList.push({
                    "addCoverName": value.additionalCoverageName?.value,
                    "addCoverSI": value.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon.value.toString(),
                    ...(Global.tp =='ren' && {"addRenewalCoefficient": value.addRenewalCoefficient.value.toString() || ""}),
                  });
                }
              } else if(value?.flagCoverageInterdependent?.value != "1" && value?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value != 0){
                if(value?.additionalCoverageName?.value == 'Daily Cash'){
                  if(value?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured.value >= (coverName === Global.coverName.PA ? Global.PAminProofofIncome : Global.HCPminProofofIncome)){
                    Global.popUpProofofIncome = true;
                  }else{
                    Global.popUpProofofIncome = false
                  }
                }
                if(value?.addCoveragesOptions == null){
                  addCoverageList.push({
                      "addCoverName": value?.additionalCoverageName?.value,
                      "addCoverSI": value?.additionalCoverageSumAssured.value.toString(),
                      ...(Global.tp =='ren' && {"addRenewalCoefficient": value.addRenewalCoefficient.value.toString() || ""}),
                  });
                }else{
                  addCoverageList.push({
                      "addCoverName": value?.additionalCoverageName?.value,
                      "addCoverSI": value?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured.value.toString(),
                      ...(Global.tp =='ren' && {"addRenewalCoefficient": value.addRenewalCoefficient.value.toString() || ""}),
                  });
                }
              }
          // }
          // if (value?.additionalCoverageName?.value == "Hospital Daily Cash"){
          //   if(value?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured.value >= (coverName === Global.coverName.PA ? Global.PAminProofofIncome : Global.HCPminProofofIncome)){
          //     Global.popUpProofofIncome = true;
          //   }else{
          //     Global.popUpProofofIncome = false
          //   }
          // }
        }
        let sumAssured = Global?.selectedPackages[coverName]?.sumAssured.value.toString();
        if (Global.quote.productType == Global.enumProductType.HCP) {
          let found = Global?.selectedPackages[coverName]?.coverages?.coverage.find(cov => cov?.additionalCoverageName?.value == coverName);
          if(found?.addCoveragesOptions == null){
            sumAssured = Global?.selectedPackages[coverName]?.sumAssured.value.toString();
          }else{
            sumAssured = found?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured.value.toString();
          }
        }
        let cover = {
          coverCode: coverName,
          sumAssured: sumAssured,
          ...(Global.tp =='ren' && {renewalCoefficient: Global.renewalPackages.renewalCoefficient?.value || ""}),
          addCoverageList: addCoverageList
        }
        coverList.push(cover);
      }
      return coverList;
    },
    getTotalPremiumForPA: function(){
      var netPremium = 0,
          totalPremium = 0;
      for (var selectedPackage in Global.selectedPackages) {
        var pkg = Global.selectedPackages[selectedPackage];
        netPremium += this.getPaPackagePremium(pkg);
      }
      // console.log("netPremium ",netPremium)

      //cal stamp duty one time and no vat
      totalPremium = Math.round(this.calculatePrice(netPremium));
      Global.totalPremiumRadar = this.calculatePrice(netPremium);
      // console.log("totalPremium ",totalPremium)
      // console.log("totalPremiumRadar ", Global.totalPremiumRadar)
      return totalPremium;
    },
    getInstallmentPremiumForPA: function(){
      var installment = {},
          installmentAvailable = false,
          netPremium = 0,
          monthlyInstallment = 0,
          totalInstallment = 0;

      for (var selectedPackage in Global.selectedPackages) {
        var pkg = Global.selectedPackages[selectedPackage];
        netPremium += this.getPaPackagePremium(pkg);
      }
  
      if((netPremium * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value) >= (Global.quote.productType == Global.enumProductType.PA ? Global.PAminInstallmentTotalPremium : Global.HCPminInstallmentTotalPremium)){
        installmentAvailable = true;
        //yearly-installment old formula
        // var monthly = (netPremium * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value);

        //yearly-installment new formula
        var monthly = Math.floor(netPremium * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value/1000)*1000;

        var limitStampDuty = 5000000;
        var stampDuty = 10000;
        
        monthly = monthly > limitStampDuty ? monthly+stampDuty : monthly;
        //old formula
        // monthlyInstallment = Math.round(Math.round(monthly)/12);
        // totalInstallment = Math.round(monthly);

        //new formula
        monthlyInstallment = Math.floor(monthly/12);
        totalInstallment = monthlyInstallment*12;
      }
      installment = {
        "installmentAvailable" : installmentAvailable,
        "totalInstallment" : totalInstallment,
        "monthlyInstallment": monthlyInstallment
      }
      // console.log("installment ",installment)
      return installment;
    },
    getPaPackagePremium: function(pkg){
      var premium = 0;
      premium = pkg.premiumBeforeTaxes.value;
      pkg.coverages?.coverage?.forEach(function(element) {
        
        if(element?.additionalCoverageName.value == "Hospital Daily Cash"){
          element.additionalCoverageSumAssured.value = Number(element.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value)
        }
        if(element?.flagCoverageInterdependent?.value === "0"){
          premium += Number(element.addCoveragesOptions?.addCoveragesOption[0].addPrem?.value)
        }else if(element?.flagCoverageInterdependent?.value === "1"){
            var addPremAddon = Number(element.addCoveragesOptions?.addCoveragesOption[0]?.addPremAddon?.value);
            element.addCoveragesOptions?.addCoveragesOption[0].premiumDependencies.premiumDependency.forEach((premiumDependency) => {
              var coverage = pkg.coverages.coverage.find(coverage => coverage.additionalCoverageName?.value === premiumDependency.depedencyCoverCode.value);
              if(coverage){
                  var dependencyCoverSet = premiumDependency.dependencyCoverSet.find(dependencyCoverSet => dependencyCoverSet.dependencyCoverValue.value == coverage.additionalCoverageSumAssured.value);
                if(dependencyCoverSet){
                  addPremAddon += Number(dependencyCoverSet.dependencyCoverAmount.value);
                }
              }
            });
            premium += addPremAddon;
        }
      });
      return premium;
    },
    setPASelectedPackages: function(){
      var vm = this,
      updateSelectedPackage = {};
      Global.productList = [];
      this.setDefaultAddCoveragesOptions();
      if (Global.packages?.coversList?.cover?.length === undefined) {
        this.loading("hide");
          // console.log("pu b")
          this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2"));
        return;
      }
      Global.packages.coversList.cover.forEach(function(element) {
        if ((!Global.isRetrieve && Object.keys(Global.selectedPackages).length === 0)) {
          updateSelectedPackage[element.name.value] = vm.initPASelectedPackages(JSON.stringify(element), null);
        } else {
          var coverName = Global.quote.productType === Global.enumProductType.HCP ? Global.coverName.HCP : Global.coverName.PA
          let option = element.optionList?.option?.find(o => o.sumAssured.value == Global.selectedPackages[coverName].sumAssured.value);
          if(!option){
            option = element.optionList?.option[0];
          }
          updateSelectedPackage[element.name.value] = vm.updateSelectedPackage(JSON.stringify(option), JSON.stringify(Global.selectedPackages[coverName]));
        }
      });
      Global.selectedPackages = updateSelectedPackage;
    },
    setDefaultAddCoveragesOptions: function(){
      Global.packages?.coversList?.cover.forEach(function(element) {
        element?.optionList?.option.forEach(function(op) {
          op.coverages?.coverage?.forEach(function(cover){
            if(cover.additionalCoverageSumAssured?.value && cover.additionalCoverageName != null){
              let defaultCover = {
                "sumAssured": {
                  "value": cover.additionalCoverageSumAssured?.value
                },
                "addPrem": {
                  "value": "0"
                }
              }
              cover.addCoveragesOptions?.addCoveragesOption.push(defaultCover);
              cover.addCoveragesOptions?.addCoveragesOption.sort(function(x,y){ return Number(x.sumAssured.value) - Number(y.sumAssured.value) });
            }
          });
        });
      });
    },
    getPlanByProduct: async function(redirectPath){
      this.loading("show");
      this.eventLog("Get quote", "Get quote");
      try {
        var planResponse = await this.getPlans();
        Global.packages = planResponse.data.quote;
        Global.quote.mastersetId = planResponse.data.masterSetId;
        Global.quote.onlineStage = "";
        this.setProductOptionNameAndNo();
        this.setSelectedPackagesByProduct();
        this.saveQuote();

        const businessRulesResult = planResponse.data.quote.businessRulesResult.value
          .split(",")
          .filter(rule => rule !== "0");

        this.loading("hide");

        if (businessRulesResult.length > 0) {
          // console.log("pu n")
          this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2"));
        } else if(redirectPath){
          this.$router.push(redirectPath);
        }
      } catch (error) {
        console.error(error);
      }
    },
    setSelectedPackagesByProduct: function(){
      if(Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP){
        this.setPASelectedPackages();
      }else{
        this.setSelectedPackages();
      }
    },
    initPASelectedPackages: function(_element){
      let vm = this, updateSelectedPackage = {};
      let element = JSON.parse(_element);
      Global.defaultSumAssured = Global.quote.productType == Global.enumProductType.PA ? 125000000 : null;
      if (
        Global.defaultSumAssured === undefined ||
        Global.defaultSumAssured === ""
      ) {
        updateSelectedPackage = element.optionList.option[0];
      } else {
        let optionData = element.optionList.option.find(e => e.sumAssured.value == Global.defaultSumAssured);
        if (optionData) {
          updateSelectedPackage = optionData;
        } else {
          updateSelectedPackage = element.optionList.option[0];
        }
      }
      updateSelectedPackage.coverages.coverage = vm.initPAAdditionalCoverage(updateSelectedPackage.coverages);
      return updateSelectedPackage;
    },
    initPAAdditionalCoverage: function(coverages){
      var vm = this;
      let updateAddtionalCovers = [];
      coverages?.coverage.forEach(function(coverage){
        if(coverage?.flagCoverageOptional && coverage.addCoveragesOptions?.addCoveragesOption){
          let defaultCoverageOption = [];
            if (Global.quote.productType == Global.enumProductType.HCP && coverage.flagCoverageInterdependent.value === "1") {
              defaultCoverageOption = coverage.addCoveragesOptions?.addCoveragesOption.find(addiCover => Number(addiCover.addPremAddon?.value) == 0);
            } else {
              if (coverage.additionalCoverageName.value == 'Hospital Daily Cash') {
                let filterHDC = [];
                coverage.addCoveragesOptions.addCoveragesOption.forEach(function(cov) {
                  var found = filterHDC.find(filter => filter.sumAssured.value == cov.sumAssured.value);
                  if (!found) {
                    filterHDC.push(cov)
                  }
                });
                coverage.addCoveragesOptions.addCoveragesOption = filterHDC;
                var defaultSumAssured = undefined;
                defaultSumAssured = coverage.addCoveragesOptions?.addCoveragesOption.length > 1 ? coverage.addCoveragesOptions?.addCoveragesOption[1].sumAssured?.value : coverage.addCoveragesOptions?.addCoveragesOption[0].sumAssured?.value;
                defaultCoverageOption = coverage.addCoveragesOptions?.addCoveragesOption.find(addiCover => Number(addiCover.sumAssured?.value) == defaultSumAssured);
              } else {
                defaultCoverageOption = coverage.addCoveragesOptions?.addCoveragesOption.find(addiCover => Number(addiCover.addPrem?.value) == 0);
              }
            }
          coverage.addCoveragesOptions.addCoveragesOption = [];
          if(defaultCoverageOption){
            coverage.addCoveragesOptions.addCoveragesOption.push(defaultCoverageOption);
            updateAddtionalCovers.push(coverage);
          }
        }
      });
      return updateAddtionalCovers;
    },
    getPADeathPermanentDisablity: function(coverage){
      let defaultAddCoveragesOption = {
            addCoveragesOption : [
              {
                sumAssured: {
                  value: coverage.additionalCoverageSumAssured?.value
                },
                addPrem: {
                  value: "0"
                }
              }
            ]
          };
      coverage.addCoveragesOptions = defaultAddCoveragesOption;
      return coverage;
    },
    updateSelectedPackage: function(option, oldOption){
      var _updateSelectedPackages = JSON.parse(option);
      
      _updateSelectedPackages.coverages.coverage = this.updatePAAdditionalCoverage(_updateSelectedPackages.coverages, JSON.parse(oldOption).coverages?.coverage);
      return _updateSelectedPackages;
    },
    updatePAAdditionalCoverage: function(coverages, oldCoverageList){
      var vm = this,
      updateAddtionalCovers = [];
      coverages?.coverage.forEach(function(coverage){
        let oldCoverage = oldCoverageList?.find(oc => oc.additionalCoverageName?.value == coverage.additionalCoverageName?.value);
          
        if(coverage.additionalCoverageName?.value == 'PA - Death Permanent Disablity' || coverage.additionalCoverageName?.value == 'Personal Accident'){
          updateAddtionalCovers.push(vm.getPADeathPermanentDisablity(coverage));
        }else if(coverage.addCoveragesOptions){
          let defaultAdditionCover = null;
          if(oldCoverage){
            let selectedCoverOption = oldCoverage.addCoveragesOptions?.addCoveragesOption.find(oc => oc.isSelected);
            if(selectedCoverOption){
              defaultAdditionCover = coverage.addCoveragesOptions?.addCoveragesOption.find(aco => aco.sumAssured?.value == selectedCoverOption.sumAssured?.value);

              if(!defaultAdditionCover && coverage.addCoveragesOptions?.addCoveragesOption.length > 1){
                defaultAdditionCover = vm.getDefaultPAAdditionalCoverage(coverage);
              }else if(!defaultAdditionCover ){
                defaultAdditionCover = coverage.addCoveragesOptions?.addCoveragesOption[0];
              }
              defaultAdditionCover.isSelected = true;
            }else{
              defaultAdditionCover = vm.getDefaultPAAdditionalCoverage(coverage);
            }
          }else if(coverage.flagCoverageOptional?.value == "1" && coverage.additionalCoverageSumAssured.value != 0 && Global.isRetrieve){
            defaultAdditionCover = coverage.addCoveragesOptions?.addCoveragesOption.find(aco => aco.sumAssured.value == 0);
            defaultAdditionCover.isSelected = true;
          }else{
            defaultAdditionCover = vm.getDefaultPAAdditionalCoverage(coverage);
          }
          coverage.addCoveragesOptions.addCoveragesOption = [];
          if(defaultAdditionCover){
            coverage.addCoveragesOptions.addCoveragesOption.push(defaultAdditionCover);
            coverage.additionalCoverageSumAssured.value = defaultAdditionCover.sumAssured?.value;
            updateAddtionalCovers.push(coverage);
          }
        }else{
          if(oldCoverage != undefined){
            updateAddtionalCovers.push(oldCoverage);
          }
        }
      });
      return updateAddtionalCovers;
    },
    getDefaultPAAdditionalCoverage: function(coverage){
      if (Global.quote.productType == Global.enumProductType.HCP && coverage.flagCoverageInterdependent?.value == "1") {
        return coverage.addCoveragesOptions?.addCoveragesOption?.find(aco => aco?.addPremAddon?.value == 0);
      } else {
        return coverage.addCoveragesOptions?.addCoveragesOption?.find(aco => aco?.addPrem?.value == 0);
      }
    },
    getProductOptionDetails: async function(){
      try{
        await axios.get(Global.roojaiService.url + "/product-option-details", {
            headers: { Authorization: "Bearer " + Global.roojaiService.token },
            params: {
                product_option_name: Global.productOptionName,
                product_option_no: Global.productOptionNo
            }
        })
        .then(function (response) {
            Global.percentageSubAdditionalCoverage = response.data;
        });
      }catch(error){
          console.log(error);
      }
    },
    getVirtualAccount: async function(){
      var vm = this;

      vm.loading("show");
      try{
        await axios.get(Global.roojaiService.url + "/payment/virtualaccountlist", {
            headers: { Authorization: "Bearer " + Global.roojaiService.token },
        })
        .then(function (response) {
            Global.VirtualAccount = response.data.data.virtual_account;
            vm.loading("hide");
        });
      }catch(error){
        vm.loading("hide");
        vm.errorModal(vm.$t("error.connection.message"));
        console.log(error);
      }
    },
    setProductOptionNameAndNo: function(){
      if(Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP){
          Global.productOptionName = Global.packages?.coversList?.cover[0].optionList?.option[0].productOptionName.value;
          Global.productOptionNo = Global.packages?.coversList?.cover[0].optionList?.option[0].productOptionNo.value;
      }
    },
    getSubAdditionalCoverageSI(clauseCode){
      if(Global.percentageSubAdditionalCoverage !== null){
        var sumInsure = Global?.selectedPackages[Global.coverName.PA]?.sumAssured?.value;
        var percent = Number(Global.percentageSubAdditionalCoverage?.find(p => p.clauseCode === clauseCode)?.limit);
        return (sumInsure * percent)/100;
      }else {
        return 0;
      }
    },
    setSelectedPackageFromCoverList: function(coverList){
      if(Global.quote.productType === Global.enumProductType.PA || Global.quote.productType === Global.enumProductType.HCP){
        coverList?.forEach(function(cover){
          var coverages = {
            coverage: []
          };
          cover.addCoverageList?.forEach(function(addCoverage){
            coverages.coverage.push({
                additionalCoverageName: {
                  value: addCoverage.addCoverName
                },
                additionalCoverageSumAssured: {
                  value: addCoverage.addCoverSI
                },
                addRenewalCoefficient: {
                  value: addCoverage.addRenewalCoefficient
                },
                addCoveragesOptions: {
                addCoveragesOption: [
                    {
                      sumAssured: {
                        value: addCoverage.addCoverSI
                      },
                      addPrem: {
                        value: 0
                      },
                      isSelected: true
                    }
                  ]
                }
            });
          });
        Global.selectedPackages[cover.coverCode] = {
            sumAssured: {
              value: cover.sumAssured
            },
            renewalCoefficient: {
              value: cover.renewalCoefficient || ""
            },
            premiumBeforeTaxes: {
              value: 0
            },
            coverages: coverages
          };
        });
      }
    },
    setSelectedPackgeFirstProduct: function(){
      if (Global.quote.productType == Global.enumProductType.Health
          && !Global.isRetrieve
          && !Global.productConfig.showAllPackage
          && Object.keys(Global.selectedPackages).length > 0
          && Global.productList.length > 0) {
        var _firstProduct = JSON.parse(JSON.stringify(Global.selectedPackages[Global.productList[0].name.value]));
        Global.selectedPackages = {};
        Global.selectedPackages[Global.productList[0].name.value] = _firstProduct;
      }
    },
    goBack: function() {
      var routePrev = null;
      for(var r in Global.productConfig?.route){
        if(Global.productConfig?.route[r] == this.currentPage){
          this.$router.push(routePrev);
        }else{
          routePrev = Global.productConfig?.route[r];
        }
      }
    },
    hideDomain:function(email = Global.brokerInfo.intermediaryReptEmail){
      if(Global.brokerInfo.firstname && Global.brokerInfo.firstname !== ""){
        return Global.brokerInfo.firstname +  " " + Global.brokerInfo.lastname
      } else {
        try{
            if( !email.includes("@") ){
                return email;
            }

            return email.split("@")[0];
        }catch(err){
            return email;
        }
      }
    },
    coverHardblock: function(cover){
      var hb = Global.questionResponses.find( questionResponse =>
        Global.productQuestions.some(
          productQuestion =>
            productQuestion.questionCode ===
            questionResponse.Question_Code__c && productQuestion.questionCategory === cover && productQuestion.hardBlock == true && questionResponse.Response_Value__c === 'Yes'
        )
      );
      return hb ? true : false;
    },
    getQuestionCategories: function(qCode){
      var q = Global.productQuestions?.find(
          productQuestion =>
            productQuestion.questionCode === qCode
      );
      return q?.questionCategory;
    },
    getPreferredOption: function(br, coverList){
      let preferredOption = ""
      for (const coverName in Global.selectedPackages) {
        if(br && br.includes(coverName) && coverList?.find(c => c.name.value == coverName)){
          let sumInsure = Global.selectedPackages[coverName]?.sumAssured.value;
          preferredOption += coverName + "-" + sumInsure + "@,";
        }
      }

      if(preferredOption != "") preferredOption = preferredOption.substring(0, preferredOption.length -1);

      return preferredOption;
    },
    callMeBackRequest:function( mobileNumber, options ){
      try{
        if(!mobileNumber || !options || !options.callMeBackRequest ){
          return false;
        }

        var vm = this,
        interval = setInterval(function() {
          if (!Global.isSavingQuote) {
            clearInterval(interval);

          // Global.quote.callMeBack = true;
            Global.quote.onlineStage = "Call me back";
            Global.phaccount.phoneNumber = mobileNumber;
            // if (Global.phaccount.lastName === "") {
            //   Global.phaccount.lastName = Global.phaccount.phoneNumber;
            // }

            vm.saveQuote( options );

            //Global.quote.callMeBack = false;
            // if (Global.phaccount.lastName === Global.phaccount.phoneNumber) {
            //   Global.phaccount.lastName = "";
            // }
          }
        }, 500);
      }catch(err){
        console.log(err);
      }
    },
    getABtestingPosition:function(index){
      var arr = Global.quote.abTestingVersion ? Global.quote.abTestingVersion.split("-") : [];
      return arr[index] ? arr[index].toUpperCase() : ""; 
    },
    getQuotation: function(options){
      var quoteLineList = this.getSFMappingQuoteLineList(),
      coverList = this.getSFMappingCoverList(),
      payment;

      if (Global.paymentID !== null && Global.paymentID !== 0) {
        payment = {
          paymentID: Global.paymentID
        };
      } else {
        payment = Global.payment;
      }

      Global.quote.totalPremium = this.totalPremium;
      Global.quote.onlineScreen = this.$route.path;
      Global.quote.isGAMarketeerlink = Global.brokerInfo.intermediaryReptID !== "" && Global.isAgentCustomerView ? 'Yes' : Global.brokerInfo.intermediaryReptID !== "" ? 'No' : '';
      // console.log("window GA : " + window.ga);
      if (window.ga !== undefined) {
        ga(function() {
          var tracker = ga.getAll()[0];
          Global.quote.googleClientID = tracker.get("clientId");
        });
      }
      // new GA4
      gtag('config', 'G-F6ZFKXYM8W');
      gtag('get', 'G-F6ZFKXYM8W', 'client_id', (clientID) => {      
        Global.quote.googleClientID = clientID;
        policyObject.root.quoteData.googleClientId = clientID;
        // console.log(Global.quote.googleClientID);
      });

      var quote = Object.assign({}, Global.quote);
      quote.Start_date = Global.quote.Start_date
        ? this.date(Global.quote.Start_date).format("YYYY-MM-DD")
        : null;
      quote.End_date = Global.quote.End_date
        ? this.date(Global.quote.End_date).format("YYYY-MM-DD")
        : null;

      quote.preferredOption = ["Proceed to payment", "success"].includes(Global.quote.onlineStage) ? Global.quote.preferredOption : "";

      if( options && options.callMeBackRequest && ['Yes','Later', 'No'].includes(options.callMeBackRequest) ){
        quote.callMeBackRequest = options.callMeBackRequest;
      }

      var quotation = {
        productType: Global.quote.productType,
        item: {
          brokerInfo: {
            intermediaryReptID: Global.brokerInfo.intermediaryReptID,
            intermediaryReptEmail: Global.brokerInfo.intermediaryReptEmail,
            intermediaryAccountID: Global.brokerInfo.intermediaryAccountID
          },
          quote: quote,
          quoteLineList: quoteLineList,
          questionResponses: Global.questionResponses,
          phaccount: {
            lastName: Global.phaccount.lastName.toUpperCase(),
            districtCode: Global.phaccount.districtCode,
            gender: Global.phaccount.gender,
            maritialStatus: Global.phaccount.maritialStatus,
            postalCode: Global.phaccount.postalCode,
            houseNumber: Global.phaccount.houseNumber,
            countryOfIssuance: Global.phaccount.countryOfIssuance,
            soi: Global.phaccount.soi,
            province: Global.phaccount.province,
            village: Global.phaccount.village,
            email: Global.phaccount.email,
            PersonHasOptedOutOfEmail: Global.phaccount.PersonHasOptedOutOfEmail,
            residentStatus: Global.phaccount.residentStatus,
            provinceCode: Global.phaccount.provinceCode,
            thaiIdNumber: Global.phaccount.thaiIdNumber,
            firstName: Global.phaccount.firstName.toUpperCase(),
            phoneNumber: Global.phaccount.phoneNumber,
            passPortNumber: Global.phaccount.passPortNumber,
            dob: this.date(Global.phaccount.dob)
              .toAD()
              .format("YYYY-MM-DD"),
            district: Global.phaccount.district,
            driverAge: Global.phaccount.driverAge ? Global.phaccount.driverAge : this.dob2age(Global.phaccount.dob),
            subDistrict: Global.phaccount.subDistrict,
            subDistrictCode: Global.phaccount.subDistrictCode,
            smoker: Global.phaccount.smoker,
            height: Global.phaccount.height ? Global.phaccount.height : null,
            weight: Global.phaccount.weight ? Global.phaccount.weight : null,
            occupation: Global.phaccount.occupation?.apiName ? Global.phaccount.occupation?.apiName : "",
            occupationGroup: Global.phaccount.occupation?.occupationGroup ? Global.phaccount.occupation.occupationGroup.toString() : "",
            recordTypeName: "Person Account",
            Educational_Qualification: Global.phaccount.Educational_Qualification,
            Company_Revenue: Global.phaccount.Company_Revenue
          },
          payment: payment,
          beneficiary: Global.beneficiary,
        }
      };

      if(coverList.length > 0){
        quotation.item.coverList = coverList
      }

      return quotation
    },
    questionTooltip: function(item) {
      if (!item[0]?.questionHelpEn) {
        return "";
      }

      return Global.quote.prefLang == "en"
        ? item[0].questionHelpEn
        : item[0].questionHelpTh;
    },
    groupQuestions: function(questions) {
      questions.sort(function(a, b) {
        return a.questionNumber - b.questionNumber;
      });

      var groupedQuestions = questions.reduce(function(
        accumulator,
        currentValue
      ) {
        if (!accumulator[currentValue.questionText1En]) {
          accumulator[currentValue.questionText1En] = [];
        }
        accumulator[currentValue.questionText1En].push(currentValue);
        return accumulator;
      },
      {});

      return Object.values(groupedQuestions);
    },
    getOdataToken: async function() {
      var vm = this;

      vm.loading("show");

      try {
        const response = await vm.serviceRequest().post(Global.odataService.url.replace("api", "login"), 
          {
            username: Global.odataService.username,
            password: Global.odataService.password
          });

        Global.odataService.token = response.data.token;
        vm.loading("hide");
      } catch (error) {
        vm.loading("hide");
        vm.errorModal(vm.$t("error.connection.message"));
        console.error("getServiceToken : ", error);
        try{
          const response = await axios.post(Global.odataService.url.replace("/api", "") + "/email/sendmail", {
            mail_to: "daniel.yos@roojai.co.id, chalermsuk.sir@roojai.com, anoop@roojai.com, christopher.cho@roojai.co.id",
            mail_subject: "Odata Error",
            mail_message: error.message
          });
          console.log("send email response : ", response);
        }catch (error_email){
          console.error("send email error : ", error_email);
        }
      }
    },
    getOccupationsV2: async function(productType, insurer){

      var vm = this;
      if(!Global.occupationsV2){
        try {
          var response = await this.request().get(
            Global.restServer.url + "/v2/occupations", {
              params: {
                product_type: productType,
                insurer: insurer
              }
            }
          );
          Global.occupationsV2 = response.data;
        } catch (error) {
          if (error.response) {
            vm.frontEndLog("occupationsV2", "", error.response.status.toString());
          }
          console.error("getOccupationsV2 => ", error);
        }
      }
    },
    sortingOccupationsV2() {
      if (Global.occupationsV2) {
        if (Global.quote.prefLang == 'id') {
          const response = [];
          const additionalInfo = {
            "occupation_group": "Pekerjaan Populer:",
            "additional": true
          }
          response.push(additionalInfo);
          Global.occupationsV2.id.forEach((item, index) => {
            if (index == 3) {
              const additionalInfo = {
                "occupation_group": "Pekerjaan Lainnya: A - Z",
                "additional": true
              }
              response.push(additionalInfo);
            }
            let newObj = item;
            newObj.additional = false;
            response.push(newObj);
          })
          return response;
        } else if (Global.quote.prefLang == 'en') {
          const response = [];
          const additionalInfo = {
            "occupation_group": "Popular Jobs:",
            "additional": true
          }
          response.push(additionalInfo);
          Global.occupationsV2.en.forEach((item, index) => {
            if (index == 3) {
              const additionalInfo = {
                "occupation_group": "Other Jobs: A - Z",
                "additional": true
              }
              response.push(additionalInfo);
            }
            let newObj = item;
            newObj.additional = false;
            response.push(newObj);
          })
          return response;
        }
      }
      
    },
    setVariantTesting() {
      if (this.$route.query.abtesting !== undefined){
        Global.quote.abTestingVersion = this.$route.query.abtesting.toUpperCase();
      }
      if (this.$route.query.variant !== undefined){
        Global.abtest = this.$route.query.variant == 1 ? 'B' : 'A';
      }
      if (this.$route.query.variant === undefined){
        Global.abtest ='A';
      }
    },

    reedemFeefoAction(payload) {
      const response = axios.post(Global.roojaiService.url + "/rewardPoints/odata/redeem", payload, {
        headers: { Authorization: "Bearer " + Global.roojaiService.token }
      });
      return response;
    }
  },
  computed: {
    totalPremium: function() {
      var totalPremium = 0;
      var limitStampDuty = 5000000;
      var stampDuty = 10000;

      if(Global.premiumToPay > 0 && ["rec"].indexOf(Global.tp) > -1){
        totalPremium = Global.premiumToPay;
      }else if(Global.quote.productType == Global.enumProductType.Health){
        for (const selectPackage in Global.selectedPackages) {
          const value = Global.selectedPackages[selectPackage];
          if (value?.premiumBeforeTaxes?.value > 0 && "covid19".includes(selectPackage.toLowerCase())) {
            totalPremium += this.calculatePrice(value.premiumBeforeTaxes.value);
          } else if (value?.premiumBeforeTaxes?.value > 0) {
            totalPremium += this.calculatePrice(value.premiumBeforeTaxes.value);
            Global.totalPremiumRadar = totalPremium;
          }
        }
        return totalPremium > limitStampDuty ? totalPremium+ 0 : totalPremium;
      }else if(Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP){
        totalPremium = this.getTotalPremiumForPA();
        return totalPremium > limitStampDuty ? totalPremium+stampDuty : totalPremium;
      }

      if( typeof $ == "function" ){
        $("#nav-totalPremium, #package-totalPremium").addClass("rubberBand").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
            $(this).removeClass("rubberBand");
        });
      }
      // console.log("totalPremium ",totalPremium)

      // return totalPremium > limitStampDuty ? totalPremium+stampDuty : totalPremium;
      // return totalPremium
    },
    twelveMonthsNoDepositPremium: function() {
      var totalPremium = 0;
      var installment = {};
      var limitStampDuty = 5000000;
      var installmentAvailable = false
      var stampDuty = 10000;
      if(Global.quote.productType == Global.enumProductType.Health){
        for (const selectPackage in Global.selectedPackages) {
          const value = Global.selectedPackages[selectPackage];
          // console.log("selectPackage ci",value);
          if (value?.premiumBeforeTaxes?.value > 0 && ((!Global.isSuperCI && selectPackage != 'Covid19') || Global.isSuperCI) ) {
            totalPremium += this.calculatePrice(
              Math.floor(Math.round(value.premiumBeforeTaxes.value * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value)/1000)*1000);
          }
        }
        //yearly-installment old formula
        // totalPremium = totalPremium > limitStampDuty ? totalPremium + 0 : totalPremium;

        // console.log("totalPremium cix ", totalPremium)
        //yearly-installment new formula
        totalPremium = Math.floor((totalPremium > limitStampDuty ? totalPremium + 0 : totalPremium)/1000)*1000;
        // console.log("totalPremium ci ", totalPremium)
        if (totalPremium >= Global.CIminInstallmentTotalPremium) {
          installmentAvailable = true;
          // console.log("monthlyInstallment ", Math.floor(totalPremium/12))
          // console.log("totalInstallment ", Math.floor(totalPremium/12)*12/1000)*1000
          installment = {
            "installmentAvailable" : installmentAvailable,
            //old formula
            // "totalInstallment" : Math.round(totalPremium),
            // "monthlyInstallment":  Math.round(Math.round(totalPremium)/12)
            
            //new formula
            "totalInstallment" : Math.floor(Math.floor(totalPremium/12)*12/1000)*1000,
            "monthlyInstallment":  Math.floor(totalPremium/12)
          }
        }else{
          //yearly
          installment = {
            "installmentAvailable" : installmentAvailable,
            "totalInstallment" : totalPremium,
            //old formula
            // "monthlyInstallment":  Math.round(Math.round(totalPremium)/12)
            
            //new formula
            "monthlyInstallment":  Math.floor(totalPremium/12)
          }
        }
        // console.log("totalPremium cix ",totalPremium)
        // console.log("totalPremium/12 cix ",totalPremium/12)
        totalPremium = installment;
        // console.log("totalPremium ci ",installment)
      }else if(Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP){
        totalPremium = this.getInstallmentPremiumForPA();
      }

      if( typeof $ == "function" ){
        $("#nav-totalPremium, #package-totalPremium").addClass("rubberBand").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
            $(this).removeClass("rubberBand");
        });
      }
// console.log("totalPremium twelveMonthsNoDepositPremium ",totalPremium)
      return totalPremium;
    },
    twelveMonthsNoDepositPremiumPlus: function() {
      return this.twelveMonthsNoDepositPremium + this.finalCovidPremium;
    },
    cancerCoverages: function() {
      if (Global.selectedPackages?.Cancer?.coverages?.coverage?.length > 0) {
        var coverages = Global.selectedPackages.Cancer.coverages.coverage.filter(
          function(i) {
            return i.additionalCoverageName.value != "Skin cancer - Melanoma";
          }
        );

        return coverages.length ? coverages : [];
      }

      return [];
    },
    AvailableInstallment: function (){
      if (Global.quote.productType == Global.enumProductType.Health) {
        return ((this.totalPremium * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value) > 1440000 ? {VA : true,CC : true} : (this.totalPremium*Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value) < 1440000 && (this.totalPremium*Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value) > 960000 ? {VA : false,CC : true} : {VA : false,CC : false})
      }else if(Global.quote.productType === Global.enumProductType.PA){
        return ((this.totalPremium*Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value) > 432000 ? {VA : true,CC : true} : (this.totalPremium*Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value) < 432000 && (this.totalPremium*Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value) > 288000 ? {VA : false,CC : true} : {VA : false,CC : false})
      }else{
        return {
          VA : false,
          CC : false
        }
      }
    },
    isAllowInstallment: function(){
      if(Global.isSuperCI){
        return true;
      }else if((Global.isAgentCustomerView && Global.brokerInfo.useInstallment == false) && Global.brokerInfo.intermediaryReptEmail != ""){
        return false
      }else if(Global.quote.productType === Global.enumProductType.PA || Global.quote.productType === Global.enumProductType.HCP || Global.quote.productType == Global.enumProductType.Health){
        return this.twelveMonthsNoDepositPremium.installmentAvailable ? true : false;
      }else{
        return (Object.keys(Global.selectedPackages).length === 1 && "Covid19" in Global.selectedPackages) ? false : true;
      }
    },
    finalCovidPremium: function(){
      return this.calculatePrice(Global.selectedPackages?.Covid19
        ?.premiumBeforeTaxes?.value > 0
        ? Global.selectedPackages.Covid19
            .premiumBeforeTaxes.value
        : 0);
    },
    firstInstallmentNoDepositPremium: function(){
      // console.log("twelveMonthsNoDepositPremium ",this.twelveMonthsNoDepositPremium)
      // console.log("finalCovidPremium ",this.finalCovidPremium)
      return this.twelveMonthsNoDepositPremium / 12 + this.finalCovidPremium;
    },
    isDisplayInstallmentPrice: function(){
      if(Global.paymentFrequency != 'Yearly' && this.twelveMonthsNoDepositPremium.installmentAvailable){
        return true;
      }else if(Global.paymentFrequency != 'Yearly' && !this.twelveMonthsNoDepositPremium.installmentAvailable){
         $("#payment-change-to-yearly").modal("show");
         Global.showModalChangeToYearly = true;
        return false;
      }else {
        return false;
      }
    },
    ABTestingPackage:function(){
        return Global.quote.abTestingVersion[0];
    },
    currentPage: function() {
      return this.$route.path.toLowerCase();
    },
    urlDecommission: function() {
      return {
        urlSaveQuote: Global.roojaiService.url + "/quotations/save",
        urlPaymentCC: Global.roojaiService.url + "/payments/creditCard",
        urlPaymentVA: Global.roojaiService.url + "/payments/createVAXendit", 
        token: Global.roojaiService.token 
      }
      // if(Global.useUrl == "mcs") 
      // else return {urlSaveQuote: Global.odataService.url + "/quote/saveQuote",urlPaymentCC: Global.odataService.url + "/payment/creditCard",urlPaymentVA:  Global.odataService.url + "/payment/createVAXendit", token: Global.odataService.token }
    },
    isFirstMonthInstallment: function(){
      if(this.twelveMonthsNoDepositPremium / 12 === this.firstInstallmentNoDepositPremium) return false;
      else return true;
    }
  }
};
</script>