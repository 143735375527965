import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import VOtpInput from "vue3-otp-input";
import VueToast from 'vue-toast-notification';
import VueCountdown from '@chenfengyuan/vue-countdown';
import 'vue-toast-notification/dist/theme-sugar.css';
import mvLang from './views/motor-vehicle/lib/languages';
import privacyPolicyMV from './views/motor-vehicle/lib/languages/privacy_policy.js';
import limitationOFLiability from './views/motor-vehicle/lib/languages/limitation_of_liability.js';

const messages = {
  en: {
    title: "Let's start",
    yourGender: "Your gender",
    male: "Male",
    female: "Female",
    maritalStatus: "Gender and marital status",
    maritalStatus_singleman: "Single man",
    maritalStatus_marriedman: "Married man",
    maritalStatus_singlewoman: "Single woman",
    maritalStatus_marriedwoman: "Married woman",
    single: "Single",
    married: "Married",
    dob: "Your date of birth",
    dobDate:"DD",
    dobMonth:"MM",
    dobYear:"YYYY",
    postcode: "Postcode",
    dob_example: "Example: 2 September 1980 is 02/09/1980",
    dob_placehoder: "02/09/1980",
    pleaseSelectAValidDate: "Please select a valid date",
    height: "Height (cm)",
    weight: "Weight (kg)",
    occupation:"Your Occupation",
    chooseOccupation:"Choose your occupation",
    yourIncome:"Your monthly income/salary (IDR)",
    chooseYourSalaryRange:"Choose your salary range",
    dragOrTouch: "drag or touch",
    andMore:"{0} and more",
    upTo:"up to",
    noIncome:"No income",
    smokingHabit:"How many cigarettes do you smoke in a day?",
    smokingHabit_never: "Never",
    smokingHabit_occasional: "1-10 max per day",
    smokingHabit_frequent: "More than 10 per day",
    smokingHabit_reject: "Unfortunately, Roojai has not been able to process your purchase",
    educationalInfo: "We may request you to submit a copy of your certificate at the time of claim submission.",
    blackListOccupation : "Legal, public figures, religious figures, medical, professional sports, divers, chemical factory worker, oil & gas extraction, forestry & logging, welding, working above or underground, explosive factory worker, Heavy machinery users / smeltery / coal / asbestos, workers onboard vessels (stationary or offshore), power plant/ nuclear power plant, medical devices manufacturing plant worker.",
    educationalTitle: "Please specify your educational level",
    educational_primary: "Primary & Junior High School",
    educational_highschool: "Senior High School or Vocational School",
    educational_college: "University and above",
    grossInfo: "We may require a proof of income at the time of claim submission.",    
    grossTitle: "Please help us understand your business size",
    gross_small: "< 1 Bio IDR per year",
    gross_medium: "1-10 Bio IDR per year",
    gross_big: "10+ Bio IDR per year",
    tooltip: {
      maritialStatus:"With this information, Roojai could provide you with the right policy options.",
      dob: "We would be very happy to provide you the best protection based on your age!",
      heightAndWeight:"This information will help us provide you with the most suitable plan.",
      occupation:"Help us get to know you better by letting us know your occupation.",
      income:"You don’t need to worry; this information is confidential and guaranteed to be secure.",
      smokingHabit:"All the information you provide is confidential and securely stored as per Indonesian regulation.",
      educational:"Help us determine closely the best terms and conditions for your profile.",
      gross:"Help us determine closely the best terms and conditions for your profile.",
      postcode: "By knowing your residential address postcode, we can provide you the best protection options."
    },
    yesWithOtherCompanies:"Yes, with other companies.",
    yesWithSOMPO:"Yes, with PT Sompo Insurance Indonesia.",
    getYourQuote:"Get your quote",
    acceptAndNext:"Click \"Get your quote\" to confirm that your all information is true and get your quote now. Price and coverage depends on accurate information",
    oneMoreStep:"One more click to get protected by Roojai!",
    loading: "Loading",
    next: "Next",
    back: "Back",
    confirm: "Confirm",
    cancel: "Cancel",
    submit: "Submit",
    close: "Close",
    select: "Please select",
    continue: "Continue",
    question_yes: "Yes",
    question_no: "No",
    question_no_policy: "No",
    roojaiName: "PT Roojai Insurance Agent",
    roojaiAddressLine1: "CIBIS 9 17th Floor",
    roojaiAddressLine2: "Jl. TB Simatupang No.2 Cilandak Timur, Pasar Minggu, Jakarta Selatan 12560",
    ojk: "Registered and supervised by",
    mobile_placehoder: "example 0856 222 2222",
    quotationNo: "Quotation no.",
    getEmail : "Please check your spam folder to make sure you receive our email.",
    getEmailAgent : "Quotation details will be sent to agent’s email. ",
    month: "Month",
    year: "Year",
    instalment12months:"12 monthly instalments",
    sumInsured:"Sum insured",
    selected:"You selected",
    nonselected:"You didn't select",
    days:"Days",
    day: "Day",
    province : "Province",
    city : "City",
    confirmPackage: "Get covered now",
    eQuoteGet:"or receive quote by",
    eQuoteTitle: "Email my quote",
    eQuoteTitleCall: "Call me",
    eQuoteEmail: "Email",
    eQuoteWhatsapp: "WhatsApp",
    eQuoteWhatsappMessage: "https://api.whatsapp.com/send?phone=6281804133340&text=Hai, thanks for your interest in Roojai! Please answer below to get your quote now!",
    wacall: "Contact me on WhatsApp",
    pleaseEnterYourWaNumber: "I agree to be contacted by WhatsApp using the number listed below",
    eQuoteCall: "Call",
    eQuote: "Receive quote via email",
    eQuoteAgent: "Want to talk to an agent? ",
    eQuoteExplainViaMobile:"Do you want us to call you to explain your policy?",
    eQuoteExplainViaMobileAgent:"You can leave your phone number and an agent will call you for further assistance. ",
    eQuoteSkipNext: "No, thank you ",
    yourCoverageInsurance: "Your Coverage Summary",
    eQuoteNofee: "(Don't worry, no fee or charges too.)",
    paymentChangeToYearlyHeader: "Not eligible for the instalment option",
    paymentChangeToYearly : "Sorry, the minimum premium for the instalment option is",
    eQuoteAmazing: "Done!",
    eQuoteDetail:"Your coverage details have been sent to your inbox. Check your inbox or click \"Get covered\" to continue.",
    eQuoteDetailAgent:"Your quote has been emailed to your agent and he/she will contact you shortly. If you’re ready to buy, simply click the “Get covered now”.",
    eQuoteCustomize: "Customize again",
    roojaiCallmeback: "Call me",
    plans:{
      cover:"Covered",
      notcover: "Not covered",
      flexi:"Popular plan",
      strong:"Caring plan",
      value:"Value",
      saving:"Saving",
      standard: "Standard",
      recommended: "Recommended",
      premium:"Premium",
      super: "Super Plan",
    },
    yearly:"/annual",
    switchYearly:"Annual",
    switchMonthly:"Monthly",
    economical: "Disc.",
    thankyoucallAgent:"Your insurance agent has been notified and will call you soon! ",
    thankyoucall: "Thank you for your interest in Roojai. We will contact you immediately on : ",
    thankyoucallExc1: "Thank you for your interest in Roojai.",
    thankyoucallExc2: "We will contact you immediately on : ",
    thankyouweekday : "Monday – Saturday at 08:00 – 17:00",
    thankyouweekend : "Public Holiday: Off",
    covidIncluded_in_first_instalment:"COVID-19 insurance {0} included in the first instalment",
    debitSaveFail : "We couldn’t save your card",
    debitSaveFailMsg : "Please make sure your card information is accurate and try again.",
    debitSaveFailbtn : "Try again",
    debitFail : "Payment failed ",
    debitFailMsg : "Please ensure that your bank account have sufficient balance. You can reach out to our Contact Center to use another payment method.",
    debitFailbtn1 : "Contact Roojai via Phone",
    debitFailbtn2 : "Back to Home Page",
    debitThankyouTitle : "Payment has been Received! ",
    debitThankyouMsg1 : "Thank you for your trust in Roojai.",
    debitThankyouMsg2 : "Please check the email we sent you to see the transaction details and to proceed to the next step.",
    buttonDebitThankyou : "Back to Homepage",
    footerDebitTitle : "Contact Center Hotlines",
    footerDebitHealth : "Health Insurance",
    footerDebitCar : "Car Insurance",
    packageUnavailableTitle: "We’re sorry, we're not able to process your profile",
    packageUnavailable: "Unfortunately, your profile doesn’t meet the requirement for this insurance. You may want to check other insurance products from Roojai.",
    packageUnavailable2: "Have questions or need more information? Please ",
    packageUnavailableContact: "Contact Us",
    blackListTitle: "We’re sorry, we're not able to process your profile",
    blackList: "Unfortunately, your profile doesn’t meet the requirement for this insurance.",
    blackList2: "Have questions or need more information? Please ",
    blackListContact: "Contact Us",
    blockPostalCodeTitle: "We’re sorry, we're not able to process your profile",
    blockPostalCode: "Unfortunately, your profile doesn’t meet the requirement for this insurance.",
    blockPostalCode2: "Have questions or need more information? Please ",
    blockPostalCodeContact: "Contact Us",
    alreadyPaidTitle: "Your Transaction has been Paid",
    alreadyPaid: "We’re currently processing your insurance policy purchase.",
    alreadyPaid2: "Have questions or need more information? Please ",
    alreadyPaidContact: "Contact Us",
    trxNotFoundTitle: "Sorry, we’re experiencing a technical issue",
    trxNotFound: "Please try again in a few minutes or if you need assitance, please ",
    trxNotFoundContact: "Contact Us",
    confirmHighestSITitle:"Important to Read",
    confirmHighestSIList1:"I confirm that all the information I provided are accurate.",
    confirmHighestSIList2:"I confirm that I don't have any pre-existing conditions that would exclude me from my chosen coverage of this insurance product.",
    confirmHighestSIList3:"I grant Roojai the authority to conduct a medical information verification during the policy's waiting period (first 90 days).",
    confirmHighestSIList4:"I agree to provide Roojai with the required medical information for the medical information verification (Member Medical Inquiry form will be sent separately over email).",
    confirmHighestSIList5: {
      text: "If the information I provided is proven to be inaccurate, ",
      bold1: "I agree that my policy will be cancelled",
      and: "and",
      bold2: "there will be no premium refund.",
    },
    confirmHighestSIFooter: "If you are unsure about whether or not you have a pre-existing condition, please reach out to our Contact Center at ",
    confirmHighestSIFooter2: " for assistance.",
    confirmHighestSIAgree: "I Agree",
    confirmHighestSIDisagree: "I Don't Agree",
    validateQuoteUnavailableTitle: "We're sorry but there is already a policy for ({0}) under your name in our system.",
    validateQuoteUnavailableParagraph1: "In accordance with Article 252 of the Indonesian Commercial Code, having a second insurance with the Insurer on the same coverage will cause the second insurance to be void;",
    validateQuoteUnavailableParagraph2: "Therefore our internal policy prohibits the purchase of such second insurance. If you think this is an error, please contact us at (021) 5089 0822.",
    rejection2: "We require further underwriting information to be able to process your policy. We will contact you shortly.",
    detail: "Detail",
    getMoreDetails: "Get more details in our Terms & Conditions.",
    yourMobilePhone: "Enter phone number",
    callMeBack: "I would like to receive a call",
    pleaseEnterYourMobileNumber: "Please enter your mobile number, our team will call you to explain the products.",
    pleaseEnterYourMobileNumberAgent: "Please enter your mobile number, Agent will call you to explain the products.",
    emailOptedOut:"I want receive newsletter and products promotions from Roojai",
    pdpaText: "This website uses cookies to improve performance and collect analytics. By continuing to use the website, you accept our",
    pdpaPrivacypolicy: "Privacy policy",
    pdpaOur: " ",
    pdpaAccept: "Accept",
    postcodeBasic:"Enter Your Residential Address Postcode",
    pleaseInsertAZipCode: "You need to enter a valid postcode.",
    'error.whatsapp.message' : 'Your phone number is not registered with WhatsApp',
    'error.connection.message': 'It looks like our server is playing up right now. Try again in a bit.',
    motorVehicle: mvLang.en,
    privacyMv: privacyPolicyMV.en,
    limitation: limitationOFLiability.en
  },
  id: {
    title: "Ayo mulai sekarang",
    yourGender: "Jenis kelamin",
    male: "Pria",
    female: "Wanita",
    maritalStatus: "Jenis kelamin dan status pernikahan",
    maritalStatus_singleman: "Pria lajang",
    maritalStatus_marriedman: "Pria sudah menikah",
    maritalStatus_singlewoman: "Wanita lajang",
    maritalStatus_marriedwoman: "Wanita sudah menikah",
    single: "Single",
    married: "Menikah",
    dob: "Tanggal lahir kamu",
    dobDate:"DD",
    dobMonth:"MM",
    dobYear:"YYYY",
    postcode: "Kode pos",
    dob_example: "Contoh 02 September 1980 is 02/09/1980",
    dob_placehoder: "02/09/1980",
    pleaseSelectAValidDate: "Mohon masukkan tanggal yang sesuai",
    height: "Tinggi (cm)",
    weight: "Berat (kg)",
    occupation:"Pekerjaan",
    chooseOccupation:"Silahkan pilih pekerjaan kamu",
    yourIncome:"Besar gaji/penghasilan bulanan (IDR)",
    chooseYourSalaryRange:"Silahkan pilih besar gaji",
    dragOrTouch: "tarik atau geser",
    andMore:"{0} dan lebih",
    upTo:"sampai dengan",
    noIncome:"Tidak ada pemasukan",
    smokingHabit:"Berapa banyak batang rokok yang kamu hisap dalam 1 hari?",
    smokingHabit_never: "Tidak pernah",
    smokingHabit_occasional: "1-10 per hari",
    smokingHabit_frequent: "lebih dari 10 per hari",
    smokingHabit_reject: "Maaf, Roojai belum bisa memproses pembelian kamu",
    blackListOccupation : "Hukum, figur publik, tokoh agama, medis, atlet profesional, penyelam, buruh pabrik kimia, ekstraksi minyak & gas, kehutanan & penebangan, pengelasan, bekerja di ketinggian atau di bawah tanah, pekerja pabrik bahan peledak, pengguna mesin berat / peleburan / batubara / asbes, pekerja di atas kapal (stasioner atau lepas pantai), pembangkit listrik / pembangkit listrik tenaga nuklir, buruh pabrik pembuatan alat kesehatan",
    educationalTitle: "Apa Pendidikan Terakhir kamu?",
    educationalInfo: "Kami mungkin akan meminta kamu untuk menyerahkan bukti ijazah pada saat mengajukan klaim.",
    educational_primary: "SD & SMP",
    educational_highschool: "SMA atau SMK",
    educational_college: "Jenjang Universitas",
    grossInfo: "Kami mungkin akan meminta kamu untuk menyerahkan bukti laporan pada saat mengajukan klaim.",
    grossTitle: "Sebesar apa bisnis yang kamu punya?",
    gross_small: "Keuntungan < 1 miliar per tahun",
    gross_medium: "Keuntungan 1-10 miliar per tahun",
    gross_big: "Keuntungan 10+ miliar per tahun",
    tooltip: {
      maritialStatus:"Dengan memberikan informasi ini, kami bisa menampilkan kamu pilihan polis yang sesuai.",
      dob: "Roojai akan memberikan kamu proteksi yang sesuai dengan usiamu.",
      heightAndWeight:"Informasi ini juga dapat membantu kami mempersiapkan pilihan-pilihan polis yang paling sesuai.",
      occupation:"Dengan mengetahui pekerjaan kamu, kami dapat memberikan pilihan proteksi yang tepat untukmu.",
      income:"Kamu tidak perlu khawatir, informasi ini bersifat rahasia dan dijamin keamanannya!",
      smokingHabit:"Informasi yang kamu berikan bersifat rahasia dan disimpan dengan aman berdasarkan peraturan di Indonesia.",
      educational:"Informasi ini dapat membantu kami dalam memberikan ketentuan polis terbaik untuk kamu.",
      gross:"Informasi ini dapat membantu kami dalam memberikan ketentuan polis terbaik untuk kamu.",
      postcode: "Dengan mengetahui kode pos tempat tinggalmu, kami dapat memberikan pilihan proteksi yang tepat untukmu."
    },
    yesWithOtherCompanies:"Ya, dengan perusahaan lain.",
    yesWithSOMPO:"Ya, dengan PT Sompo Insurance Indonesia.",
    debitSaveFail : "Kartumu gagal disimpan",
    debitSaveFailMsg : "Mohon pastikan informasi pada kartu sudah benar dan coba lagi.",
    debitSaveFailbtn : "Coba lagi",
    debitFail : "Pembayaran gagal ",
    debitFailMsg : "Mohon pastikan saldo di rekening bankmu cukup. Silakan menghubungi Contact Center kami untuk mengubah metode pembayaran.",
    debitFailbtn1 : "Hubungi Roojai via Telepon",
    debitFailbtn2 : "Kembali ke Halaman Utama",
    debitThankyouTitle : "Pembayaran Berhasil Diterima!",
    debitThankyouMsg1 : "Terima kasih telah mempercayakan Roojai untuk memenuhi perlindunganmu.",
    debitThankyouMsg2 : "Silakan cek email yang kami kirimkan untuk lihat detail transaksi dan lanjut ke proses selanjutnya.",
    buttonDebitThankyou : "Kembali ke Halaman Utama",
    footerDebitTitle : "Layanan Kontak Center",
    footerDebitHealth : "Asuransi Kesehatan",
    footerDebitCar : "Asuransi Mobil",
    getYourQuote:"Dapatkan penawaran sekarang",
    acceptAndNext:"Klik tombol \"Dapatkan penawaran sekarang\", untuk mengonfirmasi informasi yang kamu berikan. Harga premi dan juga manfaat yang kamu dapatkan akan bergantung pada informasi yang benar",
    oneMoreStep:"Satu klik lagi untuk mendapatkan proteksi dari Roojai!",
    loading: "Memuat",
    next: "Selanjutnya",
    back: "Kembali",
    confirm: "Konfirmasi",
    cancel: "Batal",
    submit: "Kirim",
    close: "Tutup",
    select: "Silahkan pilih",
    continue: "Lanjut",
    question_yes: "Ya",
    question_no: "Tidak",
    question_no_policy: "Tidak",
    roojaiName: "PT Roojai Insurance Agent",
    roojaiAddressLine1: "CIBIS 9 Lantai 17",
    roojaiAddressLine2: "Jl. TB Simatupang No.2 Cilandak Timur, Pasar Minggu, Jakarta Selatan 12560",
    ojk: "Terdaftar dan diawasi oleh",
    mobile_placehoder: "Contoh 0856 222 2222",
    quotationNo: "Nomor penawaran",
    getEmail : "Silahkan cek folder spam kamu dan pastikan kamu menerima email dari kami",
    getEmailAgent : "Detail penawaran akan dikirimkan ke email agen. ",
    month: "Bulan",
    year: "Tahun",
    instalment12months:"Cicilan 12 bulan",
    sumInsured:"Uang pertanggungan",
    selected:"Pertanggungan yang kamu pilih",
    nonselected:"Pertanggungan yang tidak dipilih",
    day:"Hari",
    days:"Hari",
    confirmPackage: "Lindungi dirimu sekarang",
    eQuoteGet:"atau terima penawaran melalui",
    eQuoteTitle: "Email penawaran",
    eQuoteTitleCall: "Hubungi saya",
    eQuoteEmail: "Email",
    eQuoteWhatsapp: "WhatsApp",
    eQuoteWhatsappMessage: "https://api.whatsapp.com/send?phone=6281804133340&text=Hai, terima kasih atas minat kamu kepada Roojai. Balas chat ini dan dapatkan penawaran kamu sekarang!",
    wacall: "Menghubungi saya melalui WhatsApp",
    pleaseEnterYourWaNumber: "Saya sepakat untuk dihubungi melalui WhatsApp menggunakan nomor yang tercatat di bawah",
    eQuoteCall: "Telepon",
    province : "Provinsi",
    city : "Kota",
    eQuote: "Terima penawaran melalui email",
    eQuoteAgent: "Ingin berbicara langsung dengan agent? ",
    eQuoteExplainViaMobile:"Apakah kamu menginginkan kami untuk menjelaskan polis asuransi mu melalui telephone?",
    eQuoteExplainViaMobileAgent:"Kamu bisa tinggalkan nomor teleponmu dan agen akan menghubungimu untuk memberi bantuan. ",
    eQuoteSkipNext: "Tidak, terima kasih",
    yourCoverageInsurance: "Polis asuransi kamu",
    eQuoteNofee: "(Jangan khawatir, bebas biaya)",
    paymentChangeToYearlyHeader: "Tidak memenuhi syarat untuk opsi cicilan",
    paymentChangeToYearly : "Maaf, minimum premi untuk opsi cicilan adalah",
    eQuoteAmazing: "Selesai!",
    eQuoteDetail:"Penawaran kamu sudah dikirim ke e-mail. Cek inbox dan folder spam kamu atau klik 'lindungi dirimu sekarang' untuk lanjut.",
    eQuoteDetailAgent:"Penawaranmu sudah dikirimkan ke email agen dan kamu akan segera dihubungi. Jika kamu sudah siap untuk membeli, klik tombol “Lindungi dirimu sekarang” ",
    eQuoteCustomize: "Sesuaikan lagi",
    roojaiCallmeback: "Hubungi saya",
    plans:{
      cover:"Ditanggung",
      notcover: "Tidak ditanggung",
      flexi:"Paket 2",
      strong:"Paket 3",
      family:"Paket 4",
      saving:"Hemat",
      value:"Ekonomis",
      standard: "Standar",
      recommended: "Rekomendasi",
      premium:"Premium",
      super: "Paket Super",
    },
    yearly:"/tahun",
    switchYearly:"Tahun",
    switchMonthly:"Bulan",
    economical : "Diskon",
    thankyoucallAgent: "Agen asuransimu telah menerima permintaan panggilan dan akan segera menghubungimu ",
    thankyoucall: "Terima kasih atas kepercayaanmu terhadap Roojai. Kami akan segera menghubungi kamu pada :",
    thankyoucallExc1: "Terima kasih atas kepercayaanmu terhadap Roojai.",
    thankyoucallExc1Agent: "Terima kasih atas kepercayaanmu terhadap Kami.",
    thankyoucallExc2: " Kami akan segera menghubungi kamu pada :",
    thankyouweekday : "Senin-Sabtu 08:00-17:00",
    thankyouweekend : "Hari Libur Nasional: Off",
    covidIncluded_in_first_instalment:"Asuransi COVID-19 {0} sudah termasuk dalam cicilan pertama",
    packageUnavailableTitle: "Mohon maaf, kami belum bisa memproses profile kamu",
    packageUnavailable: "Sayang sekali, profile kamu belum memenuhi persyaratan pembelian asuransi ini. Tapi kamu masih bisa cek produk asuransi Roojai lainnya. ",
    packageUnavailable2: "Punya pertanyaan atau butuh info lebih lanjut? Silakan ",
    packageUnavailableContact: "Hubungi Kami",
    blackListTitle: "Mohon maaf, kami belum bisa memproses profile kamu",
    blackList: "Sayang sekali, profile kamu belum memenuhi persyaratan pembelian asuransi ini.",
    blackList2: "Punya pertanyaan atau butuh info lebih lanjut? Silakan ",
    blackListContact: "Hubungi Kami",
    blockPostalCodeTitle: "Mohon maaf, kami belum bisa memproses profile kamu",
    blockPostalCode: "Sayang sekali, profile kamu belum memenuhi persyaratan pembelian asuransi ini.",
    blockPostalCode2: "Punya pertanyaan atau butuh info lebih lanjut? Silakan ",
    blockPostalCodeContact: "Hubungi Kami",
    alreadyPaidTitle: "Transaksi Kamu Telah Dibayarkan",
    alreadyPaid: "Pembelian polis asuransimu sedang kami proses.",
    alreadyPaid2: "Punya pertanyaan atau butuh info lebih lanjut? Silakan ",
    alreadyPaidContact: "Hubungi Kami",
    trxNotFoundTitle: "Maaf, kami mengalami gangguan teknis",
    trxNotFound: "Silakan coba lagi dalam beberapa menit jika butuh bantuan silakan ",
    trxNotFoundContact: "Hubungi Kami",
    validateQuoteUnavailableTitle: "Oops! Sepertinya namamu sudah terdaftar sebagai pemegang polis ({0}) di sistem kami.",
    confirmHighestSITitle:"Penting Untuk Dibaca",
    confirmHighestSIList1:"Saya setuju bahwa semua informasi yang sudah saya berikan adalah benar.",
    confirmHighestSIList2:"Saya mengkonfirmasi bahwa saya tidak memiliki riwayat penyakit/keadaan yang sudah ada (pre-existing condition) yang dikecualikan oleh produk asuransi pilihan saya.",
    confirmHighestSIList3:"Saya bersedia memberikan kuasa kepada Roojai untuk melakukan verifikasi informasi medis kepada saya yang akan dilakukan selama masa tunggu polis (90 hari pertama).",
    confirmHighestSIList4:"Saya bersedia memberikan informasi kesehatan saya yang diperlukan Roojai untuk melakukan verifikasi informasi medis (formulir Pertanyaan Kesehatan Nasabah akan dikirimkan melalui email).",
    confirmHighestSIList5: {
      text: "Jika ditemukan informasi yang saya berikan tidak benar, maka saya ",
      bold1: "setuju jika polis saya dibatalkan",
      and: "dan",
      bold2: "tidak ada pengembalian premi",
    },
    confirmHighestSIFooter: "Jika kamu ragu apakah kamu memiliki riwayat penyakit/keadaan yang sudah ada, silakan hubungi Contact Center kami di ",
    confirmHighestSIFooter2: " untuk mendapatkan bantuan",
    confirmHighestSIAgree: "Saya setuju",
    confirmHighestSIDisagree: "Saya tidak setuju",
    validateQuoteUnavailableParagraph1: "Menurut Pasal 252 KUHD, memiliki perlindungan dengan manfaat yang sama kepada pihak asuransi yang sama akan menyebabkan perlindungan kedua dibatalkan.",
    validateQuoteUnavailableParagraph2: "Maka dari itu kami tidak memperbolehkan pembelian polis serupa di waktu yang sama. Apabila menurutmu telah terjadi kesalahan, mohon hubungi kami di (021) 5089 0822.",
    rejection2: "Kami membutuhkan informasi penjaminan emisi lebih lanjut untuk memproses polis kamu. Kami akan segera menghubungi kamu.",
    detail: "Detil",
    getMoreDetails: "Dapatkan informasi lebih lanjut terkait Syarat & Ketentuan.",
    yourMobilePhone: "Masukkan nomor telepon",
    callMeBack: "Saya ingin menerima penawaran",
    pleaseEnterYourMobileNumber: "Silahkan masukkan nomor ponsel kamu untuk dihubungi oleh tim kami. ",
    pleaseEnterYourMobileNumberAgent: "Silahkan masukkan nomor ponsel kamu untuk dihubungi oleh Agen. ",
    emailOptedOut:"Saya ingin menerima newsletter dan promosi tentang produk lain dari Roojai",
    pdpaText: "Situs web ini menggunakan cookie untuk meningkatkan kinerja dan mengumpulkan analitik. Dengan menggunakan web kami, berarti kamu menerima",
    pdpaPrivacypolicy: "Kebijakan pribadi",
    pdpaOur: " ",
    pdpaAccept: "Terima",
    postcodeBasic:"Masukkan Kode Pos Domisilimu",
    pleaseInsertAZipCode: "Mohon masukkan kode pos yang valid.",
    'error.whatsapp.message' : 'Nomor ponsel kamu tidak terdaftar di WhatsApp',
    'error.connection.message': 'Sepertinya server kami sedang terkendala sekarang. Coba lagi beberapa saat.',
    motorVehicle: mvLang.id,
    privacyMv: privacyPolicyMV.id,
    limitation: limitationOFLiability.id
  },
};

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: "id", // set locale default
  fallbackLocale: "id", // set fallback locale
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

createApp(App)
  .component(VueCountdown.name, VueCountdown)
  .component('v-otp-input', VOtpInput)
  .use(router)
  .use(i18n)
  .use(VueToast)
  .mount("#app");

  window.apps = App;