<style>
body.default-style .tooltip .tooltip-inner,
body.default-style .tooltip.fade.top.in .tooltip-inner {
  width: auto;
  max-width: 320px;
  min-width: 280px;
  background-color: rgba(0, 59, 113, 0.95);
  text-align: left;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
body.default-style .tooltip.fade.top.in {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: -10px 5px 0 0;
  font-family: "Prompt", Helvetica, Arial, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 1;
  height: auto;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(5px, 64px, 0px);
  font-size: 1.35rem;
}
body.default-style .tooltip.top {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: -10px 5px 0 0;
  font-family: "Prompt", Helvetica, Arial, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.35rem;
  word-wrap: break-word;
  height: auto;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(5px, 64px, 0px);
}
body.default-style .tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.4rem;
  bottom: 0rem;
  background-color: rgba(0, 59, 113, 0);
}
body.default-style .tooltip .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  position: absolute;
  content: "";
  border: solid transparent;
  border-top: 0;
}
  body.default-style .questions>.container {
      max-width: 630px;
      width: 100%;
      padding: 0 15px;
  }

  body.default-style #top-bar[data-style-version='B'] {
    box-shadow: silver 0 1px 5px 0;
    -webkit-box-shadow: silver 0 1px 5px 0;
    /* padding: 2rem 0; */
}

  body.default-style #top-bar #select-lang .dropdown-menu {
      width: 92px!important;
  }
  body.default-style #top-bar #select-lang .dropdown-menu li>a {
      padding: 5px 5px!important;
  }

  body.default-style header.default nav .container .tab4year2price .pay2stage{
    display: inline-block!important;
    position: relative!important;
  }

  body.default-style header.default nav .container .tab4year2price .pay2stage h2#summary-price{
    font-size: 45px;
  }

  body.default-style header.default nav .container .tab4year2price .pay2stage .price-div{
    font-size: 45px;
  }

  body.default-style header.default nav .container .tab4year2price .pay2stage .price-div.price{
    font-size: 45px;
  }
  body.default-style header.default nav .container .tab4year2price .pay2stage{
    text-align: center;
  }
  body.default-style header.default nav.fixed-menu #back-btn{
    margin-top: -4px;
  }
  .details2drop4v3{
    top: 50%;
    transform: translateY(-50%);
  }
/* 
  body.default-style header.default nav .container .tab4year2price .pay2stage .price-div .installment{
    font-size: 2;
  } */

  @media (max-width: 767px){
    body.default-style header.default nav .container .tab4year2price .pay2stage .price-div{
      font-size: 1.8rem;
    }

    body.default-style header.default nav .container .tab4year2price .pay2stage .price-div .installment{
      font-size: 1rem;
    }
  }
  @media (max-width: 767px){
    body.default-style .footer .footer-company, body.default-style footer .footer-company{
      text-align: center!important;
    }
  }

  @media (max-width: 640px) {
    body.default-style header.default nav .container .tab4year2price .pay2stage h2#summary-price{
      margin:0!important;
      font-weight:700;
      font-size:2rem;
      display:block;
      text-align:center
    }
    body.default-style header.default nav .container .price{
      margin-top:5px!important;
      margin-left: 5px;
      font-weight:700;
      font-size:1.8rem;
    }
    body.default-style header.default nav .container .total-price{
      margin-top:10px!important;
      font-weight:700;
      font-size:1.8rem;
    }
  }

  @media (max-width:415px){
    body.default-style header.default nav .container .tab4year2price .pay2stage h2#summary-price{
      margin:0!important;
      font-weight:700;
      font-size:1.7rem;
      display:block;
      text-align:center
    }
    body.default-style .badge{
      font-size: 80%;
      color: #fff;
    }

  }

  @media (max-width: 350px){
    body.default-style header.default nav .container .tab4year2price .pay2stage .price-div{
      font-size: 1.2rem;
    }

    body.default-style header.default nav .container .tab4year2price .pay2stage h2#summary-price {
      font-size: 1.4rem;
      margin: 0!important;
      font-weight: 700;
      display: block;
      text-align: center;
    }

    body.default-style header.default nav .container .tab4year2price .pay2stage .price-div .installment{
      font-size: .8rem;
    }
  }
  @media (max-width: 370px){
    body.default-style .footer .footer-standard-logo img, body.default-style footer .footer-standard-logo img{
      max-width: 60px;
    }
  }
  @media (max-width: 320px){
    body.default-style .footer .footer-standard-logo img, body.default-style footer .footer-standard-logo img{
      max-width: 50px;
    }
  }
  

  body.default-style header.default nav .container .install2line {
    margin: 0px auto 5px auto!important;
    padding: 0px 0px 10px 0!important;
  }

#installment-detail-modal .modal-header{
  display: block;
}

#installment-detail-modal .modal-header .close{
  color: #ff5011;
}

#installment-detail-modal .modal-body .installment-option {
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  border: 1px solid #ece9e8;
  color: grey
}

#installment-detail-modal .modal-body .installment-option .panel-body{
  padding: 15px;
}

#installment-detail-modal .modal-body .installment-option.active {
    background-color: #fff !important;
    border: 1px solid #ff5011 !important;
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7) !important;
    color: #003b71 !important
}
#installment-detail-modal .modal-body .installment-option.active .panel-footer {
    color: #fff;
    background-color: #ff5011;
}
#installment-detail-modal .modal-body .installment-option.active .btn-pk-radio {
    border: 0
}
#installment-detail-modal .modal-body .installment-option.active .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    display: inline
}
#installment-detail-modal .modal-body .installment-option .text-head {
    font-size: 16px
}
#installment-detail-modal .modal-body .installment-option .text-desc {
    font-size: 16px
}
#installment-detail-modal .modal-body .installment-option .btn-pk-radio {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    background-color: #fff;
    border: 1px solid rgba(255, 53, 19, .3);
    padding: 0;
    margin: 0;
    z-index: 50;
    outline-color: #fff;
    cursor: pointer
}
#installment-detail-modal .modal-body .installment-option .btn-pk-radio:hover {
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7);
    border: 0
}
#installment-detail-modal .modal-body .installment-option .btn-pk-radio:hover .glyphicons.glyphicons-ok-sign {
    display: inline;
    color: #fa7f52
}
#installment-detail-modal .modal-body .installment-option .btn-pk-radio .glyphicons {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 30px
}
#installment-detail-modal .modal-body .installment-option .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    color: #ff5011;
    display: none
}
#installment-detail-modal .modal-body .installment-option .panel-footer {
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid #ece9e8;
    font-size: 17px;
    padding: 1rem 1rem;
}
#installment-detail-modal .modal-body .installment-option .panel-footer p {
    margin: 0;
    padding: 0
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}
#addressRoojai {
  color: #196bb6!important;
}
.language {
    text-align: right;
    /* padding: 16px 0px; */
    display: flex;
}
.switch {
  position: relative;
  display: inline-block;
  /* margin: 0 5px; */
}

.switch > span {
  position: absolute;
  top: 10px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #EF4A24;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #6F6F6F;
}

.backwidth{
    width: 70px !important;
}

.switch > span.on {
  left: 0;
  padding-left: 3px;
  color: #EF4A24;
}

.switch > span.off {
  right: 0;
  /* padding-right: 2px; */
  color: #6F6F6F;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 4px;
  width: 60px;
  height: 34px;
  margin-bottom: 0px;
  background-color: #F0F0F0;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  border-radius: 40px;
  border: 1px solid #e8e8e8;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #F0F0F0;
  
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  color: #EF4A24;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label {
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 28px;
}
.list-lang {
    position: absolute;
    border: 1px solid #ddd;
    z-index: 9999;
    padding: 8px;
    background: #fff;
    border-radius: 4px;
    top: 27px;
    right: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.headerContainer{
  display: flex;
  height: 74px;
  padding: 0px 48px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.backButton {
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */
    /* width: 105px !important;
    position: relative;
    top: -4px;
    float: left; */
    
}
.backButton .back {
  display: flex;
  align-items: flex-start;
  border: 1px solid #FFEFEB;
  margin: 6px auto 0px;
  margin-right: 0;
  border-radius: 8px;
  background: #FFEFEB;
  padding: 8px;
  color:#EF4A24;
  text-align: left;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px; 
  letter-spacing: 0.28px;
}
.backButton .back:hover {
  border: 1px solid #FFE7E1;
  background: #FFE7E1;
}

@media (max-width: 450px) {
  .headerContainer{
    padding: 0px 8px;
  }
}
.new-header{
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 0;
}
.new-modal-dialog{

}
@media (max-width: 450px) {
  .new-modal-dialog{
    display: flex;
    height: 100%;
  }
}
.new-modal-body{
  padding: 8px 16px 16px 16px;
}
.new-modal-content {
  width: 296px;
  margin: auto;
  border-radius: 16px;
}
.new-modal-title {
  color: #003B70;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.new-modal-text {
  color: #393939;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 12px 0;
}
.new-close-bottom-button {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  flex: 1 0 0;
  flex-wrap: wrap;
  border-radius: 8px;
  background: linear-gradient(180deg, #FF6D39 0%, #FF5011 100%);
  color: #FFF;
  text-align: center;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: capitalize;
  cursor: pointer;
}
.new-close-bottom-button:hover {
  background: linear-gradient(180deg, #FF5011 0%, #D4430E 100%);
}
.new-close-bottom-button:active {
  background: linear-gradient(180deg, #D4430E 0%, #AA350B 100%);
}
.new-close-button {
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 48px;
  border: 1px solid #EBEBEB;
  background: #EBF1F5;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.new-close-button:hover {
  background: #C5D9EB;
}
.new-close-button:hover svg path {
  stroke: #262626;
}
.new-close-button:active {
  background: #9FB9D1;
}
.new-close-button:active svg path {
  stroke: #000000;
}
</style>
<template>
  <div id="loading" class="custom-loading" data-selenium-name="loading">
    <div class="loading-body">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="75"
        height="75"
        viewBox="0 0 105 105"
        fill="#ff5011"
      >
        <circle cx="12.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
          <animate
            attributeName="fill-opacity"
            begin="100ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="52.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="300ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="52.5" cy="52.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="600ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="92.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="800ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="92.5" cy="52.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="400ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="12.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="700ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="52.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="500ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="92.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="200ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
      <h4 class="mt-4 h5">{{ $t("loading") }}</h4>
    </div>
  </div>
  <div
      id="agent"
      v-if="Global.brokerInfo.intermediaryReptEmail &&
          ![
            // '/packages'
            ,'/product'
            , Global.productConfig?.route.policydetails1
            , Global.productConfig?.route.payment
            , Global.productConfig?.route.policydetails2
          ].includes(currentPage)"
      style="padding: 5px 5px;color: #FFF;margin: 0px auto 0px auto;width: 100%;font-size: 12px;background-color: #003b71;"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 text-right">
            {{ hideDomain(Global.brokerInfo.intermediaryReptEmail) }}
          </div>
        </div>
      </div>
    </div>

    <!-- OLD header comment out and using new header -->
  <!-- <div id="top-bar" :data-style-version="styleVersion" v-if="[
  '/pa-input', '/hcp-input', '/debitcard', '/feefo','/debit/thankyou','/debit/failure'
  , Global.productConfig?.route.packages
  , Global.productConfig?.route.policydetails1
  , Global.productConfig?.route.payment
  , Global.productConfig?.route.debitcard
  , Global.productConfig?.route.policydetails2].includes(currentPage)">
    <div class="container">
      <div class="row">
        <div class="col-3">
          <button
            type="button"
            class="details2drop4v3"
            @click="goBack()"
            v-if="isRenderBackButton()"
            data-selenium-name="top-bar-goBack"
          >
            <svg
              fill="#003b71"
              height="12"
              width="12"
              viewBox="0 0 24 24"
              role="img"
              style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
            >
              <path
                d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
              ></path>
            </svg>
          </button>
        </div>
        <div class="col-6 text-center">
          <img
            src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            data-src="images/roojai-logo-id.svg"
            alt="roojai.co.id"
            class="lazy"
            height="37"
            width="52"
          />
          <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sompo-logo.svg" class="insure-logo lazy" height="30" width="90" alt="sompo-logo">
        </div>
        <div class="col-3">
          <div
            id="select-lang"
            class="dropdown"
            style="text-align: right;"
          >
            <button
              id="btn-select-language"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              class="btn"
              data-selenium-name="select-language"
              :data-selenium-value="Global.quote.prefLang"
            >
              <img
                src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                data-src="images/id-v2.png"
                alt="ID"
                class="lazy"
                height="12"
                width="18"
                v-show="Global.quote.prefLang == 'id'"
              />
              <img
                src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                data-src="images/en.png"
                alt="EN"
                class="lazy"
                height="12"
                width="18"
                v-show="Global.quote.prefLang == 'en'"
              />
              <span class="language2drop4v3"
                ><svg
                  fill="#ff5011"
                  height="10"
                  width="10"
                  viewBox="0 0 24 24"
                  role="img"
                  class="mt-1"
                >
                  <path
                    d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                  ></path>
                </svg>
              </span>
            </button>
            <ul aria-labelledby="btn-select-language" class="dropdown-menu">
              <li class="dropdown-item">
                <a
                  href="javascript:"
                  data-selenium-name="language"
                  data-selenium-value="id"
                  class="text-right"
                  v-bind:class="{
                    'type-active': Global.quote.prefLang == 'id'
                  }"
                  @click="Global.quote.prefLang = 'id'"
                >
                  Bahasa
                  <img
                    src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    data-src="images/id-v2.png"
                    alt="ID"
                    class="lazy"
                    height="12"
                    width="18"
                  />
                </a>
              </li>
              <li class="dropdown-item">
                <a
                  href="javascript:"
                  data-selenium-name="language"
                  data-selenium-value="en"
                  class="text-right"
                  v-bind:class="{
                    'type-active': Global.quote.prefLang == 'en'
                  }"
                  @click="Global.quote.prefLang = 'en'"
                >
                  Eng
                  <img
                    src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    data-src="images/en.png"
                    alt="EN"
                    height="12"
                    width="18"
                    class="lazy"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Header CI -->
  <div id="top-bar" :data-style-version="styleVersion" v-if="[
  '/', '/ci-input', '/pa-input', '/hcp-input', '/debitcard', '/feefo','/debit/thankyou','/debit/failure', '/payment-info', '/payment-info-success' 
  , Global.productConfig?.route.packages
  , Global.productConfig?.route.policydetails1
  , Global.productConfig?.route.payment
  , Global.productConfig?.route.debitcard
  , Global.productConfig?.route.policydetails2].includes(currentPage)" style="padding: 0px !important;">
    <div class="headerContainer">
      <div class="backButton">
          <button class="back" @click="goBack()" v-if="isRenderBackButton()">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M10.0088 12.6666L5.33779 7.99992L10.0088 3.33325" stroke="#EF4A24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
              {{ $t('motorVehicle.back') }}
          </button>
      </div>
      <div class="d-flex">
        <img
          src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          data-src="images/roojai-logo-id.svg"
          alt="roojai.co.id"
          class="lazy"
          height="32"
          width="44"
        />
        <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sompo-logo.svg" class="insure-logo lazy" height="38" width="71" alt="sompo-logo">
      </div>
      <div class="d-flex">
        <div class="language">
            <div class="switch">
                <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox" v-model="isEnglish" @input="toogleChangeLanguage" >
                <label for="language-toggle"></label>
                <span class="on">ID</span>
                <span class="off">EN</span>
            </div>
        </div>
      </div>
    </div>
  </div>

  <header class="default sticky-top" v-if="[
      '/packages'
      , Global.productConfig?.route.policydetails1
      , Global.productConfig?.route.payment
      , Global.productConfig?.route.policydetails2].includes(currentPage) && packageABtestingHeader">
    <nav>
      <div
        id="agent"
        v-if="Global.brokerInfo.intermediaryReptEmail"
        style="padding: 5px 5px;color: #FFF;margin: 0px auto 5px auto;width: 100%;font-size: 12px;background-color: #003b71;"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 text-right">
              {{ hideDomain(Global.brokerInfo.intermediaryReptEmail) }}
            </div>
          </div>
        </div>
      </div>
      <div class="container text-center" style="padding-top:2px">
        <button
          type="button"
          id="back-btn"
          data-selenium-name="goback-fix"
          class="details2drop4v3 pull-left"
          style="border:1px solid #6993b9;border-radius:5px;"
          @click="goBack()"
          v-if="isRenderBackButton()"
        >
          <svg
            fill="#A8CEF1"
            height="12"
            width="12"
            viewBox="0 0 24 24"
            role="img"
            style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
          >
            <path
              d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
            ></path>
          </svg>
        </button>

        <!-- <div class="allondisplay">
          <small
            id="qotation-no"
            data-selenium-name="qotation-no"
            v-bind:data-selenium-value="Global.quote.opportunityNumber"
            style="margin:auto;position:relative;"
          >
            <span class="pnlabel">{{ $t("quotationNo") }}</span>
            <span class="pnshow" style="">{{
              Global.quote.opportunityNumber
            }}</span>
          </small>
        </div> -->
        <div class="tab4year2price">
          <div class="pay2stage">
            <small
            id="qotation-no"
            data-selenium-name="qotation-no"
            v-bind:data-selenium-value="Global.quote.opportunityNumber"
            style="margin:auto;position:relative;"
            v-bind:style="{
              visibility: Global.quote.opportunityNumber.startsWith('TBA')
                ? 'hidden'
                : ''
            }"
          >
            <span class="pnlabel">{{ $t("quotationNo") }}</span>
            <span class="pnshow" style="">{{
              Global.quote.opportunityNumber
            }}</span>
          </small>
          <div v-if="Global.tp == 'nb'">
            <h2
              id="summary-price"
              data-selenium-name="summaryPrice"
              v-bind:data-selenium-value="formatNumber(totalPremium)"
              v-bind:key="'summary-price-' + totalPremium"
              v-if="!isDisplayInstallmentPrice"
            >
              <small>IDR </small>{{ formatNumber(totalPremium) }}<span class="small">/{{$t("year")}}</span>
            </h2>
            <div class="price-div" v-if="isDisplayInstallmentPrice">
              <div class="price" id="nav-totalPremium" style="line-height: 1;">
                <span class="small">IDR </span>
                <strong class="total-price">{{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}</strong>
                <span class="small">/{{$t("month")}}</span>
              </div>
              <!-- <div class="installment text-center">
                <span class="small">{{$t("instalment12months")}}</span>
              </div> -->
            </div>
          </div>
          <div v-if="Global.tp == 'ren'">
            <h2
              id="summary-price"
              data-selenium-name="summaryPrice"
              v-bind:data-selenium-value="formatNumber(Global.renewalPrices.totalTransactionPremium)"
              v-bind:key="'summary-price-' + Global.renewalPrices.totalTransactionPremium"
              v-if="Global.paymentFrequency !==  '12 months, no deposit'"
            >
              <small>IDR </small>{{ formatNumber(Global.renewalPrices.totalTransactionPremium) }}<span class="small">/{{$t("year")}}</span>
            </h2>
            <div class="price-div" v-if="Global.paymentFrequency ===  '12 months, no deposit'">
              <div class="price" id="nav-totalPremium" style="line-height: 1;">
                <span class="small">IDR </span>
                <strong class="total-price">{{formatNumber(Global.renewalPrices.twelveMonthlyInstallmentAmnt)}}</strong>
                <span class="small">/{{$t("month")}}</span>
              </div>
            </div>
          </div>
            <button
              type="button"
              data-toggle="modal"
              data-selenium-name="btn-head-installment-fix"
              :data-target="[Global.quote.productType == Global.enumProductType.Health ? '#installment-detail-modal' : '#installment-detail-modal']"
              class="badge instalment-badge my-2"
              style="position:relative;margin:auto;"
              v-show="
                Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value >
                  0 &&
                  isAllowInstallment &&
                  totalPremium > 0
              "
              v-on:click="eventLog('Instalment', 'Dropdown');"
            >
              {{ $t("instalment") }}
              <svg
                fill="#A8CEF1"
                height="10"
                width="10"
                viewBox="1 0 24 24"
                role="img"
              >
                <path
                  d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                ></path>
              </svg>
            </button>
            
          </div>
        </div>
        <div id="roojai-rewards" class="install2line" v-if="!Global.payment.isPmntInitiatedBySF">
          <button
            type="button"
            data-toggle="modal"
            data-selenium-name="btn-head-installment"
            :data-target="[Global.quote.productType == Global.enumProductType.Health ? '#installment-detail-modal' : '#installment-detail-modal']"
            class="badge"
            v-show="
              Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor
                ?.value > 0 &&
                isAllowInstallment &&
                totalPremium > 0
            "
            v-on:click="eventLog('Instalment', 'Dropdown');"
          >
            {{ $t("instalmentOptions") }}
          </button>
        </div>
        <!-- <div id="roojai-rewards" class="install2line" v-if="!Global.payment.isPmntInitiatedBySF && [Global.productConfig?.route.payment].includes(currentPage)">
          <button
            type="button"
            data-toggle="modal"
            data-selenium-name="btn-head-installment"
            :data-target="[Global.quote.productType == Global.enumProductType.Health ? '#installment-detail-modal' : '#installment-detail-modal']"
            class="badge"
            v-show="
              Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor
                ?.value > 0 &&
                isAllowInstallment &&
                ( (AvailableInstallment.VA && AvailableInstallment.CC) ) &&
                totalPremium > 0
            "
            v-on:click="eventLog('Instalment', 'Dropdown');"
          >
            {{ $t("instalmentOptions") }}
          </button>
        </div> -->
        <div id="rewards-details" class="rewards4display d-none">
          <div
            id="rewards-shake"
            class="rewards2words"
            v-if="currentPage == Global.productConfig.route.packages"
            v-bind:key="'moreinfo'"
          >
            <span class="mr-1">{{ $t("fixed5year") }}</span>
            <a
              href="javascript:void(0)"
              role="button"
              data-selenium-name="btn-package-detail-modal"
              data-toggle="modal"
              data-target="#fixpremium5-modal"
              class="btn btn-link moreinfo"
              >?</a
            >
          </div>
          <div
            id="rewards-shake"
            class="rewards2words"
            v-if="
              [Global.productConfig.route.policydetails1, Global.productConfig.route.payment, Global.productConfig.route.policydetails2].includes(
                currentPage
              )
            "
            v-bind:key="'detail'"
          >
            <span class="mr-1">{{ $t("packages.yourPolicy") }}</span>
            <a
              href="javascript:void(0)"
              role="button"
              data-selenium-name="btn-package-detail-modal"
              data-toggle="modal"
              data-target="#package-detail-modal"
              class="btn btn-link detail"
              >{{ $t("detail") }}</a
            >
          </div>
          <div class="rewards2pop"></div>
        </div>
      </div>
    </nav>
  </header>

  <div id="app-content" data-selenium-name="ABTesting" :data-selenium-value="Global.quote.abTestingVersion">
    <router-view />
  </div>

  <div class="modal modal-v1 fade" id="package-detail-modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            X
          </button>
          <h5 class="modal-title mt-4 text-center h6">
            {{ $t("packages.yourIllnessPackage") }}
          </h5>
          <div class="text-center">
            <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sum-illness.png" class="lazy" />
          </div>
        </div>
        <div class="modal-body px-0">
          <div class="collapse-list" v-if="Global.selectedPackages?.Cancer">
            <a
              href="javascript:void(0)"
              id="collapse-item-cancer"
              class="collapse-item collapsed"
              data-toggle="collapse"
              data-target="#detail-cancer"
              aria-expanded="false"
              aria-controls="detail-cancer"
            >
              <span class="glyphicons glyphicons-ok text-default"></span>
              {{ $t("packages.cancer.title") }}
              <a href="javascript:void(0)" class="drown-arrow">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  class="bi bi-chevron-down"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </a>
            <div
              id="detail-cancer"
              class="collapse-body collapse"
              aria-labelledby="collapse-item-cancer"
            >
              <div class="collapse-detail">
                <p>
                  {{ $t("packages.cancer.coverage") }}
                  <strong class="mr-1">
                    <small>IDR </small
                    >{{
                      formatNumber(
                        Global.selectedPackages.Cancer.sumAssured.value
                      )
                    }}
                  </strong>
                  <br />
                  <span v-if="cancerCoverages.length > 0">
                    <span
                      class="small comma mr-1"
                      v-for="(coverage, index) in cancerCoverages"
                      v-bind:key="'package-summary-cancer' + index"
                    >
                      {{
                        coverageTranslate(coverage.additionalCoverageName.value)
                      }}
                      <small>IDR </small
                      >{{
                        formatNumber(
                          coverage.additionalCoverageSumAssured.value
                        )
                      }}
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="collapse-list" v-if="Global.selectedPackages?.Cardio">
            <a
              href="javascript:void(0)"
              class="collapse-item collapsed"
              data-toggle="collapse"
              data-target="#detail-heart"
              aria-expanded="false"
              aria-controls="detail-heart"
            >
              <span class="glyphicons glyphicons-ok text-default"></span>
              โรคกล้ามเนื้อหัวใจเฉียบพลัน

              <a href="javascript:void(0)" class="drown-arrow">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  class="bi bi-chevron-down"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </a>
            <div id="detail-heart" class="collapse-body collapse">
              <div class="collapse-detail">
                Using the card component, you can extend the default collapse
                behavior to create an accordion. To properly achieve the
                accordion style, be sure to use .accordion as a wrapper.
              </div>
            </div>
          </div>

          <div class="collapse-list" v-if="Global.selectedPackages?.Organ">
            <a
              href="javascript:void(0)"
              class="collapse-item collapsed notcover"
              data-toggle="collapse"
              data-target="#detail-organ-failure"
              aria-expanded="false"
              aria-controls="detail-organ-failure"
            >
              <span class="glyphicons glyphicons-remove"></span>
              อวัยวะภายในล้มเหลว

              <a href="javascript:void(0)" class="drown-arrow">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  class="bi bi-chevron-down"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </a>
            <div id="detail-organ-failure" class="collapse-body collapse">
              <div class="collapse-detail">
                Using the card component, you can extend the default collapse
                behavior to create an accordion. To properly achieve the
                accordion style, be sure to use .accordion as a wrapper.
              </div>
            </div>
          </div>

          <div class="collapse-list" v-if="Global.selectedPackages?.Neuro">
            <a
              href="javascript:void(0)"
              class="collapse-item collapsed"
              data-toggle="collapse"
              data-target="#detail-neuro"
              aria-expanded="false"
              aria-controls="detail-neuro"
            >
              <span class="glyphicons glyphicons-ok text-default"></span>
              ระบบประสาทและสมองเสื่อม
              <a href="javascript:void(0)" class="drown-arrow">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  class="bi bi-chevron-down"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </a>
            <div id="detail-neuro" class="collapse-body collapse">
              <div class="collapse-detail">
                Using the card component, you can extend the default collapse
                behavior to create an accordion. To properly achieve the
                accordion style, be sure to use .accordion as a wrapper.
              </div>
            </div>
          </div>

          <div class="collapse-list" v-if="Global.selectedPackages?.Mosquito">
            <a
              href="javascript:void(0)"
              class="collapse-item collapsed"
              data-toggle="collapse"
              data-target="#detail-chikungunya"
              aria-expanded="false"
              aria-controls="detail-cancer"
            >
              <span class="glyphicons glyphicons-ok text-default"></span>
              โรคที่เกิดจากยุงเป็นพาหะ
              <a href="javascript:void(0)" class="drown-arrow">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  class="bi bi-chevron-down"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </a>
            <div id="detail-chikungunya" class="collapse-body collapse">
              <div class="collapse-detail">
                Using the card component, you can extend the default collapse
                behavior to create an accordion. To properly achieve the
                accordion style, be sure to use .accordion as a wrapper.
              </div>
            </div>
          </div>

          <div class="collapse-list" v-if="Global.selectedPackages?.COVID">
            <a
              href="javascript:void(0)"
              class="collapse-item collapsed"
              data-toggle="collapse"
              data-target="#detail-covid"
              aria-expanded="false"
              aria-controls="detail-covid"
            >
              <span class="glyphicons glyphicons-ok text-default"></span>
              โรคทางเดินหายใจเนื่องจาก โคโรนาไวรัส
              <a href="javascript:void(0)" class="drown-arrow">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  class="bi bi-chevron-down"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </a>
            <div id="detail-covid" class="collapse-body collapse">
              <div class="collapse-detail">
                Using the card component, you can extend the default collapse
                behavior to create an accordion. To properly achieve the
                accordion style, be sure to use .accordion as a wrapper.
              </div>
            </div>
          </div>

          <div
            class="collapse-list"
            v-if="Global.selectedPackages?.Unrelated_Death"
          >
            <a
              href="javascript:void(0)"
              class="collapse-item collapsed"
              data-toggle="collapse"
              data-target="#detail-unrelated_Death"
              aria-expanded="false"
              aria-controls="detail-unrelated_Death"
            >
              <span class="glyphicons glyphicons-ok text-default"></span>
              ความคุ้มครองชีวิต
              <a href="javascript:void(0)" class="drown-arrow">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  class="bi bi-chevron-down"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </a>
            <div id="detail-unrelated_Death" class="collapse-body collapse">
              <div class="collapse-detail">
                Using the card component, you can extend the default collapse
                behavior to create an accordion. To properly achieve the
                accordion style, be sure to use .accordion as a wrapper.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary w-100"
            data-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-v1 fade" id="fixpremium5-modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" v-if="false">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            X
          </button>
          <h5 class="modal-title mt-4 text-center h6">
            เบี้ยประกันคงที่ตลอด 5 ปี
          </h5>
          <div class="text-center font13">
            <small>IDR </small>1,652/ปี <br />
            มูลค่าการลดหย่อนภาษี <small>IDR </small>1,254/ปี
          </div>
        </div>
        <div class="modal-body">
          <h5 class="text-center h6">{{ $t("5YearFixedPremium") }}</h5>
          <div class="pb-5 font13" v-if="Global.selectedPackages?.Cancer">
            <div class="font13">
              <strong>- {{ $t("packages.cancer.title") }}</strong>
            </div>
            <p class="pl-2">
              {{ $t("packages.cancer.coverage") }}
              <strong class="mr-1">
                <small>IDR </small
                >{{
                  formatNumber(Global.selectedPackages.Cancer.sumAssured.value)
                }}
              </strong>
              <br />
              <span v-if="cancerCoverages.length > 0">
                <span
                  class="small comma mr-1"
                  v-for="(coverage, index) in cancerCoverages"
                  v-bind:key="'package-summary-cancer' + index"
                >
                  {{ coverageTranslate(coverage.additionalCoverageName.value) }}
                  <small>IDR </small
                  >{{
                    formatNumber(coverage.additionalCoverageSumAssured.value)
                  }}
                </span>
              </span>
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary w-100"
            data-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Start Modal Error Umum -->
  <div class="modal modal-v1 fade" id="refuse-modal" tabindex="-1" data-keyboard="false" data-backdrop="true">
    <div class="modal-dialog new-modal-dialog">
      <div class="modal-content new-modal-content">
        <div class="new-header">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M20.0005 15V18.3333M20.0005 25H20.0172M8.45354 31.6667H31.5475C34.1135 31.6667 35.7173 28.8889 34.4343 26.6667L22.8873 6.66667C21.6043 4.44444 18.3968 4.44444 17.1138 6.66667L5.56679 26.6667C4.28379 28.8889 5.88754 31.6667 8.45354 31.6667Z" stroke="#EAB308" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="new-close-button" data-dismiss="modal" @click="Global.errorModal_needreload ? refresh() : null">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M3 9L9 3M3 3L9 9" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="new-modal-body">
          <div class="new-modal-title" v-if="Global.errorModal_title">{{ Global.errorModal_title }}</div>
          <div class="new-modal-text" :style="Global.errorModal_title ? 'padding-top : 12px;' : 'padding-top : 0px;'" v-if="!Global.errorModal_additional && Global.errorModal_contact">{{ Global.errorModal_message }} <a :href="isMvInsure ? 'tel:02150890821' : 'tel:02150890822'">{{ Global.errorModal_contact }}</a></div>
          <div class="new-modal-text" :style="Global.errorModal_title ? 'padding-top : 12px;' : 'padding-top : 0px;'" v-else>{{ Global.errorModal_message }}</div>
          <div class="new-modal-text" style="padding-top: 0px;" v-if="Global.errorModal_additional">{{ Global.errorModal_additional }}<a :href="isMvInsure ? 'tel:02150890821' : 'tel:02150890822'">{{ Global.errorModal_contact }}</a></div>
          <div class="new-close-bottom-button" data-dismiss="modal" @click="Global.errorModal_needreload ? refresh() : null">{{ $t("close") }}</div>
        </div>
      </div>
    </div>
  </div>
   <!-- End Modal Error Umum -->
   <!-- Start Modal Error MV -->
  <div class="modal modal-v1 fade" id="refuse-modal-mv" tabindex="-1" data-keyboard="true" data-backdrop="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="refresh()"
          >
            X
          </button>
          <div class="text-center">
            <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sorry4commercial.png" class="lazy" />
          </div>
        </div>
        <div class="modal-body">
          <div
            class="text-center pb-3 text-black"
            v-html="Global.errorModal_message"
          ></div>
        </div>
        <div class="modal-footer pb-2 px-2">
          <button
            type="button"
            class="btn btn-primary w-100"
            data-dismiss="modal"
            @click="refresh()"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
   <!-- End Modal Error MV -->

  <div class="modal modal-v1 fade" id="blockPolicy-modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            X
          </button>
          <div class="text-center">
            <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sorry4commercial.png" class="lazy" />
          </div>
          <h5 class="modal-title mt-4 text-center">
            <i18n-t tag="span" keypath="validateQuoteUnavailableTitle">{{Global.productModule}}</i18n-t>
          </h5>
        </div>
        <div class="modal-body">
          <div class="text-center pb-3 text-black">
            <p>{{ $t("validateQuoteUnavailableParagraph1") }}</p><br>
            <p>{{ $t("validateQuoteUnavailableParagraph2") }}</p>
          </div>
        </div>
        <div class="modal-footer pb-2 px-2">
          <button
            type="button"
            class="btn btn-primary w-100"
            data-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-v1 fade" id="thankyou-modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            X
          </button>
          <div class="text-center">
            <img
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/rj-thank-you.png"
              style="border-radius: 50%;border: 1px solid #eee;"
              class="lazy"
            />
          </div>
        </div>
        <div class="modal-body">
          <div
            class="text-default text-center pb-3"
            v-html="Global.thankyou_message"
          ></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary w-100"
            data-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal insure-modal fade"
    id="installment-modal"
    tabindex="-1"
    v-bind:key="'installment-modal-' + totalPremium"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body pb-5">
          <h5 class="text-center h4">{{ $t("instalment") }}</h5>
          <div class="text-center mb-4">
            {{ $t("installment_debit_credit") }}
          </div>

          <div
            class="panel"
            v-show="
              Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor
                ?.value > 0
            "
          >
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t("installment_12_months") }}
              </h3>
            </div>
            <div class="panel-body">
              <div>
                <p
                  class="m-0 mb-1"
                  data-selenium-name="monthly-12-payment"
                  :data-selenium-value="
                    formatNumber(twelveMonthsNoDepositPremium / 12, 0)
                  ">
                  <i18n-t tag="div" keypath="installment_per_month">
                    <span>
                      <small>IDR </small><strong>{{ formatNumber(twelveMonthsNoDepositPremium / 12, 0) }}</strong>
                    </span>
                  </i18n-t>
                </p>
                <p
                  data-selenium-name="monthly-12-total"
                  :data-selenium-value="
                    formatNumber(twelveMonthsNoDepositPremium, 0)
                  "
                >
                  {{ $t("installmentTotal") }}
                  <small>IDR </small><strong>{{ formatNumber(twelveMonthsNoDepositPremium, 0) }}</strong>
                </p>
                <p
                  v-if="!Global.isSuperCI && Global.selectedPackages.Covid19"
                  data-selenium-name="covid-premium"
                  :data-selenium-value="
                    formatNumber(
                      calculatePrice(
                        Global.selectedPackages?.Covid19?.premiumBeforeTaxes
                          ?.value
                      ),
                      0
                    )
                  "
                >
                  <i18n-t tag="div" keypath="covidIncluded_in_first_instalment">
                    <span>
                      <small>IDR </small><strong>{{ formatNumber( calculatePrice( Global.selectedPackages?.Covid19?.premiumBeforeTaxes?.value), 0) }}</strong>
                    </span>
                  </i18n-t>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-red w-100"
            data-dismiss="modal"
            data-selenium-name="installment-modal-close"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="installment-detail-modal" class="modal fade modal-v4" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="installment-modal-v4">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div  style="width: 100%;">
                <div  class="modal-header" style="border:0;">
                    <button type="button" data-selenium-name="installment-modal-close-btn" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">x</span></button>
                    <h2 class="text-center h4 mt-5">{{$t("instalmentOptions")}}</h2>
                    <p class="text-center" style="font-size: 16px;">{{$t("instalmentCreditDebit")}}</p>
                </div>
                <div class="modal-body" style="padding:20px;">
                    <div class="panel installment-option mb-5" v-bind:class="{'active' : Global.paymentFrequency == 'Yearly'}">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="Yearly" v-on:click="Global.paymentFrequency = 'Yearly'; eventLog('Instalment', 'Yearly');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option1")}}</p>
                        </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("fullPayment")}}</h3>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="fullPayment-totalPrice" :data-selenium-value="formatNumber(totalPremium)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small>{{formatNumber(totalPremium)}}</strong>
                            </i18n-t>
                        </div>
                    </div>

                    <div class="panel installment-option mb-5" v-bind:class="{'active' : false}" v-if="false">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="Quarterly" v-on:click="Global.paymentFrequency = 'Quarterly'; eventLog('Instalment', 'Quarterly');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>

                                <p class="float-right pt-2">{{$t("option2")}}</p>
                            </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("4MonthlyInstalments")}}</h3>
                                <p class="text-desc" data-selenium-name="quarterly-instalment" :data-selenium-value="205">
                                  <i18n-t tag="p" keypath="payForMonths">
                                    <strong><small>IDR </small>205</strong>
                                    <strong>4</strong>
                                  </i18n-t>
                                </p>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="quarterly-totalPrice" :data-selenium-value="'8000'">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small>8,000</strong>
                            </i18n-t>
                        </div>
                    </div>

                    <div class="panel installment-option mb-5" v-bind:class="{'active' : false}" v-if="false">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="Monthly" v-on:click="Global.paymentFrequency = 'Monthly'; eventLog('Instalment', 'Monthly');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option3")}}</p>
                        </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("instalmentWithDeposit")}}</h3>

                                <i18n-t tag="p" keypath="deposit">
                                  <strong><small>IDR </small>1,000</strong>
                                </i18n-t>

                                <i18n-t tag="p" keypath="instalmentThen">
                                  <strong><small>IDR </small>205</strong>
                                  <strong>9</strong>
                                </i18n-t>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="monthly-deposit-totalPrice" :data-selenium-value="'8500'">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small>8,500</strong>
                            </i18n-t>
                        </div>
                    </div>

                    <div class="panel installment-option mb-5"  v-bind:class="{'active' : Global.paymentFrequency == '12 months, no deposit'}" v-if="isAllowInstallment">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="12 months, no deposit" v-on:click="Global.paymentFrequency = '12 months, no deposit'; eventLog('Instalment', '12 months, no deposit');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option2")}}</p>
                            </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("instalmentWithNoDeposit")}}</h3>
                                <i18n-t tag="p" keypath="payForMonths">
                                  <strong><small>IDR </small>{{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}</strong>
                                  <strong>12</strong>
                                </i18n-t>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="monthly-nodeposit-totalPrice" :data-selenium-value="formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small>{{formatNumber(twelveMonthsNoDepositPremium.totalInstallment)}}</strong>
                            </i18n-t>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <footer v-if="isPAInsure">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4" v-if="!Global.isAgentCustomerView">
          <div class="footer-company">
            <p>
              <b>{{ $t("roojaiName") }}</b>
              <br />{{ $t("roojaiAddressLine1") }}
              <br />{{ $t("roojaiAddressLine2") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-4 text-center">
          <div class="footer-phone" v-if="!Global.isAgentCustomerView && !isDebitThankyou && !['/product'].includes(currentPage)">
            <a
              href="tel:02150890822"
              role="button"
              class="btn btn-link "
              data-selenium-name="footer-roojai-number"
              data-selenium-value="021 5089 0822"
            >
              <span class="glyphicons glyphicons-earphone"></span>
              021 5089 0822
            </a>
          </div>
          <div v-if="!Global.isAgentCustomerView && isDebitThankyou && !['/product'].includes(currentPage)">
            <p class="h4 text-secondary text-center text-md-left mb-625">
              {{ $t("footerDebitTitle") }}
            </p>

            <p class="text-center text-md-left mb-625">
              <span class="h6"> {{ $t("footerDebitHealth") }} </span>

              <br />

              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="none"
                stroke-width="1"
                fill="#ff5011"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="css-i6dzq1"
              >
                <path
                  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                ></path>
              </svg>

              &nbsp;<a href="tel:02150890822"
                ><span class="text-secondary">021 5089 0822</span></a
              >
            </p>

            <p class="text-center text-md-left mb-2250">
              <span class="h6">{{ $t("footerDebitCar") }} </span>

              <br />

              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="none"
                stroke-width="1"
                fill="#ff5011"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="css-i6dzq1"
              >
                <path
                  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                ></path>
              </svg>

              &nbsp;<a href="tel:02150890821"
                ><span class="text-secondary">021 5089 0821</span></a
              >
            </p>
          </div>
        </div>
        <div class="col-12 col-md-4 text-center">
          <!-- <div class="footer-standard-logo" style="float: right;">
            <b>{{ $t("ojk") }} </b>
            <img
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/ojk-min.png"
              alt="ojk"
              class="mx-auto lazy"
            />
          </div> -->
          <div class="footer-standard-logo">
            <img style="padding-left: 5px"
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/logo-virtual-account.png"
              width="70"
              height="30"
              alt="virtual-account"
              class="mx-auto b-loaded lazy"
            />
            <img style="padding-left: 5px"
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/logo-visa.png"
              width="74"
              height="40"
              alt="visa"
              class="mx-auto lazy"
            />
            <img style="padding-left: 5px"
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/logo-mastercard.png"
              alt="mastercard"
              width="70"
              height="40"
              class="mx-auto lazy"
            />
            <img style="padding-left: 5px"
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/JCB-settings.svg"
              alt="mastercard"
              width="59"
              height="46"
              class="mx-auto lazy"
            />
            <!-- <img style="padding-left: 5px"
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/hacker-proof.png"
              alt="hacker proof comodo"
              class="mx-auto lazy"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </footer>
  <FooterHealth v-if="isHealthInsure || ['/payment-info'].includes(currentPage)"/>

  <div id="pdpa" class="pdpa" v-if="false" data-selenium-name="pdpa">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="content">
            {{ $t("pdpaText") }}
            <a :href="pdpaRoojaiURL" target="_blank" class="text-default">{{
              $t("pdpaPrivacypolicy")
            }}</a>
            {{ $t("pdpaOur") }}
            <div class="text-center pt-3">
              <button
                class="btn btn-lg btn-primary"
                data-selenium-name="pdpa-accept"
                v-on:click="setPdpaCookie()"
              >
                {{ $t("pdpaAccept") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import FooterHealth from "./views/critical-illness/components/FooterHealth.vue"

export default {
  name: "App",
  mixins: [Mixin],
  data() {
    return {
      Global,
      isShowPDPA: true,
    };
  },
  components: {
    FooterHealth
  },
  mounted() {
    this.isShowPDPA = !document.cookie.includes("healtpdpa");
  },
  computed: {
    isEnglish: function(){
      return Global.quote.prefLang === 'en';
    },
    pdpaRoojaiURL: function() {
      return (
        Global.roojaiURL +
        (Global.quote.prefLang != "th"
          ? "/en/terms-and-conditions/privacy-policy/"
          : "/terms-and-conditions/privacy-policy/")
      );
    },
    packageABtesting:function(){
      return Global.quote?.abTestingVersion?.toUpperCase();
    },
    packageABtestingHeader:function(){
      return (this.currentPage != '/packages')
    },
    styleVersion:function(){
      return this.packageABtesting == 'B' && this.currentPage == '/' ? "B":"A";
    },
    isHealthInsure() {
      return Global.quote.productType === "Health";
    },
    isPAInsure() {
      return Global.quote.productType === "Personal Accident";
    },
    isMvInsure() {
      return Global.quote.productType === "MotorCar";
    },
    isDebitThankyou: function () {
      return this.currentPage == "/debit/thankyou" || this.currentPage == "/debit/failure" ;
    },
  },
  methods: {
    sendCase(value){
      const mapCase = new Map();
      mapCase.set('fraud', 'ISSUER_SUSPECT_FRAUD');
      mapCase.set('insufficient', 'INSUFFICIENT_BALANCE');
      mapCase.set('stolen', 'STOLEN_CARD');
      mapCase.set('inactive', 'INACTIVE_OR_UNAUTHORIZED_CARD');
      let valueCase = mapCase.get(value);
      if (valueCase === undefined) {
        console.error('value is not found')
        return false;
      }else{
        Global.isTestCaseCreditCard = true;
        Global.testCaseCreditCard = valueCase;
        console.info('Set Case is successfully');
        return true;
      }
    },
    resetCase(){
      Global.isTestCaseCreditCard = false;
      Global.testCaseCreditCard = null;
      console.info('Case is already reset');
      return true;
    },
    toogleChangeLanguage(val) {
      // console.log(val,'==isEnglish',this.isEnglish)
      Global.quote.prefLang == 'en' ? Global.quote.prefLang = 'id' : Global.quote.prefLang = 'en'
        // this.$emit("selectedLang", this.languages.find(({ value }) => value === (this.prefLang.toLowerCase() == 'id' ? 'en' : 'id')));
    },
    isRenderBackButton: function() {
      if (
        this.$route.path.toLowerCase() === Global.productConfig?.route.payment &&
        Global.isRetrieveToPayment === true
      ) {
        return false;
      } else {
        return [
          Global.productConfig?.route.packages
          , Global.productConfig?.route.policydetails1
          , Global.productConfig?.route.payment].includes(
          this.$route.path.toLowerCase()
        );
      }
    },
    refresh(){
      location.reload()
    },
    setPdpaCookie: function() {
      var vm = this;
      var date = Global.serverTime ? new Date(Global.serverTime) : new Date();
      date.setYear(date.getFullYear() + 1);

      document.cookie =
        "healtpdpa=true; expires=" + date.toGMTString() + "; path=/";

      vm.isShowPDPA = !document.cookie.includes("healtpdpa");
    }
  },
  watch: {
    "Global.quote.prefLang": function(value) {
      //this.$i18n.locale = value !== 'zh' ? value : 'en';
      this.$i18n.locale = value;
    },
    $route: function(to) {
      if (window.ga !== undefined) {
        ga(function() {
          ga(
            "set",
            "page",
            to.path + window.location.pathname + Global.quote.prefLang
          );
          ga("send", "pageview");
        });
      }
    }
  },
  created() {
    if (!Global.restServer.token) {
      this.getToken();
    }

    if (!Global.roojaiService.token) {
      this.getServiceToken();
    }

    // if (!Global.odataService.token) {
    //   this.getOdataToken();
    // }

    if (sessionStorage.getItem("firstname") !== null) {
      Global.brokerInfo.firstname = sessionStorage.getItem(
        "firstname"
      );
    }
    if (sessionStorage.getItem("lastname") !== null) {
      Global.brokerInfo.lastname = sessionStorage.getItem(
        "lastname"
      );
    }
    if (sessionStorage.getItem("intermediaryAccountID") !== null) {
      Global.brokerInfo.intermediaryAccountID = sessionStorage.getItem(
        "intermediaryAccountID"
      );
    }
    if (sessionStorage.getItem("intermediaryReptEmail") !== null) {
      Global.brokerInfo.intermediaryReptEmail = sessionStorage.getItem(
        "intermediaryReptEmail"
      );
    }
    if (sessionStorage.getItem("intermediaryReptID") !== null) {
      Global.brokerInfo.intermediaryReptID = sessionStorage.getItem(
        "intermediaryReptID"
      );
    }
    if (sessionStorage.getItem("accountSource") !== null) {
      Global.brokerInfo.accountSource = sessionStorage.getItem("accountSource");
      Global.isAgentTraditionalBroker = this.checkAgentTraditionalBroker();
    }
    if (sessionStorage.getItem("useInstallment") !== null) {
      Global.brokerInfo.useInstallment = sessionStorage.getItem("useInstallment") == "true";
    }
    
    if (sessionStorage.getItem("isAgentCustomerView") !== null) {
      Global.isAgentCustomerView = sessionStorage.getItem("isAgentCustomerView") == "true";
    }
    if (sessionStorage.getItem("channel") !== null) {
      Global.quote.channel = sessionStorage.getItem("channel");
    }
  },
  updated() {
    if (typeof $ == "function") {
      // console.log("Updated!");
      $('[data-toggle="tooltip"]').tooltip("dispose");
      $('[data-toggle="tooltip"]').tooltip();
    }

    //lazy loading images
    var intersectionObserver = new IntersectionObserver(function (entries) {
      for (var i=0; i<entries.length; i++) {
        if (entries[i].intersectionRatio > 0) {
          entries[i].target.setAttribute('src', entries[i].target.getAttribute('data-src'));
          entries[i].target.classList.remove("lazy");
          intersectionObserver.unobserve(entries[i].target);
        }
      }
    });

    var myImgs = document.querySelectorAll("img.lazy");
    for (var i=0; i<myImgs.length; i++) {
        intersectionObserver.observe(myImgs[i]);
    }
  }
};
</script>
<i18n>
{
  "en":{
    "instalment": "Instalment",
    "instalmentOptions": "Instalment options",
    "installment_debit_credit":"Instalment Credit / Debit card",
    "instalmentCreditDebit": "Available for Credit Card, Debit Card and Virtual Account",
    "instalmentDesc": "You can select your preferred option before payment",
    "option1": "Option 1",
    "option2": "Option 2",
    "option3": "Option 3",
    "option4": "Option 4",
    "totalPrice": "Total price {0}",
    "payForMonths": "Pay {0} for {1} months",
    "deposit": "Initial deposit {0}",
    "instalmentThen": "Pay {0} for {1} months",
    "fullPayment": "Full payment",
    "installment_12_months":"Instalment 12 months",
    "installment_per_month":"Pay {0} for 12 months",
    "installmentTotal":"Total",
    "instalmentWithDeposit": "10 monthly instalments with deposit",
    "instalmentWithNoDeposit": "12 monthly instalments without deposit",
    "4MonthlyInstalments": "4 monthly instalments",
    "6MonthlyInstalments": "6 monthly instalments",
  },
  "id":{
    "instalment": "Cicilan",
    "instalmentOptions": "Opsi cicilan",
    "installment_debit_credit":"Cicilan kartu Kredit / kartu Debit",
    "instalmentCreditDebit": "Tersedia untuk Kartu Kredit, Debit dan Virtual Account",
    "instalmentDesc": "Kamu dapat memilih opsi pilihan kamu sebelum melakukan pembayaran",
    "option1": "Opsi 1",
    "option2": "Opsi 2",
    "option3": "Opsi 3",
    "option4": "Opsi 4",
    "totalPrice": "Total harga {0}",
    "payForMonths": "Bayar {0} untuk {1} bulan",
    "deposit": "Awal deposit {0}",
    "instalmentThen": "Bayar {0} untuk {1} bulan",
    "fullPayment": "Pembayaran penuh",
    "installment_12_months":"Cicilan 12 bulan",
    "installment_per_month":"Bayar {0} untuk 12 bulan",
    "installmentTotal":"Total",
    "instalmentWithDeposit": "10 bulan cicilan dengan deposit",
    "instalmentWithNoDeposit": "12 bulan cicilan tanpa deposit",
    "4MonthlyInstalments": "4 bulan cicilan",
    "6MonthlyInstalments": "6 bulan cicilan",
  },
}
</i18n>