<template>
    <div class="footer-mv">
        <div class="container">
            <div class="footer-content">
                <div class="footer-phone" v-if="!Global.isAgentCustomerView">
                    <span class="company">{{ $t("roojaiName") }}</span>
                    <a
                        href="tel:02150890822"
                        role="button"
                        class="btn btn-link"
                    >
                       <img src="images/motor-vehicle/phoneCall.svg" alt="" height="18" width="18">
                       <span class="phoneNumber"> 021 5089 0822 </span>
                    </a>
                </div>
                <div class="footer-compro" v-if="!Global.isAgentCustomerView">
                    <span style="font-weight: 700;margin-bottom: 4px;display: block;">
                        {{ $t("roojaiAddressLine1") }}
                    </span> 
                    <span>{{ $t("roojaiAddressLine2") }}</span>   
                </div>
                <div class="footer-image">
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/ojk.png"
                        alt="ojk"
                        class="lazy"
                    />
                    <img style="padding-left: 5px;margin-top: 8px;"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/sompo-logo.svg"
                        alt="sompo"
                        class="lazy"
                    />
                </div>
                <div class="footer-image">
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/logo-virtual-account.png"
                        alt="virtual-account"
                        class="lazy"
                    />
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/logo-visa.png"
                        alt="visa"
                        class="lazy"
                    />
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/logo-mastercard.png"
                        alt="mastercard"
                        class="lazy"
                    />
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/JCB-settings.svg"
                        alt="mastercard"
                        width="59"
                        height="46"
                        class="mx-auto lazy"
                    />
                </div>
            </div>
        </div>
        <div class="footer-company" v-if="!Global.isAgentCustomerView">
            ©{{ new Date().getFullYear() }} PT Roojai Services Indonesia Copyright
        </div>
    </div>
</template>

<script>
import Global from "@/store/global.js";
export default {
    name: "FooterHealth",
    data() {
        return {
            Global,
        }
    }
}
</script>

<style scoped>
.footer-mv {
    border-top: 1px solid #CCC;
    background: #F7F7F7;
}
.phoneNumber{
    color:#FFF;
    text-align: center;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.28px;
}
.footer-mv .footer-content {
    padding: 32px 0px 32px 0px;
}
.footer-mv .footer-content .footer-phone {
    text-align: center;
}
.footer-mv .footer-content .footer-compro {
    font-family: 'Noto Sans';
    text-align: center;
    padding: 24px;
    color: #000000;
}
.footer-mv .footer-content .footer-image {
    text-align: center;
    margin-bottom: 8px;
}

.footer-mv .footer-content .footer-image:last-child {
    text-align: center;
}
.footer-mv .footer-content .footer-image img {
    height: 32px;
    margin-right: 10px;
}
.footer-mv .footer-company {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background: #003B71;
    color: #fff;
    padding: 16px 24px;
}
.company{
    font-family: 'Noto Sans';
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    color: #003B71;
}

.btn-link {
    padding: 6px 16px;
    width: 170px;
    height: 36px;
    background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #fff!important;
    border-radius: 8px;
    transition: background 0.5s step-start;
}
.btn-link:hover {
    text-decoration: none;
    background: #C73E1E;
}
@media screen and (max-width: 480px) {
    .footer-mv .footer-content .footer-image img {
        height: 24px;
        margin-right: 10px;
    }
}
</style>