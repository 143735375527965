// const phaccount= {
//     carBrand: null,
//     carModel: null,
//     carProduction: null,
//     carType: null,
//     dashCamera: null,
//     mileage: null,
//     purposeOfUse: null,
//     carForWork: null,
//     claim: null,
//     carPlate: null,
//     genderAndStatus: null,
//     dob: null,
//     drivingLicense: null,
//     postalCode: null,
//     mainDriver: null,
//     email: null,
//     phone: null,
//     commercial: null,
//     callMeBack: null,
//     registeredOwner: null,
//     companyName: null,
//     companyRegistrationNumber: null,
//     carNumber: null,
// };

const phaccount= {
  email: "", 
  phoneNumber: null,
  isMainDriver: null,
  carForWork: "",
  mileage:"",
  purposeOfUse: "",
  gender: null,
  maritialStatus: null,
  dob: null,
  driverAge: null,
  drivingExperience: "0",
  driverAccidents: "0",
  residentStatus: null,
  thaiIdNumber: null,
  passPortNumber: null,
  countryOfIssuance: null,
  firstName: "",
  lastName: "",
  policyDate: null,
  occupation: null,
  companyNumber: null,
  businessRegNumber: null,
  postalCode: null,
  houseNumber: "",
  village: null,
  soi: "",
  subDistrict: null,
  subDistrictCode: null,
  district: null,
  districtCode: null,
  province: null,
  provinceCode: null,
  PersonHasOptedOutOfEmail: false,
  flagPDPAConsent: null,
  companyName: null,
  iPAddress: null
};
const mainDrivers= {
  residentStatus: null,
  thaiIdNumber: null,
  phoneNumber: null,
  passPortNumber: null,
  countryOfIssuance: null,
  occupation: null,
  occupationGroup: null,
  maritialStatus: null,
  firstName: "",
  lastName: "",
  isMainDriver: null,
  gender: null,
  email: null,
  drivingExperience: null,
  driverAge: null,
  driverAccidents: null,
  PersonHasOptedOutOfEmail: false,
  dob: null,
};
const quoteLine= {
  Basic_premium__c: null,
  Bail_Bond_SI__c: null,
  Cover_Code__c: null,
  Adjusted_technical_premium__c: null,
  Reason_for_premium_adjustment__c: null,
  Premium_adjustment_pct__c: null,
};
const payment = {
  coveragePrice: {},
  totalPrice: 0,
  grossPrice: 0,
  paymentMethod: null,
  agentId: null,
  paymentFrequency: null,
  paymentType: {},
  paymentDetailsData: [],
  creditCard: null
};
const quote= {
  Year_of_Manufacture: null,
  Make: null,
  Is_Main_Driver_PH: null,
  Declared_NCB: null,
  Excess: null,
  Compulsory_Plan: null,
  Workshop_Type: null,
  Plan_Type: null,
  Masterset_Id: null,
  Created_From: "Web",
  Opportunity_Number: "",
  send_email_flag: null,
  sendEmailFlag: false,
  callMeBackRequest: null,
  callMeBack: false,
  carModel: null,
  Model_Description: null,
  Vehicle_Usag: null,
  taxiVehForHire: null,
  rentalVehicl: null,
  goodsTransportVehicle: null,
  goodsTransportRoute: null,
  whoseGoods: null,
  hdChassisNumber: null,
  hdCarPlateProvince: null,
  hdCarPlateNumber: null,
  hdCarOwnership: null,
  Start_date: null,
  End_date: null,
  previousInsurer: "",
  previousCoverType: null,
  abTestingVersion: null,
  carAccessoryCarCamera: "",
  channel: null,
  PSN_value: null,
  isActivatePolicy: false,
  currentPolicyExpiryDate: null,
  referralNumber: null,
  isValidSourceCode: false,
  tentativeStartDate: "Other",
  Vehicle_Key: null,
  Google_Client_ID: "",
  prefLang: "id",
  Online_Screen: null,
  Online_Stage: null,
  versionId: null,
  Engine_size: null,
  Renewal_Adjustment_Value: null,
  GoogleClickId: null,
  FamilyCode: null,
  KerbWeight: null,
  Power: null,
  NewPrice: null,
  Final_UW_Class: null,
  Final_UW_Group: null,
  Final_Premium_Class: null,
  Final_Premium_Group: null,
  Tariff_Group: null,
  Vehicle_Sum_Insured: null,
  Adjusted_vehicle_Sum_Insured: null,
  odoMeterReading: null,
  postalCode: null,
  Rating_Factor_Defaulted: false,
  Additional_Benefit_PA_ME_SI: null,
  rsaProduct: null,
  carAccessoriesSI: null,
  Compulsory_type: null,
  flagCarFinancing: null,
  financingCompany : null,
  Vehicle_Type_Update_Flag: null,
  Voluntary_Type: null,
  ePolicyDocument: null,
  Voluntary_TPBI: null,
  Beneficiary: null,
  QuoteToOppScored: null,
  ShowMrKumkaInfo: null,
  carReplacementProductName: null,
  carReplacementProductOptionNo: null,
  paymentFrequency: null,
  totalPremium: 0,
  isGAMarketeerlink: null,
  carFinancing: null,
  approvedCode: null,
  transactionNumber: null,
  currentPolicyExpiryDate : null,
  sumCarWarranty : 0
};

export default {
  carDetails: null,
  isDefaultReward: null,
  voucherTrigger: null,
  isCancelVoucher : null,
  isScrollingVoucher : null,
  DefaultReward: 0,
  Reward: 0,
  phaccount,
  mainDrivers,
  quoteLine,
  quote,
  payment,
  hdIsRedCarPlate: null,
  flagRetrievePolicy: false,
  retrievePolicy: null,
  flagcallmeback: false,
  flagemailmequote: false,
  hdResidentialStatusMD: null, 
  isLeadSourceCodeValid: null,
  currentPath: null,
  radarCallType: null,
  brokerInfo: {
      intermediaryAccountID: null,
      intermediaryReptID: null,
  },
  totalPrice: 0,
  sumOdoStep: 0,
  sumOdoMax: 0,
  carAge : 0,
  odoStep : 5000,
  odoMaxRange: 15,
  vehicleAccessories: [],
  firstSumInsured: null,
  tplValue: "0",
  pameValue: "0",
  addOnSelected: [],
  productConfiguration: null,
  isPackagesStage: false,
  todayDate: "",
  Quote_Date_of_First_Quote: "",
  postalCodeDetails: null,
  clientRegInfo: null,
  interceptorObj: null,
  tncClick: false,
  isShowInstallmentModal : false,
  odometerLists: [],
  sumCarAccessories: 0,
  textOdoV2 : "",
  selectedCarStep: {
    carBrand: true,
    carsModel: false,
    carYear: false,
    carSubmodel: false
  },
  garages: null,
  carBrand: [],
  carsModel: {},
  carYear: [],
  carSubmodel: [],
  occupationCarOwner: {},
  occupationMainDriver: {},
  scrollPositionPD2: 0,
}
