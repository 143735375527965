const en = {
    greetings1Agent: "No contact info needed!",
    greetings2Agent: "Take 30 seconds to answer a few questions and get your insurance quote.",
    addButton: "Add",
    cancelButton: "Cancel",
    greetings1: "No contact info needed!",
    greetings2: "Take 30 seconds to answer a few questions and get your insurance quote.",
    greetings3: "Let's talk about the car you want to insure",
    greetings4: "Curious about your premium price? Click the button below!",
    carBrand: "What is the car brand?",
    carModel: "What is the car model?",
    carProductionYear: "When is the car production year?",
    carType: "What is the car submodel?",
    carRecognize: "Now, help us to get to know your {car} better",
    dashCam: "Is your car equipped with a dashboard camera?",
    dashNotes: "Note: Dashboard camera footage will make it easier for you when filing a claim.",
    mileage: "What's the current mileage for this car?",
    newmileage: "Choose Current Mileage",
    mileageinstruction1: "Slide or tap on the bar to select your mileage",
    mileageinstruction2: "Please be precise. We will check your mileage at car inspection.",
    mileageOrange: "Please be precise. We will check your mileage at car inspection.",
    purposeOfUsage: "What do you use your {car} for?",
    carForWork: "Do you use the car to commute to work?",
    claimQuestion: "Was there any claims filed on your car in the past 12 months?",
    carPlate: "License plate area code",
    carNumPlateQuestion: "License Plate Number",
    carNumPlate: "Contoh: B1234FGH",
    choosePlateArea: "Choose area code",
    aboutDriver: "Let's talk about the driver of this car!",
    maritalStatusInfo: "What is the gender and marital status of the driver?",
    maritalStatus_singleman: "Single man",
    maritalStatus_marriedman: "Married man",
    maritalStatus_singlewoman: "Single woman",
    maritalStatus_marriedwoman: "Married woman",
    messageWarningAcc:"Please do not exceed a total coverage value of IDR { value } for car accessories.",
    dob: "What is driver's date of birth?",
    drivingLicense: "How long has the main driver had a valid driving license?",
    driverLiving: "Where does the driver live?",
    askPostalCode: "Please fill in the postal code of driver's current residency",
    calculatePremium: "Calculate Your Premium",
    continueToNextstep: "By clicking the button above, you have confirmed that the information provided is true. Any discrepancy may result in a price change.",
    installmentOption: "instalment Option",
    rewardsInfoHeader: "Roojai Rewards Bonus: ",
    rewardsInfoHeader2: "Worth ",
    quotationNo: "Quotation Number",
    switchYearly:"Annual",
    premi: "Premium",
    disc13: "Disc 6%",
    switchMonthly:"Instalment",
    year:"year",
    month: "months",
    currencySymbol: "IDR ",
    packagesBlueCard1: "Maximum protection for your car from various damages",
    packagesBlueCard2: "Let's level up your coverage!",
    packagesBlueCard3: "The humans need protection, too!",
    packagesTitle1: "Third Party Legal Liability",
    packagesDescription1: "If you get into an accident and become responsible for another person's loss (such as property damage or medical expenses), this will help you cover the expenses.",
    packagesTitle2: "Personal Accident",
    selectPlanPA : "Choose the coverage value you want (per person):",
    chooseWorkshop: "Our garage network consists of both our preferred garages as well as dealer garages.",
    questionWorkshop: "In case of accidents, where would you prefer to repair your car? ",
    questionSumInsured: "Would you like to customize your sum insured?",
    msgSumInsured: "You may choose the sum insured that is suitable for your car. Changing the sum insured will affect the premium price.",
    partnerRepairShop: "Look up the preferred garages closest to you, ",
    dealerRepairShop: "Look up the dealer garages closest to you, ",
    here: "here",
    close: "Close",
    poin: "Point",
    recomendedForYou: "Extra protection for you",
    popUpRewardRecomend1: "Choose repair at our preferred garages to get Roojai Rewards Points worth",
    popUpRewardRecomend2: "",
    rewardMessage1: "Choose ",
    rewardMessage2: "our preferred garages ",
    rewardMessage3: "to get Roojai Rewards Points worth ",
    rewardMessage4: "",
    UpdaterewardMessage1: "Choose ",
    UpdaterewardMessage2: "our preferred garages ",
    UpdaterewardMessage3: "to get Roojai Rewards points worth ",
    UpdaterewardMessage4: "",
    titleModalSumInsured:"Choose Your Sum Insured Again",
    msgModalSumInsured:"Changing the garage type affects your Sum Insured options.",
    msgWarnSumInsured:"Sum Insured options depend on the garage type you choose",
    emailThankyouTitle: "Receive quotation by email",
    emailThankyou: "Your quotation details has been sent to your email. Please check your inbox or spam folder, or click 'Next' to proceed to the next step.",
    emailThankyouAgent: "Your quotation details has been sent to Agent. If you’re ready to buy, click 'Next' to proceed to the next step.",
    askSumInsure: "Want a different sum insured? Let our Contact Center help you!",
    btnRewardMessage : "Change",
    packagesDescription2: "If your car gets into an accident, this coverage helps pay for the medical expenses of the people inside your car, which includes the driver and up to four passengers who may be injured.",
    sumInsuredLabel: "Your Quotation Summary",
    sumInsuredLabel2: "Insured Car",
    sumInsured: "Sum insured IDR {sumInsured}",
    popUpGarages: "We found {garagesFound} garages near you, based on your postal code",
    preferredPanel: "Preferred Garages",
    preferredDealer: "Dealer Garages",
    voucherTitle:"Redeem Your Free Car Wash Voucher Now!",
    voucherMsg:"Voucher ",
    voucherHint:"Applicable for all services",
    voucherCust:"Please make sure to enter the right the car license plate number. The voucher code is valid for the car plate number you provided. ",
    voucherWA:"Enter WhatsApp number ",
    voucherWAerror:"Please fill out your WhatsApp number ",
    voucherEmail:"Enter email address",
    voucherEmailError:"Please fill out your email address ",
    voucherCarPlate:"Enter your car’s license plate number ",
    voucherCarPlateError:"Please fill out your car’s license plate number ",
    voucherEmailExmple:"Example: vira{'@'}gmail.com ",
    voucherPhoneExmple:"Make sure your WhatsApp number is active. Voucher will be sent via WhatsApp. One voucher is valid for one WA number and one car plate number.",
    voucherCarPlateExmple:"Example: B1234DEF",
    redeemVoucher:"Redeem Voucher",
    cancelVoucher: "No, Thank You",
    voucherErrTitle : "Oh snap!",
    voucherErrPhone: "Looks like your WhatsApp number has already been used to collect a voucher. At the moment, we can only give out one voucher per phone number. Thanks for understanding. ",
    voucherErrCarPlate: "Looks like your car plate number has already been used to collect a voucher. At the moment, we can only give out one voucher per car plate number. Thanks for understanding. ",
    voucherErrButton : "OK, Got It",
    voucherSuccTitle : "Thank you!",
    voucherSuccMsg: "You should be receiving the voucher code in a few minutes in your WhatsApp. Our Contact Center team will also be contacting you shortly.",
    voucherSuccButton : "Close",
    noteVoucher: "Please note: Voucher code cannot be sent if a phone number/car plate number has been used to redeem a voucher. ",
    update: "Change",
    resendOTP: "Resend",
    titleVoucherOTP: "OTP Verification",
    voucherOTP: "Please enter the OTP code we’ve sent to the following WhatsApp number",
    voucherMsgOTP: "Resend OTP code in ",
    verifyOTP : "Verify",
    next: "Next",
    chatWhatsapp: "Chat me via Whatsapp",
    callMe: "Call Me",
    callUs: "Call Us",
    firstNamePlaceholder: "First Name",
    lastNamePlaceholder: "Last Name",
    companyNamePlaceholder: "Enter company's name",
    identificationNumberPlaceholder: "Example: 12345678",
    passportNumberPlaceholder: "Example: 12345678",
    questionMainDriver: "Is car owner the main driver?",
    stepperFirst: "Registration",
    stepperSecond: "Payment",
    stepperThird: "Fill Additional Information",
    stepperFourth: "Car Inspection",
    stepperFifth : "Policy Active",
    premiumCallUs: "Need assistance to check quote online?",
    back: "Back",
    gobackhome: "Back to Home",
    thankyou2PaymentTitle :"Completed!",
    thankyou2Paymentmsg : "Your data has been successfully received. Next step you can request for completing car inspection to activate your policy. You can check the details in the email we sent you.",
    callingInspection : "Call Us to Arrange Car Inspection",
    answerShowcallMeYes:"Thank you, our customer service representative will contact you shortly.",
    answerShowcallMeLater : "Thank you, our customer service representative will contact you during lunch or after office hours.",
    openInspection : "Car inspection can be performed on Monday – Saturday at 08:00-17:00, Public Holiday: Off",
    paymentConfirmed: "Payment has been confirmed!",
    paymentConfirmedMsg: "Thank you for your payment. Please complete the information below in order to proceed with your car inspection. If you do not proceed or if you do not pass your car inspection, your policy will not be activated and the premium will be refunded in full.",
    questionUnderFinancing : "Is your { brand } { model }  currently under financing?",
    companyUnderFinancing : "Which financial organization is financing your car?",
    questionchassisNumber : "Please provide the chassis number (see car registration book)",
    questionUnderInsurance : "Are you currently covered by another  insurance policy?",
    questionCompanyInsurance : "What is the name of the insurance company?",
    questionTypeInsurance : "What type of car insurance do you currently own?",
    questionExpireInsurance : "When will the policy expire?",
    completeAddress : "Almost finished! Please complete your current address",
    roadNamePlaceholder : "Enter Road Name",
    houseNumberPlaceholder : "Enter the House/Building Number",
    provincePlaceholder : "Select Province",
    districtPlaceholder : "Select District",
    subdistrictPlaceholder : "Select Subdistrict",
    companyUnderFinancingPlaceholder : "Select Company",
    companyInsurancePlaceholder : "Select Company",
    chassisNumberPlaceholder : "Enter the Chassis Number",
    typeInsurancePlaceholder : "Choose the Type of Car Insurance",
    zipcode : "Zip code",
    province : "Province",
    district : "District",
    subdistrict : "Subdistrict",
    roadName : "Road name",
    houseNumber : "House/building number",
    redeemStep1: "Enter Voucher Code ",
    redeemStep2: "Data Verification ",
    redeemTitle: "Autoglaze Voucher Redemption",
    redeemMsg1: "Enter the Unique Code ",
    redeemMsg2: "See the unique code in the WhatsApp message we sent you ",
    redeemPlace: "Enter voucher code",
    redeemExmVoucher: "Code consists of 3 letters followed by 4 digits ",
    redeemErrVoucher: "The code you entered is not registered ",
    redeemBtn : "Use Voucher",
    redeemValidVoucher: "The voucher with code {code} is valid for a customer with details below: ",
    redeemHintVoucher: "Please show the data above to the Autoglaze staff for verification before using the voucher. ",
    redeemHeaderVoucher: "Voucher owner's personal data",
    redeemNoWa: "WhatsApp Number",
    redeemEmail: "Email",
    redeemCarPlate: "Car Plate Number ",
    redeemLocation: "Location",
    redeemVerifyBtn: "Proceed to Verification",
    redeemPassCodeTitle: "Staff Verification Code ",
    redeemPassCodeMsg: "Code is provided by Autoglaze staff ",
    redeemPassCodePlaceHolder: "Enter verification code",
    redeemPassCodeError: "Code was not found. Having trouble? Call our Contact Center",
    redeemCallUs : "Need help in claiming vouchers?",
    successRedeemVoucherTitle: "Verification Successful! ",
    successRedeemVoucherMsg: "Autoglaze voucher worth IDR 100,000 can already be used. Hope you enjoy the voucher! ",
    alreadyRedeem : "The code has already been used ",
    thankyouPaymentTitle : "Payment has been confirmed!",
    thankyouPaymentmsg : "Thank you for trusting Roojai in providing your protection need.",
    thankyouPaymentmsg2 : "To activate your policy, you will need to complete some additional information. Kindly click Continue to begin.",
    carOwnerEmail: "Car owner's email",
    mainDriverEmail: "Main driver's email",
    emailNewsletter: "I want to receive newsletter and promotions from Roojai",
    carOwnerPhone: "Car owner's mobile phone number",
    mainDriverPhone: "Main driver's phone number",
    phoneNumberValidText: "A valid number is required for OTP verification when we process your payment.",
    availabilityToCall: "Would you like to receive a call?",
    commercialPurposes: "Is the car used for commercial purpose?",
    registeredAsOwned: "This car is registered under",
    companyName: "Company's Name",
    companyRegistrationNumber: "Company's Registration Number",
    companyRegistrationNumberPlaceholder: "Example: 12345678",
    citizenCarOwner: "Select Car Owner's Nationality",
    citizenMainDriver: "Select Main Driver's Nationality",
    birthDay:"Birth Day",
    submit : "Submit",
    continue : "Continue",
    identificationNumber: "Identification Number",
    identificationNumberMainDriver: "Identification Number of Main Driver",
    passportNumber: "Passport Number",
    passportNumberMainDriver: "Passport Number of Main Driver",
    carOwnerName: "Car Owner/Main Driver's Name",
    carOwnerName2: "Main Driver's Name",
    carOwnerName3: "Car Owner's Name",
    occupation: "Occupation of Car Owner",
    occupation2: "Occupation of Main Driver",
    occupationPlaceholder: "Choose occupation",
    policyDate: "Policy Start Date",
    policyDateText: "Policy start date should be not more than 90 days away from today",
    messagePayment: "After payment, you will be directed to provide additional information and to complete car inspection. In case you do not pass your car inspection, your policy will not be activated and the premium will be refunded in full.",
    messageDealer8year: "Dealer garages options are available for cars under 8 years old.",
    carOwnerTitle: "Car Owner",
    agreeTnc: "I have read and accept the ",
    tnc: "Privacy Policy",
    and: "and the",
    limitationAndLiability: "Limitation of Liability",
    Rewardmsg : "Free Roojai Rewards",
    btnToPayment: "Continue to Payment",
    AnyWS: "Our preferred garages",
    PanelWS: "Dealer garages",
    sumInsuredTitle: "Maximum sum insured",
    sumInsuredValue: "IDR {sumInsured}",
    addCoverage : "We recommend you add this protection to reduce your risk. Do you want to add it?",
    addCoverageOthers : "Do you want to add it?",
    sumInsuredSubtitle: "This will cover you for damages to your vehicle: ",
    optionForDealerWs1: "Your own risk for partial loss will be ",
    optionForDealerWs2: "per event.",
    optionForPanelWs1: "Your own risk for partial loss will be ",
    optionForPanelWs2: "per event.",
    comp: {
        damage: "If you hit another vehicle, or another vehicle hits you",
        crime: "If you crash by yourself (be careful!)",
        stealing: "If you hit an object (or animal!)",
        fire: "In cases of theft, fire, or vandalism",
        crane: "And if your car needs towing after an accident, we will tow it to the nearest garage"
    },
    selectPlans: "Choose the coverage value you want:",
    inputNominal: "Enter the nominal amount you want",
    flood: "Flood and Windstorm",
    floodDesc: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
    earthquake: "Earthquake, Volcanic Eruption, Tsunami",
    earthquakeDesc: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
    trrTitle: "Terrorism & Sabotage",
    trrDesc: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
    srccTitle: "SRCC (strikes, riot, civil commotion)",
    srccDesc: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
    trr: {
        item1: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
    },
    srcc: {
        item1: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
    },
    fl: {
        item1title: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
        item1text: ""
    },
    eq: {
        item1title: "Own Risk: 10% from the loss, minimum IDR 500.000 per event",
        item1text: ""
    },
    openbtnBenefitDetails: "See Coverage Detail",
    closebtnBenefitDetails: "Close Coverage Detail",
    btnAccAdon: "Select car accessories",
    editBtnAccAdon: "Update accessories selection",
    rsaTitle: "Emergency Roadside Assistance",
    rsaDesc: "We'll assist you in emergencies (2 events/year) if your car runs out of fuel, has a flat tire or dead battery, or if you're accidentally locked out of your vehicle.",
    rsa: {
        item1: "We'll assist you in emergencies (2 events/year) if your car runs out of fuel, has a flat tire or dead battery, or if you're accidentally locked out of your vehicle.",
    },
    taTitle: "Transportation Allowance",
    taDesc: "If your car is being repaired at the garage for more than 5 day, the allowance of IDR 150,000/day will be given from day 6 onward, for a max. of 5 days.",
    ta: {
        item1: " IDR 150,000 per day that your car is being repaired at the garage for more than 5 days (given from day 6 onward, for a maximum of 5 days)",
        // item2: " IDR 150,000 per day (given from day 6 onward, for a maximum of 5 days)"
    },
    benefit: {
        repairGarage: "Repair at ",
        dealerGarage: "dealer garages with own risk of IDR 300,000",
        partnerGarage: "our preferred garages with own risk of IDR 300,000",
        thirdParty: "Third party legal liability ",
        personalAccident: "Personal accident coverage ",

    },
    perPerson : " /person",
    carAccessories: "Additional Car Accessories Coverage",
    mainDriverDOB: "Car Owner/Main Driver",
    mainDriverDOB2: "Main Driver",
    ePolicy: "Yes, I want to save the environment by receiving my e-policy",
    ePolicyTitle: "E-policy",
    ePolicyDesc: "Are you sure you want to receive a hardcopy? A surcharge might apply for the hardcopy. E-policy is much faster, safer, easy to access anytime anywhere and environmentally friendly.",
    ePolicyYes: "Yes, I want e-policy and save the environment!",
    ePolicyNo: "No, I need the hardcopy",
    modalAccessoriesTitle: "Choose the additional car accessories you want to cover",
    modalAccessoriesSubtitle: "Coverage worth 10% of the sum insured max. IDR 20,000,000",
    protectionAccessories: "Maximum protection per event",
    countryOrigin : "Country of origin",
    answer: {
        yes: "Yes",
        no: "No",
        purpose1: "Personal Use",
        purpose2: "Business Use (online taxi, goods transport, rental, etc)",
        threeOrMore: "3 or more",
        lessThan1Year: "Less than 1 year",
        sixOrMore: "6 or more",
        yesNow: "Yes, now",
        later: "Later",
        personal: "Individual",
        company: "Company",
        indonesiaCitizen: "Indonesia Nationality",
        foreign: "Other Nationality"
    },
    dobDriver: "Date of birth",
    notCover: {
        fl: "Flood and Windstorm",
        eq: "Earthquake, Volcanic Eruption, Tsunami",
        trr: "Terrorism & Sabotage",
        srcc: "SRCC (strikes, riot, civil commotion)",
        rsa: "Emergency Roadside Assistance",
        ta: "Transport Allowance",
        // acc: "Additional Car Accessories Coverage",
    },
    accessoriess: {
        label1: "Body Kit",
        label2: "Rims & Tires",
        label3: "Solar Film",
        label4: "Entertainment Audio/Video System",
        label5: "Rear/Side Camera",
    },
    million:"{value} Mio ",
    change2preffered3 : "into repair at ",
    change2preffered1 : "Change repair at ",
    change2preffered2 : "dealer garages ",
    change2preffered4 : "our preferred garage?",
    confirmation : "Confirmation",
    change2prefferedYes : "Yes",
    change2prefferedNo : "No",
    accessoriessTitle: "Choose the additional car accessories you want to cover",
    accessoriessTitle2: "Accessories is included in  the total sum insured for the vehicle, and cannot exceed 10% of the total value (max 20 million).",
    maxAccProtection: "Maximum protection per event",
    cancel: "Cancel",
    save: "Save",
    selectedBenefits: "You selected",
    youNotChoose: "You didn't select",
    emailCallmeWord: "Please enter your email to be contacted by our team",
    emailCallmeWordAgent: "Please enter your email to be contacted by Agent",
    emailQuotePopUp: "I would like to receive an email offer",
    emailMe: "Email me",
    messageError:"Oops, something went wrong. It looks like our server is playing up right now. Try again in a bit.",
    accessoriesWarning: "Please select the coverage value",
    accMaximumProtection: "You have reached the maximum protection",
    rewardTitle: "You Get { pointReward } Roojai Rewards Points",
    rewardValue: "Worth IDR { valueReward }",
    rewardBtn: "See Prize Options",
    rewardModalClose: "Close",
    rewardReedem: "You can redeem your points in exchange for the following options:",
    btnEmailQuote: "Email Me",
    labelEmailMe: "Or receive an offer from",
    tooltip1: "This is the small camera that is installed on the dashboard or rearview mirror of a car to record events that occur while the driver is driving.",
    tooltip2: "Please be precise. The mileage will be checked at car inspection.",
    tooltip3: "Information about how your car is used will help us determine your exact premium.",
    tooltip4: "Area code from the license plate will help us determine the best premium for you.",
    tooltip5: "Please fill precisely By knowing this information, we can determine the best protection for you.",
    tooltip6: "This question helps us understand your driving experience.",
    tooltip7: "Knowing the area where the car is parked and driven the most helps us determine your exact premium.",
    tooltipclaim: "The number of prior claims helps identify safe drivers. Your answer to this question will be verified.",
    tooltipMaritial: "Marital status helps us determine your exact premium.",
    tooltipAge: "Your age helps us determine your exact premium.",
    tooltipDriving: "This question helps us understand your driving experience. Driving experience helps us determine your exact premium.",
    pD1tooltip1: "We need your email to send relevant documents and inform you about your quotes, policies and promotions.",
    pD1tooltip2: "We need your phone number to verify your identity before the payment.",
    pD1tooltip3: "Area code from the license plate will help us determine the best premium for you.",
    pD1tooltip4: "ID number is required to verify the identity of policy holder as per OJK regulation.",
    pD1tooltip5: "This information is required in your policy document",
    pD1tooltip6: "Passport number is required to verify the identity of policy holder as per OJK regulation. ",
    pD1tooltip7: "This information is required in your policy document. Please make sure you fill in the information correctly.",
    pD1tooltip8: "This information is required in your policy document. Please make sure to fill in correctly.",
    pD1tooltip9: "This information is required in your policy document. Make sure you the occupation information  fill in is correct.",
    pD1tooltip10: "The date you choose is the start date of the insurance policy to protect your car.",
    instalmentCreditDebitMV: "Available for Credit Card, and Debit Card",
    tooltipReward: "Rewards are awarded in the form of points to customers after they complete the car insurance purchase. Roojai Rewards can be redeemed for Tokopedia or Pertamina vouchers.",
    notCoverTxt: "Not covered",
    addTxt: "Add",
    addSubtitle : "By adding this coverage, you will get:",
    addSubtitleOthers : "Adding this coverage will reduce your risk.",
    addPremi: "Add:",
    minusPremi: "Save:",
    coverage: "Coverage:",
    statusCarOwner: "Status of Car Owner",
    dobCarOwner: "Date of Birth of Car Owner",
    invalidTPLInfo: "You haven't selected any benefits",
    invalidTPLInfo: "You haven't selected any benefits",
    benefitHasBeenAdded: "Benefit has been added",
    part1ContainerLabel: "Choose your garage type and customize your sum insured",
    part2ContainerLabel: "Additional protection for driver and passengers",
    part3ContainerLabel: "Choose extra protection for your car",
    callmeback: {
        phone : {
          title:"Want to talk with our team?",
          desc:"Please enter your phone number to instantly connect with our team.",
          invalid: "Phone number you entered is invalid. ",
          tryagain: "Please try again.",
          cancelbtn: "Cancel",
          sendbtn: "Submit",
          successTitle: "Phone number has been received!",
          successDesc: "Thank you for your interest in Roojai.",
          opsHour1: "Roojai’s operational hours:",
          opsHour2: "Monday - Friday: 08:00 – 19:00",
          opsHour3: "Saturday: 08:00 – 17:00",
          opsHour4: "Public Holiday: Off",
          closebtn: "Close",
        },
        email: {
          title:"Send Quotation to Email",
          desc:"Please enter your email address.",
          placeholder: "Enter email",
          invalid: "Please enter a valid email address",
          sample: "Example: ",
          cancelbtn: "Cancel",
          sendbtn: "Submit",
          successTitle: "Success!",
          successDesc: "Your quotation details have been sent. Please check your inbox or spam folder to find your quotation email.",
          closebtn: "Close",
        }
    },
    garage: {
        findNearestPanelLabel: "Look up the preferred garages closest to you,",
        findNearestDealerLabel: "Look up the dealer garages closest to you,",
        findNearestButton: "here",
        titlePanel: "Preferred Garage",
        titleDealer: "{car} Dealer Garage",
        chooseProvinceLabel: "Choose Province",
        choosedProvinceLabel: "Province",
        infoPanelLabel: "Please choose a province to see the nearest preferred garage",
        infoDealerLabel: "Please choose a province to see the nearest dealer garage",
        findGaragePlaceholder: "Type name or address",
        findGarageButton: "Search",
        infoSearchResult1: "Search result for ",
        infoSearchResult2: "We found ",
        infoSearchResult3: "garages",
        searchProvinceLabel: "Search province",
        emptyTitle1: "Oops! Your search not found",
        emptyDesc1: "We're sorry, there's no Roojai's garage partner yet in the province you've chosen",
        emptyTitle2: "We couldn't find any results for '{keyword}'",
        emptyDesc2: "Please double check your spelling or search for another garage",
        emptyPanelButton: "See Our Preferred Garage",
        emptyDealerButton: "See Dealer Garage",
        emptyAnotherButton: "Search for Other Garages",
        workshopPanel: "Preferred Garage",
        workshopDealer: "Dealer Garage",
    }
};

const id = {
    greetings1Agent: "Gak perlu kasih kontak!",
    greetings2Agent: "Jawab beberapa pertanyaan dan dapatkan harga premimu. Cuma perlu 30 detik.",
    addButton: "Tambah",
    cancelButton: "Batal",
    greetings1: "Gak perlu kasih kontak!",
    greetings2: "Jawab beberapa pertanyaan dan dapatkan harga premimu. Cuma perlu 30 detik.",
    greetings3: "Yuk, lengkapi informasi mobil yang ingin kamu proteksi",
    greetings4: "Penasaran dengan harga premimu? Klik tombol di bawah ini!",
    carBrand: "Apa merek mobilmu?",
    carModel: "Apa model mobilmu?",
    carProductionYear: "Kapan tahun produksi mobilmu?",
    carType: "Apa tipe mobilmu?",
    carRecognize: "Bantu kami mengenali mobil {car} milikmu",
    dashCam: "Apakah mobilmu dilengkapi kamera dashboard?",
    dashNotes: "Catatan: Rekaman kamera dashboard dapat mempermudah proses pengajuan klaim.",
    mileage: "Berapa jarak tempuh mobilmu saat ini?",
    newmileage: "Pilih Jarak Tempuh Saat Ini",
    mileageinstruction1: "Geser atau tap garis di bawah untuk tentukan jarak tempuh mobilmu",
    mileageinstruction2: "Mohon mengisi data dengan benar, karena jarak tempuh akan diperiksa saat inspeksi mobil.",
    mileageOrange: "Mohon mengisi data dengan benar, karena jarak tempuh akan diperiksa saat inspeksi mobil.",
    purposeOfUsage: "Untuk apa kamu menggunakan mobil {car} ini?",
    carForWork: "Apakah mobilmu digunakan untuk pergi/pulang bekerja?",
    claimQuestion: "Apakah ada klaim yang diajukan untuk mobilmu dalam waktu 12 bulan terakhir?",
    threeOrMore: "3 atau lebih",
    carPlate: "Kode area plat mobil",
    carNumPlateQuestion: "Nomor plat mobil",
    carNumPlate: "Contoh: B1234FGH",
    choosePlateArea: "Pilih kode area",
    aboutDriver: "Ceritakan kami tentang pengemudi mobil ini yuk!",
    maritalStatusInfo: "Apa jenis kelamin dan status pernikahan pengemudi?",
    maritalStatus: "Jenis kelamin dan status pernikahan",
    maritalStatus_singleman: "Pria lajang",
    maritalStatus_marriedman: "Pria sudah menikah",
    maritalStatus_singlewoman: "Wanita lajang",
    maritalStatus_marriedwoman: "Wanita sudah menikah",
    dob: "Kapan tanggal lahir pengemudi?",
    drivingLicense: "Sudah berapa lama pengemudi memiliki SIM?",
    driverLiving: "Dimana tempat tinggal pengemudi?",
    askPostalCode: "Silakan isi kode pos area domisili pengemudi",
    premiumCallUs: "Butuh bantuan cek harga premi online?",
    calculatePremium: "Hitung Premi",
    continueToNextstep: "Dengan klik tombol di atas, berarti kamu sudah memastikan bahwa semua informasi yang kamu berikan adalah benar. Jika terdapat perbedaan, maka harga premi bisa berubah.",
    installmentOption: "Opsi Cicilan",
    rewardsInfoHeader: "Bonus Roojai Rewards: ",
    rewardsInfoHeader2: "Setara ",
    quotationNo: "Nomor penawaran",
    switchYearly:"Tahunan",
    disc13: "Disk 6%",
    premi: "Premi",
    switchMonthly:"Cicilan",
    year: "tahun",
    month: "bulan",
    close: "Tutup",
    poin: "Poin",
    back: "Kembali",
    currencySymbol: "Rp",
    packagesBlueCard1: "Proteksi maksimal untuk mobilmu dari berbagai kerusakan",
    packagesBlueCard2: "Tambah coverage asuransi mobilmu yuk!",
    packagesBlueCard3: "Perlu proteksi untuk pengemudi & penumpang juga?",
    packagesTitle1: "Tanggung Jawab Hukum Pihak Ketiga",
    packagesDescription1: "Jika kamu harus bertanggung jawab atas kerugian yang dialami orang lain (seperti: kerusakan properti atau biaya pengobatan) karena kecelakaan, manfaat ini akan menjamin biaya ganti rugi tersebut.",
    packagesTitle2: "Kecelakaan Diri",
    packagesDescription2: "Jika kamu mengalami kecelakaan ketika berkendara, manfaat ini akan menjamin biaya pengobatan pengemudi dan maks. empat penumpang yang mengalami cidera.",
    chooseWorkshop: "Bengkel rekanan kami terdiri dari bengkel umum dan bengkel resmi.",
    questionWorkshop: "Jika mengalami kecelakaan, di mana kamu ingin melakukan perbaikan mobil?",
    questionSumInsured: "Mau tentukan harga pertanggungan sendiri?",
    msgSumInsured: "Silakan pilih harga pertanggungan yang cocok untuk mobilmu. Mengubah harga pertanggungan akan memengaruhi harga premi.",
    selectPlanPA : "Pilih nilai pertanggungan (per orang):",
    answerShowcallMeYes:"Terimakasih, customer service kami akan menghubungi kamu sebentar lagi.",
    answerShowcallMeLater : "Terimakasih, customer service kami akan menghubungi kamu pada saat jam makan siang atau setelah jam kerja.",
    partnerRepairShop: "Cek bengkel umum terdekat",
    dealerRepairShop: "Cek bengkel resmi terdekat",
    here: "di sini",
    recomendedForYou: "Proteksi ekstra untuk kamu",
    popUpRewardRecomend1: "Pilih perbaiki di bengkel umum untuk dapatkan poin Roojai Rewards senilai ",
    popUpRewardRecomend2: "",
    rewardMessage1: "Pilih ",
    rewardMessage2: "bengkel umum ",
    rewardMessage3: "untuk dapatkan poin Roojai Rewards senilai ",
    rewardMessage4: "",
    emailThankyouTitle: "Terima penawaran melalui email",
    emailThankyouAgent: "Penawaran kamu sudah dikirimkan ke e-mail agen. Jika kamu sudah siap untuk membeli, klik 'Lanjut' untuk ke proses selanjutnya.",
    emailThankyou: "Penawaran kamu sudah dikirimkan ke e-mail. Silakan cek inbox atau folder spam di emailmu, atau klik 'Lanjut' untuk ke proses selanjutnya.",
    UpdaterewardMessage1: "Pilih perbaiki ",
    UpdaterewardMessage2: "bengkel umum ",
    UpdaterewardMessage3: "untuk dapatkan poin Roojai Rewards senilai ",
    UpdaterewardMessage4: "",
    addCoverage : "Menambah manfaat bisa mengurangi risiko saat berkendara. Setuju untuk tambah manfaat ini?",
    addCoverageOthers : "Setuju untuk tambah manfaat ini?",
    askSumInsure: "Mau tentukan harga pertanggungan sendiri? Contact Center kami bisa membantumu!",
    Rewardmsg : "Bonus Roojai Rewards",
    btnRewardMessage : "Ubah",
    sumInsured: "Uang Pertanggungan Rp {sumInsured}",
    sumInsuredLabel: "Ringkasan Penawaran Untukmu",
    sumInsuredLabel2: "Mobil yang Ditanggung",
    popUpGarages: "Kami menemukan {garagesFound} bengkel terdekat berdasarkan kode posmu",
    preferredPanel: "Bengkel Umum",
    preferredDealer: "Bengkel resmi",
    update: "Ubah",
    next: "Lanjut",
    chatWhatsapp: "Chat Saya via Whatsapp",
    callMe: "Hubungi Saya",
    callUs: "Hubungi Kami",
    voucherTitle:"Ambil Voucher Cuci Mobil Gratis Kamu Sekarang!",
    voucherMsg:"Voucher ",
    voucherHint:"Berlaku untuk semua layanan",
    voucherCust:"Mohon pastikan nomor plat mobil yang kamu masukkan sudah benar. Kode voucher hanya berlaku untuk mobil dengan nomor plat yang kamu berikan.",
    voucherWA:"Masukkan nomor WhatsApp ",
    voucherWAerror:"Mohon masukkan nomor Whatsapp-mu ",
    voucherEmail:"Masukkan alamat email ",
    voucherEmailError:"Mohon masukkan alamat email mu ",
    voucherCarPlate:"Masukkan nomor plat mobilmu ",
    voucherCarPlateError:"Mohon masukkan nomor plat mobilmu ",
    voucherEmailExmple:"Contoh: vira{'@'}gmail.com",
    voucherPhoneExmple:"Pastikan nomor WhatsApp-mu aktif. Voucher akan dikirimkan via WhatsApp. Satu voucher berlaku untuk satu nomor WA dan satu nomor plat mobil. ",
    voucherCarPlateExmple:"Contoh: B1234DEF",
    redeemVoucher: "Tukar Voucher",
    cancelVoucher: "Tidak, Terima Kasih",
    noteVoucher: "Catatan: Kode voucher tidak dapat dikirimkan jika nomor handphone/plat mobil sudah pernah digunakan untuk menukar voucher. ",
    voucherErrTitle : "Ups!",
    voucherErrPhone: "Nomor WhatsApp-mu sudah pernah digunakan. Satu nomor WhatsApp hanya boleh menukar satu voucher. Terima kasih atas pengertiannya.",
    voucherErrCarPlate: "Nomor plat mobilmu sudah pernah digunakan. Satu nomor plat mobil hanya boleh menukar satu voucher. Terima kasih atas pengertiannya.",
    voucherErrButton : "Oke, Mengerti",
    voucherSuccTitle : "Terima Kasih!",
    voucherSuccMsg: "Kamu akan menerima kode voucher melalui WhatsApp dalam beberapa menit. Tim Contact Center kami juga akan segera menghubungimu.",
    voucherSuccButton : "Tutup",
    resendOTP: "Kirim ulang",
    titleVoucherOTP: "Verifikasi OTP",
    voucherOTP: "Mohon masukkan kode OTP yang kami kirimkan ke nomor WhatsApp di bawah ini ",
    voucherMsgOTP: "Kirim ulang kode OTP dalam ",
    verifyOTP : "Verifikasi",
    firstNamePlaceholder: "Nama Depan",
    lastNamePlaceholder: "Nama Belakang",
    companyNamePlaceholder: "Masukkan Nama Perusahaan",
    identificationNumberPlaceholder: "Contoh: 12345678",
    identificationNumberMainDriver: "Nomor KTP Pengemudi Utama",
    passportNumberPlaceholder: "Contoh: 12345678",
    stepperFirst: "Pendaftaran",
    stepperSecond: "Pembayaran",
    stepperThird: "Isi Informasi Tambahan",
    stepperFourth: "Inspeksi Mobil",
    stepperFifth : "Polis Aktif",
    paymentConfirmed: "Pembayaran Berhasil!",
    paymentConfirmedMsg: "Terima kasih atas pembayaranmu! Mohon lengkapi informasi dibawah ini untuk melanjutkan ke tahap inspeksi mobil. Apabila kamu tidak berhasil melalui tahap inspeksi maka polismu tidak dapat diaktifkan dan seluruh biaya akan di kembalikan",
    questionUnderFinancing : "Apakah { brand } { model } milikmu masih dalam pembiayaan/cicilan?",
    companyUnderFinancing : "Silakan pilih nama perusahaan pembiayaannya",
    companyUnderFinancingPlaceholder : "Pilih perusahaan",
    questionchassisNumber : "Nomor rangka (lihat di STNK)",
    chassisNumberPlaceholder : "Masukan Nomor Rangka",
    questionUnderInsurance : "Apakah saat ini mobilmu dicover oleh asuransi lain?",
    questionCompanyInsurance : "Apa nama perusahaan asuransinya?",
    companyInsurancePlaceholder : "Pilih perusahaan asuransi",
    typeInsurancePlaceholder : "Pilih Tipe Asuransi Mobil",
    questionTypeInsurance : "Apa jenis asuransi mobilmu saat ini?",
    questionExpireInsurance : "Kapan polis asuransi mobilmu akan berakhir?",
    completeAddress : "Sedikit lagi selesai! Mohon lengkapi alamatmu saat ini",
    zipcode : "Kode pos",
    province : "Provinsi",
    provincePlaceholder : "Pilih Provinsi",
    districtPlaceholder : "Pilih Kota",
    subdistrictPlaceholder : "Pilih Kecamatan",
    district : "Kota",
    subdistrict : "Kecamatan",
    roadName : "Nama Jalan",
    roadNamePlaceholder : "Masukan Nama Jalan",
    houseNumberPlaceholder : "Masukan Nomor Rumah/Bangunan",
    houseNumber : "Nomor rumah/bangunan",
    submit : "Kirim",
    continue : "Lanjutkan",
    redeemStep1: "Masukkan Kode Voucher ",
    redeemStep2: "Verifikasi Data ",
    redeemTitle: "Autoglaze Voucher Redemption",
    redeemMsg1: "Masukkan Kode Unik ",
    redeemMsg2: "Lihat kode unik pada pesan WhatsApp yang dikirimkan oleh Roojai ",
    redeemTitle: "Penukaran Voucher Autoglaze",
    redeemPlace: "Masukkan kode voucher ",
    redeemExmVoucher: "Kode terdiri dari 3 huruf dan 6 digit angka ",
    redeemErrVoucher: "Kode yang kamu masukkan tidak terdaftar ",
    redeemBtn : "Gunakan Voucher",
    redeemValidVoucher: "Kode voucher {code} berlaku untuk pelanggan dengan data berikut: ",
    redeemHintVoucher: "Mohon tunjukkan data di atas kepada staf Autoglaze untuk dilakukan verifikasi data sebelum menggunakan voucher. ",
    redeemHeaderVoucher: "Data diri pemilik voucher",
    redeemNoWa: "Nomor WhatsApp",
    redeemEmail: "Email",
    redeemCarPlate: "Nomor Plat Mobil",
    redeemLocation: "Lokasi",
    redeemVerifyBtn: "Lanjut untuk Verifikasi",
    redeemPassCodeTitle: "Kode Verifikasi Petugas ",
    redeemPassCodeMsg: "Kode diisi oleh petugas Autoglaze ",
    redeemPassCodePlaceHolder: "Masukkan kode verifikasi",
    redeemPassCodeError: "Kode tidak ditemukan. Mengalami kendala? Hubungi Contact Center kami",
    redeemCallUs : "Butuh bantuan dalam klaim voucher?",
    successRedeemVoucherTitle:"Verifikasi Berhasil!",
    successRedeemVoucherMsg:"Voucher Autoglaze senilai Rp100.000 sudah dapat digunakan. Selamat menikmati! ",
    alreadyRedeem : "Kode sudah pernah digunakan ",
    thankyouPaymentTitle : "Pembayaran terkonfirmasi!",
    thankyouPaymentmsg : "Terima kasih telah mempercayakan Roojai untuk memenuhi kebutuhan proteksimu.",
    gobackhome: "Kembali ke Halaman Awal",
    thankyou2PaymentTitle : "Selesai!",
    thankyou2Paymentmsg : "Datamu berhasil kami terima. Selanjutnya kamu bisa melakukan inspeksi mobil agar polismu dapat segera diaktifkan. Silakan cek emailmu untuk detail informasinya.",
    callingInspection : "Hubungi Kami untuk Jadwalkan Inspeksi Mobil",
    openInspection : "Inspeksi mobil dapat dilakukan pada Senin – Sabtu pukul 08:00 – 17:00, Hari Libur Nasional: Off",
    thankyouPaymentmsg2 : "Agar polismu dapat diaktifkan, kamu perlu melengkapi informasi tambahan. Silakan klik Lanjutkan untuk mulai.",
    messageError: "Ups, terjadi kesalahan. Sepertinya server kami sedang terkendala sekarang. Coba lagi beberapa saat.",
    questionMainDriver: "Apakah pemilik mobil adalah pengemudi utama?",
    carOwnerEmail: "Email pemilik mobil",
    mainDriverEmail: "Email Pengemudi Utama",
    emailNewsletter: "Saya ingin menerima newsletter dan promosi dari Roojai",
    carOwnerPhone: "Nomor telepon pemilik mobil",
    mainDriverPhone: "Nomor telepon Pengemudi Utama",
    phoneNumberValidText: "Mohon mengisi nomor telepon yang valid untuk mendapatkan nomor OTP yang diperlukan saat pembayaran.",
    availabilityToCall: "Apakah kamu bersedia untuk kami telepon?",
    commercialPurposes: "Apakah mobilmu digunakan untuk keperluan komersial?",
    registeredAsOwned: "Mobil ini terdaftar sebagai milik",
    countryOrigin : "Negara asal",
    companyName: "Nama Perusahaan",
    messageWarningAcc:"Harap tidak melebihi Rp. { value } untuk total pertanggungan aksesoris mobil",
    ePolicy: "Saya mau menyelamatkan bumi dengan menerima polis digital (e-policy)",
    ePolicyTitle: "E-polis",
    ePolicyDesc: "Yakin mau menerima polis cetak? Kamu bisa dikenakan biaya jika memilih polis cetak. Dengan memilih polis digital, kamu bisa akses polis kapan saja, dimana saja selama 24/7.",
    ePolicyYes: "Ya, saya mau e-polis dan selamatkan bumi!",
    ePolicyNo: "Tidak, saya perlu polis cetak",
    companyRegistrationNumber: "Nomor Registrasi Perusahaan",
    companyRegistrationNumberPlaceholder: "Contoh: 12345678",
    citizenCarOwner: "Pilih Warga Negara Pemilik Mobil",
    citizenMainDriver: "Pilih Warga Negara Pengemudi Utama",
    birthDay:"Tanggal Lahir",
    identificationNumber: "Nomor KTP",
    passportNumber: "Nomor Paspor",
    passportNumberMainDriver: "Nomor Paspor Pengemudi Utama",
    messagePayment: "Setelah pembayaran, kamu perlu memberikan informasi tambahan yang dibutuhkan dan menyelesaikan inspeksi kendaraan. Apabila kamu tidak lolos inspeksi kendaraan, maka polismu tidak akan diaktifkan dan premi yang telah bayar akan dikembalikan seutuhnya.",
    messageDealer8year: "Bengkel resmi tersedia untuk mobil berusia di bawah 8 tahun.",
    carOwnerName: "Nama Pemilik Mobil/Pengemudi Utama",
    carOwnerName2: "Nama Pengemudi Utama",
    carOwnerName3: "Nama Pemilik Mobil",
    occupation: "Pekerjaan Pemilik Mobil",
    occupation2: "Pekerjaan Pengemudi Utama",
    occupationPlaceholder: "Pilih pekerjaan",
    policyDate: "Tanggal Polis Dimulai",
    policyDateText: "Pastikan tanggal polis dimulai tidak lebih dari 90 hari dari hari ini",
    carOwnerTitle: "Pemilik Mobil",
    agreeTnc: "Saya telah membaca dan menerima",
    tnc: "Kebijakan Privasi",
    and: "serta",
    limitationAndLiability: "Batasan Tanggung Jawab",
    btnToPayment: "Lanjut ke Pembayaran",
    AnyWS: "Bengkel umum",
    PanelWS: "Bengkel resmi",
    sumInsuredTitle: "Harga pertanggungan maksimum ",
    sumInsuredValue: "Rp {sumInsured}",
    sumInsuredSubtitle: "Asuransi mobil ini menjamin kerugian yang terjadi pada mobilmu:",
    optionForDealerWs1: "Biaya risiko sendiri untuk kerugian sebagian sebesar ",
    optionForDealerWs2: " per kejadian",
    optionForPanelWs1: "Biaya risiko sendiri untuk kerugian sebagian sebesar ",
    optionForPanelWs2: " per kejadian",
    comp: {
        damage: "Jika kamu menabrak atau ditabrak kendaraan lain",
        crime: "Jika kamu mengalami kecelakaan tunggal",
        stealing: "Jika kamu menabrak benda (atau mungkin hewan)",
        fire: "Jika mobilmu dicuri, mengalami kebakaran, atau perusakan",
        crane: "Dan jika mobilmu perlu diderek karena kecelakaan, kami akan menyediakan layanan mobil derek ke bengkel terdekat"
    },
    selectPlans: "Pilih nilai pertanggungan:",
    inputNominal: "Masukkan Nominal Yang Kamu Inginkan",
    flood: "Banjir dan Angin Topan",
    floodDesc: "Risiko sendiri: 10% dari kerugian, min. Rp500.000 untuk satu kali kejadian",
    earthquake: "Gempa Bumi, Gunung Meletus, dan Tsunami",
    earthquakeDesc: "Risiko sendiri: 10% dari kerugian, min. Rp500.000 untuk satu kali kejadian",
    trrTitle: "Terorisme & Sabotase",
    trrDesc: "Risiko sendiri: 10% dari kerugian, min. Rp500.000 untuk satu kali kejadian",
    srccTitle: "Huru-Hara",
    srccDesc: "Risiko sendiri: 10% dari kerugian, min. Rp500.000 untuk satu kali kejadian",
    trr: {
        item1: "Risiko Sendiri: 10% dari kerugian, minimal Rp500.000 untuk satu kali kejadian."
    },
    srcc: {
        item1: "Risiko Sendiri: 10% dari kerugian, minimal Rp500.000 untuk satu kali kejadian."
    },
    fl: {
        item1title: "Risiko Sendiri: 10% dari kerugian, minimal Rp500.000 untuk satu kali kejadian.",
        item1text: ""
    },
    eq: {
        item1title: "Risiko Sendiri: 10% dari kerugian, minimal Rp500.000 untuk satu kali kejadian.",
        item1text: ""
    },
    openbtnBenefitDetails: "Lihat Coverage Detail",
    closebtnBenefitDetails: "Tutup Coverage Detail",
    btnAccAdon: "Pilih aksesoris mobil",
    editBtnAccAdon: "Perbarui pilihan aksesoris",
    rsaTitle: "Emergency Roadside Assistance",
    rsaDesc: "Kamu akan mendapatkan layanan bantuan darurat (2 kali/tahun), seperti kehabisan bahan bakar, ban kempes, baterai lemah, atau kunci mobil tertinggal di dalam mobil.",
    rsa: {
        item1: "Kamu akan mendapatkan layanan bantuan darurat (2 kali/tahun), seperti kehabisan bahan bakar, ban kempes, baterai lemah, atau kunci mobil tertinggal di dalam mobil.",
    },
    taTitle: "Uang Transportasi",
    taDesc: "Jika mobilmu diperbaiki di bengkel lebih dari 5 hari, uang transportasi sebesar Rp150.000/hari diberikan pada hari ke 6 dan seterusnya hingga maksimal 5 hari.",
    ta: {
        item1: "Uang transportasi sebesar Rp150.000/hari jika mobilmu diperbaiki di bengkel lebih dari 5 hari (diberikan pada hari ke 6, dan seterusnya hingga maksimal 5 hari)",
        // item2: "Rp150.000 per hari (diberikan mulai hari ke-6 dst, maks. 5 hari)"
    },
    benefit: {
        repairGarage: "Perbaiki di ",
        partnerGarage: "bengkel umum rekanan Roojai dengan risiko sendiri Rp300.000",
        dealerGarage: "bengkel resmi rekanan Roojai dengan risiko sendiri Rp300.000",
        thirdParty: "Tanggung jawab hukum pihak ketiga {value}",
        personalAccident: "Jaminan kecelakaan diri ",
    },
    perPerson : " /orang",
    carAccessories: "Jaminan Aksesoris Tambahan",
    mainDriverDOB: "Pemilik Mobil/Pengemudi Utama",
    mainDriverDOB2: "Pengemudi Utama",
    modalAccessoriesTitle: "Pilih Aksesoris yang ingin Dijaminkan",
    modalAccessoriesSubtitle: "Jaminan senilai 10% dari harga pertanggungan (maks. Rp20.000.000)",
    protectionAccessories: "Proteksi maksimum per kejadian",
    answer: {
        yes: "Ya",
        no: "Tidak",
        purpose1: "Keperluan Pribadi",
        purpose2: "Keperluan Bisnis (taksi online, transport barang, rental, dll)",
        threeOrMore: "3 atau lebih",
        lessThan1Year: "Kurang dari 1 tahun",
        sixOrMore: "6 atau lebih",
        yesNow: "Ya, sekarang",
        later: "Nanti saja",
        personal: "Perorangan",
        company: "Perusahaan",
        indonesiaCitizen: "Warga Negara Indonesia",
        foreign: "Warga Negara Asing"
    },
    dobDriver: "Tanggal lahir",
    notCover: {
        fl: "Banjir dan Angin Topan",
        eq: "Gempa Bumi, Gunung Meletus, dan Tsunami",
        trr: "Terorisme & Sabotase",
        srcc: "Huru-Hara",
        rsa: "Emergency Roadside Assistance",
        ta: "Uang Transport",
        // acc: "Jaminan Aksesoris Tambahan Mobil",
    },
    accessoriess: {
        label1: "Body Kit",
        label2: "Pelek & Ban",
        label3: "Kaca Film",
        label4: "Audio/Video Sistem untuk Hiburan",
        label5: "Kamera Belakang/Samping",
    },
    million:"{value} Juta ",
    change2preffered1 : "Ubah perbaiki ",
    change2preffered2 : "bengkel resmi ",
    change2preffered3 : "menjadi perbaiki ",
    change2preffered4 : "bengkel umum?",
    confirmation : "Konfirmasi",
    change2prefferedYes : "Ya",
    change2prefferedNo : "Tidak",
    accessoriessTitle: "Pilih aksesoris mobil tambahan yang ingin dijaminkan",
    accessoriessTitle2: "Aksesoris termasuk di dalam uang pertanggungan kendaraan, dan tidak melebihi 10% dari jumlah uang pertanggungan (maksimal 20 juta)",
    maxAccProtection: "Proteksi maksimum per kejadian",
    cancel: "Batal",
    save: "Simpan",
    selectedBenefits: "Manfaat pilihanmu",
    youNotChoose: "Kamu tidak memilih",
    titleModalSumInsured:"Pilih Lagi Harga Pertanggungan",
    msgModalSumInsured:"Mengubah jenis bengkel rekanan memengaruhi pilihan Harga Pertanggungan untukmu.",
    msgWarnSumInsured:"Pilihan Harga Pertanggungan menyesuaikan jenis bengkel yang kamu pilih",
    emailCallmeWord: "Silahkan masukan email kamu untuk dihubungi oleh tim kami",
    emailCallmeWordAgent: "Silahkan masukan email kamu untuk dihubungi oleh Agen",
    emailQuotePopUp: "Saya ingin menerima email penawaran",
    emailMe: "Email saya",
    accessoriesWarning: "Mohon pilih nilai pertanggungan",
    accMaximumProtection: "Anda telah mencapai perlindungan maksimum",
    rewardTitle: "Kamu Mendapatkan { pointReward } Poin Roojai Rewards",
    rewardValue: "Setara Rp{ valueReward }",
    rewardBtn: "Lihat Pilihan Hadiah",
    rewardModalClose: "Tutup",
    rewardReedem: "Poinmu dapat ditukarkan dengan pilihan voucher berikut:",
    btnEmailQuote: "Email Saya",
    labelEmailMe: "Atau terima penawaran dari",
    tooltip1: "Ini adalah kamera kecil yang dipasang pada bagian dashboard atau spion mobil untuk merekam kejadian selama pengemudi berkendara.",
    tooltip2: "Jarak tempuh akan diperiksa saat inspeksi mobil. Pastikan kamu mengisi data dengan benar.",
    tooltip3: "Informasi ini dibutuhkan untuk menentukan harga premi. Pastikan kamu mengisi dengan benar.",
    tooltip4: "Kode area dari plat mobil dibutuhkan untuk menentukan harga premi terbaik untukmu.",
    tooltip5: "Mohon diisi dengan tepat. Dengan mengetahui informasi ini, kami dapat menentukan proteksi terbaik untukmu. ",
    tooltip6: "Pertanyaan ini membantu kami mengetahui pengalaman mengemudimu.",
    tooltip7: "Informasi ini membantu kami menentukan harga premi berdasarkan lokasi di mana pengemudi biasanya parkir dan berkendara.",
    tooltipclaim: "Jumlah pengajuan klaim membantu kami menentukan seberapa aman kamu berkendara. Jawaban ini akan diverifikasi.",
    tooltipMaritial: "Status pernikahanmu membantu kami menentukan harga premi.",
    tooltipAge: "Mengetahui usiamu membantu kami menentukan harga premi untukmu.",
    tooltipDriving: "Pertanyaan ini membantu kami mengetahui pengalamanmu mengemudi.  Pengalaman mengemudi dapat mempengaruhi harga premi.",
    pD1tooltip1: "Kami memerlukan email kamu untuk mengirimkan penawaran, dokumen polis dan promosi.",
    pD1tooltip2: "Kami memerlukan nomor ponsel kamu yang akan digunakan untuk verifikasi identitas sebelum pembayaran.",
    pD1tooltip3: "Kode area dari plat mobil dibutuhkan untuk menentukan harga premi terbaik untukmu.",
    pD1tooltip4: "Nomor KTP diperlukan untuk verifikasi identitas pemegang polis, menurut peraturan OJK.",
    pD1tooltip5: "Informasi ini diperlukan untuk dicantumkan dalam buku polis",
    pD1tooltip6: "Nomor paspor diperlukan untuk verifikasi identitas pemegang polis, menurut peraturan OJK.",
    pD1tooltip7: "Informasi ini diperlukan untuk dicantumkan dalam buku polis. Pastikan kamu mengisi informasi yang tepat.",
    pD1tooltip8: "Informasi ini diperlukan untuk dicantumkan dalam buku polis. Pastikan kamu mengisi dengan benar.",
    pD1tooltip9: "Informasi ini diperlukan untuk dicantumkan dalam buku polis. Pastikan kamu mengisi informasi pekerjaan yang tepat.",
    pD1tooltip10: "Tanggal yang kamu pilih adalah tanggal mulai polis asuransi untuk memproteksi mobilmu.",
    instalmentCreditDebitMV: "Tersedia untuk Kartu Kredit, dan Debit",
    tooltipReward: "Reward akan diberikan setelah pembelian produk asuransi mobil dalam bentuk poin. Roojai Rewards dapat ditukarkan untuk voucher Tokopedia atau Pertamina.",
    notCoverTxt: "Tidak ditanggung",
    addTxt: "Tambah",
    addSubtitle : "Memilih manfaat ini, kamu akan mendapatkan:",
    addSubtitleOthers : "Memilih manfaat ini bisa mengurangi risiko saat berkendara.",
    addPremi: "Tambah premi:",
    minusPremi: "Hemat premi:",
    coverage: "Pertanggungan:",
    statusCarOwner: "Status Pemilik Mobil",
    dobCarOwner: "Tanggal Lahir Pemilik Mobil",
    invalidPAMEInfo: "Kamu belum memilih manfaat",
    invalidTPLInfo: "Kamu belum memilih manfaat",
    benefitHasBeenAdded: "Manfaat berhasil ditambahkan",
    part1ContainerLabel: "Pilih jenis bengkel dan tentukan harga pertanggungan",
    part2ContainerLabel: "Proteksi tambahan untuk pengendara & penumpang",
    part3ContainerLabel: "Pilih proteksi ekstra untuk mobilmu",
    callmeback : {
        phone: {
          title : "Ingin berbicara langsung dengan tim kami?",
          desc : "Silakan masukkan nomor teleponmu dan tim kami akan segera menghubungimu.",
          invalid : "Nomor yang kamu masukkan tidak valid.",
          tryagain : "Silakan coba lagi.",
          cancelbtn : "Batal",
          sendbtn : "Kirim",
          successTitle : "Nomor telepon telah kami terima!",
          successDesc : "Terima kasih atas kepercayaanmu terhadap Roojai.",
          opsHour1 : "Waktu operasional Roojai:",
          opsHour2 : "Senin - Jumat: 08:00 – 19:00",
          opsHour3 : "Sabtu: 08:00 – 17:00",
          opsHour4 : "Hari Libur Nasional: Off",
          closebtn : "Tutup",
        },
        email : {
          title : "Kirim Penawaran ke Email",
          desc : "Silakan masukkan alamat emailmu.",
          placeholder : "Masukkan email",
          invalid : "Mohon masukkan email yang valid",
          sample : "Contoh : ",
          cancelbtn : "Batal",
          sendbtn : "Kirim",
          successTitle : "Sukses!",
          successDesc : "Penawaran kamu sudah dikirim. Cek inbox atau folder spam untuk temukan email penawaranmu.",
          closebtn : "Tutup",
        },
    },
    garage: {
        findNearestPanelLabel: "Cek bengkel umum terdekat.",
        findNearestDealerLabel: "Cek bengkel resmi terdekat",
        findNearestButton: "di sini",
        titlePanel: "Bengkel Umum",
        titleDealer: "Bengkel Resmi {car}",
        chooseProvinceLabel: "Pilih Provinsi",
        choosedProvinceLabel: "Provinsi",
        infoPanelLabel: "Silakan pilih provinsi untuk lihat bengkel umum terdekat",
        infoDealerLabel: "Silakan pilih provinsi untuk lihat bengkel resmi terdekat",
        findGaragePlaceholder: "Cari nama atau alamat",
        findGarageButton: "Cari",
        infoSearchResult1: "Hasil pencarian untuk ",
        infoSearchResult2: "Kami menemukan ",
        infoSearchResult3: "bengkel",
        searchProvinceLabel: "Cari provinsi",
        emptyTitle1: "Ups! Pencarianmu tidak ditemukan",
        emptyDesc1: "Sayang sekali, belum terdapat bengkel rekanan Roojai di provinsi yang kamu pilih",
        emptyTitle2: "Pencarianmu untuk '{keyword}' tidak ditemukan",
        emptyDesc2: "Pastikan ejaanmu sudah benar atau cari bengkel lainnya",
        emptyPanelButton: "Lihat Bengkel Umum",
        emptyDealerButton: "Lihat Bengkel Resmi",
        emptyAnotherButton: "Cari Bengkel Lainnya",
        workshopPanel: "Bengkel Umum",
        workshopDealer: "Bengkel Resmi",
    }
};

export default { en, id }