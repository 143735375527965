export const policyObject = {
    "root": {
      "quoteData": {
        "brokerInfo":{
          "intermediaryAccountID":"",
          "intermediaryReptID":"",
          "intermediaryReptEmail":""
        },
        "abTestingVersion": null,
        "endDate": null, 
        "postalCode": null,
        "channel": null,
        "googleClientId": null,
        "prefLang": null,
        "previousCoverType": null,
        "priceBookEntryId": "",
        "onlineScreen": null,
        "mastersetId": "",
        "customerIpAddress": null,
        "quoteNumber": null,
        "insuredData": {
          "lastName": "",
          "gender": null,
          "maritialStatus": null,
          "drivingExperience": null,
          "prefLang": null,
          // "percentage": null,
          "recordTypeName": "Person Account",
          "driverAccidents": null,
          "residentStatus": null,
          "occupation": null,
          "occupationGroup": null,
          "accountExternalId": null,
          "firstName": "",
          "dob": null,
          "driverAge": null,
          "email": "",
          "thaiIdNumber": "",
          "phoneNumber": null,
          "passPortNumber": null,
          "countryOfIssuance": null,
          "businessRegNumber": null,
          "contactAddresses": [],
        },
        // "primaryCampaign": null,
        "ePolicyDocument": null,
        "sendEmailFlag": false,
        "startDate": null,
        "currentPolicyExpiryDate": null,
        "opportunityNumber": "",
        "flagAutoRenewal" : false,
        // "opportunityNumber": null,
        "callMeBackRequest": null,
        "callMeBack": false,
        "flagAutoRenewal" : false,
        "renewalNumber" : "0",
        "quoteLineItemsData": [
          {
            "coverCode": null,
            "productId": null,
            "selectedOption": null,
            "additionalCoverageName1": null,
            "additionalCoveragePremium1": null,
            "additionalCoverageSumAssured1": null,
            "additionalCoverageName2": null,
            "additionalCoveragePremium2": null,
            "additionalCoverageSumAssured2": null,
            "additionalCoverageName3": null,
            "additionalCoveragePremium3": null,
            "additionalCoverageSumAssured3": null,
            "additionalCoverageName4": null,
            "additionalCoveragePremium4": null,
            "additionalCoverageSumAssured4": null,
            "additionalCoverageName5": null,
            "additionalCoveragePremium5": null,
            "additionalCoverageSumAssured5": null,
            "additionalCoverageName6": null,
            "additionalCoveragePremium6": null,
            "additionalCoverageSumAssured6": null,
            "additionalCoverageName7": null,
            "additionalCoveragePremium7": null,
            "additionalCoverageSumAssured7": null,
            "additionalCoverageName8": null,
            "additionalCoveragePremium8": null,
            "additionalCoverageSumAssured8": null, 
            "policyNetPremium": null,
            "policyStampDuty": null,
            "policyVAT": null,
            "policyGrossPremium": null,
            "policyCommissionAmount": null,
            "transNetPremium": null,
            "transStampDuty": null,
            "transVAT": null,
            "transGrossPremium": null,
            "transCommissionAmount": null,
            "annualNetPremium": null,
            "annualStampDuty": null,
            "annualVAT": null,
            "annualGrossPremium": null,
            "annualCommissionAmount": null
          }
        ],
        "previousInsurer": "",
        "policyHolderData": {
          "lastName": "",
          // "otherRelationship": null,
          "gender": null,
          "maritialStatus": null,
          "drivingExperience": null,
          "prefLang": null,
          "recordTypeName": "Person Account",
          "driverAccidents": null,
          "residentStatus": null,
          "PersonHasOptedOutOfEmail": false,
          "email": "",
          "thaiIdNumber": "",
          "phoneNumber": null,
          "passPortNumber": null,
          "countryOfIssuance": null,
          "businessRegNumber": null,
          "contactAddresses": [
            {
              "subDistrictCode": null,
              "subDistrict": null,
              "postalCode": null,
              "postalCodeText": null,
              "districtCode": null,
              "district": null,
              "provinceCode": null,
              "province": null,
              "houseNumber": "",
              "externalId": null,
              "village": "",
              "soi": ""
            }
          ],
          "accountExternalId": null,
          "firstName": "",
          "dob": null
        },
        "productType": "MotorCar",
        "abTestingVersion": null,
        "additionalInsuredData": [],
  
        "vehicleData": {
          // "redbookId": "a1d9D000005iuC4QAI",
          "redbookId": null,
          "flagCarFinancing": null,
          "financingCompany": null,
          "vehicleKey": null,
          "yearOfOwnership": null,
          "vehicleSumInsured": null,
          "vehiclePrice": null,
          "vehicleTypeCode" : null,
          "bodyStyle": null,
          "uwPremiumClass": null,
          "uwPremiumGroup": null,
          "modelFamily": null,
          "voluntaryType": null,
          "carPlateNumber": "",
          "newPrice": null,
          "derivedNCB": null,
          "assetExternalId": null,
          "workShopType": null,
          "carPlateProvince": null,
          "carOwnerShip": null,
          "yearlyMileage": null,
          "power": null,
          "driverPlan": null,
          "carAccessoryCarCamera": null,
          "make": null,
          "isMainDriverPH": true,
          "parkingLocation": null,
          "odometerReading": null,
          "yearOfManufacture": null,
          "vehicleUsage": null,
          //"planType": "Type 1",
          "adjustedVehicleSumInsured": null,
          "familyCode": null,
          "engineNumber": null,
          "accessoriesData": [],
          "isCarModified": "",
          "chassisNumber": "",
          "seatCapacity": null,
          "kerbWeight": null,
          "policyType": null,
          "engineSize": null,
          "carAccessoriesSI": null,
          "declaredNCB": null,
          "howLongInsured": null
        },
        "googleClickId": null,
        "onlineStage": null,
        "isActivatePolicy": false,
        "transactionType": "New Biz",
        "approvedCode": null,
        "paymentData": {
          "isPmntInitiatedBySF": false,
          "agentId": null,
          "paidAmount":0,
          "flagPaymentConfirmed":null,
          "flagDeferredPayment":false,
          "paymentMethod": null,
          "paymentFrequency": null,
          "paymentDetailsData" : [],
          "creditCard" : null
        },
        "howLongInsured": null                    
      }
    }
  }