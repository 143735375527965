const en = {
    mainTitlePrivacyMv: "Privacy Policy",
    informationCollectionMv: "Personal Information Collection",
    P1: {
        title: "What We Collect?",
        content: "What personal information we collect from you (“discloser”) depends on your relationship with us at the time of collection, i.e. insured, claimant, or prospect, as well as your request – the information of which may include the following:",
        points: {
            1: {
                title: "Personal Details",
                content: "This may include name, date of birth, age, gender, marital status, and occupation."
            },
            2: {
                title: "Contact Information",
                content: "This may include address, phone number, telephone/mobile number, email address, and social media."
            },
            3: {
                title: "Financial Information",
                content: "This may include your bank account number, credit history, income and other financial details"
            },
            4: {
                title: "Health Information",
                content: "This may include, height, weight, medical record, other insurance policies held, hospitalization in the past, smoking habits, pre-existing conditions, family medical history, and other information you have given to us related to your or your family medical history."
            },
            5: {
                title: "Vehicle Information",
                content: "This may include vehicle brand, vehicle type, vehicle production’s year, vehicle’s accessories, engine number, chassis number, vehicle identification number (surat tanda nomor kendaraan or STNK) or its equivalent, certificate of vehicle ownership (buku pemilik kendaraan bermotor or BPKB) or its equivalent, driver’s driving license (surat izin mengemudi or SIM) or its equivalent, and other details."
            }
        }
    },
    P2: {
        title: "How We Collect?",
        content: "Your personal information will be collected and stored, directly or indirectly, when:",
        points: {
            1: {
                content: "You enter our website;",
            },
            2: {
                content: "You disclose to us;"
            },
            3: {
                content: "Any persons related to you or appointed by you (including but not limited to your spouse or family member, a person whose name is under the same policy as yours, and any other persons under the purpose of validation of application, claim, and compensation) discloses to us, and",
            },
            4: {
                content: "Relevant third parties (including but not limited to our insurer, other insurers and financial institutions, regulatory bodies e.g. Financial Services Authority of the Republic of Indonesia (Otoritas Jasa Keuangan or OJK), Directorate General of Tax of the Ministry of Finance of the Republic of Indonesia (DGT), Directorate General of Population and Civil Registration of the Ministry of Home Affairs of the Republic of Indonesia (DGPCR) , Indonesian Financial Transaction Reports and Analysis Center (Pusat Pelaporan dan Analisis Transaksi Keuangan or PPATK), Indonesian National Police (Kepolisian Negara Republik Indonesia or POLRI), and other reliable sources e.g. Indonesian General Insurance Association (Asosiasi Asuransi Umum Indonesia or AAUI) or Indonesian Life Insurance Association (Asosiasi Asuransi Jiwa Indonesia or AAJI)). In the case of (c), when the information concerning about other persons (“data subject”) than the discloser is given to us, it is the sole responsibility of the discloser to ask for a consent from and notify the data subject of the disclosure of his/her personal information and the terms and conditions of this Privacy Policy."
            },
            5: {
                content: "You perform vehicle inspection with us."
            }
        }
    },
    P3: {
        title: "Why We Collect and How We Use and Secure?",
        content: "Why We Collect and How We Use and Secure:",
        points: {
            1: {
                content: "To provide you with our services and products which shall include performing our relevant contractual or regulatory obligations"
            },
            2: {
                content: "To enhance our existing services and products and develop new services and products"
            },
            3: {
                content: "To communicate with you about our existing or up-coming services or products, including but not limited to, terms and conditions, promotion, activities, and advertisement"
            },
            4: {
                content: "For Website administration and business operation and"
            },
            5: {
                content: "To be a reference that we could rely on, for the purpose of fulfilling obligations that we are subject to, by collecting your personal data as per the requirements under the law such as the applicable insurance laws, personal data protection laws and other related laws."
            }
        }
    },
    P4: {
        title: "For your security, we:",
        points: {
            1: {
                content: "Use both encryption and tokenization technology to safeguard your sensitive information such as credit card number, which can only be accessed by an authorized person"
            },
            2: {
                content: "Restrict the access of your personal information to only our employees who require such access"
            },
            3: {
                content: "Prevent unauthorized access by regular update of technology; and"
            },
            4: {
                content: "Delete your personal information when it is no longer necessary for relevant aforementioned purposes."
            }
        }
    },
    P5: {
        title: "How Long We Retain",
        content: "We will retain your personal information as long as it is necessary for the purposes of collection and use, unless required or permitted by applicable law. For most of the cases, your personal information will be retained for five years after the date of termination or cancellation of your policy, whereas for financial information related to you, such as the amount of payment of premiums, claims and other ancillary payments, as per the applicable laws and regulations on Corporate Documents, will be retained for at least ten years after the date of termination or cancellation of your policy."
    },
    P6: {
        title: "Third Party Access",
        content: "In order to perform our contractual obligations towards you or our regulatory obligations towards regulators and authorities, your personal information may be disclosed, and/or disseminated to third parties for the relevant mentioned purposes only. These third parties may include insurers, reinsurers, our business partners, financial institutions, regulators e.g. OJK, authorities e.g. DGT, PPATK, POLRI, or other relevant government institutions, other service providers e.g. data storage service providers, call centre service providers, First Notice of Loss service providers, payment system provider or payment gateway provider, and repair shops, hospitals, health clinics, other health service providers, repair garage, claim system provider, and salvage buyer.   Moreover, in order to improve your experience in using our Website and traffic optimization during access by users, we will also track your behaviour using analytical tools. These analytical tools will be provided by third-party provider that we have engaged, and we hereby reserve the right to add, stop or change existing provider to other third party analytical tools provider. The information that we have gathered for this purpose will be anonym and shall not be identifiable to you. For our business operation, where our legitimate interest is not less important that the data subject’s fundamental rights, your personal information may be disclosed and/or disseminated to our group companies and third parties such as external auditors, advisors, survey/data analytics service providers, and investors."
    },
    P7: {
        title: "In any case, your personal information will not be disclosed and/or disseminated to any other persons other than the cases of the above two paragraphs, without your consent, except for the following cases:",
        points: {
            1: {
                content: "providing that it is required by any applicable law;"
            },
            2:  {
                content: "provided that it is necessary for preventing emergencies or protecting others from danger;"
            },
            3: {
                content: "for the public interest."
            }
        },
        content: "In case where we disclose and/or disseminate your personal information to any third parties, we shall notify the said third parties of its confidential nature and their obligations to restrict the use of such information to any person involved for the permitted purposes as necessary only, and to handle the information appropriately in accordance with this Privacy Policy and the applicable laws and regulations in Indonesia concerning personal data protection"
    },
    P8: {
        title: "Cross-border Transfer",
        content: "In order to provide you with our services, especially for documentation, your personal information will be stored in Indonesia, whereas other non-personal information will be stored outside Indonesia with the encryption and tokenization method mentioned above. None of your personal information with direct reference to you will be stored abroad. In any case, we will take any necessary steps to ensure that your personal information is stored and secured appropriately."
    },
    P9: {
        title: "YOUR RIGHTS AS A DATA SUBJECT",
        content: "Subject to the applicable laws and regulations in Indonesia concerning personal data protection, by using our Website, receiving our services and/or purchasing our products, the protection of your personal data will cover the following process.",
        points: {
            1: {
                title: "Procurement and Collection",
                content: "In accordance with the applicable laws and regulations on personal data protection applicable in Indonesia, procurement and collection of personal data will be carried out by respecting your rights as the owner of the personal data, which will be carried out by providing options for confidentiality or non-confidentiality of your personal data, as well as other provision of options to change, add, or update the personal data. In this matter, procurement and collection of your personal data by us will be based on your consent, or according to the applicable laws and regulations."
            },
            2: {
                title: "Processing and Analyzing",
                content: "In accordance with the applicable laws and regulations on personal data protection applicable in Indonesia, processing and analyzing or your personal data will only cover matters that we have clearly stated when procuring or collecting your personal data. This will be based on your consent."
            },
            3: {
                title: "Storing",
                content: "In accordance with the applicable laws and regulations on personal data protection applicable in Indonesia, your personal data will be stored at least 5 (five) years since you are no longer becoming a user in our website. As mentioned above, your personal data will be stored in data center and data recovery center placed within the territory of the Republic of Indonesia. Other non-personal data, may be stored outside the territory of the Republic of Indonesia, by using tokenization and encryption so that the data that we stored outside the territory of the Republic of Indonesia will not be identifiable to you."
            },
            4: {
                title: "Displaying, publishing, transmitting, disseminating, and/or access opening",
                content: "In accordance with the applicable laws and regulations on personal data protection applicable in Indonesia, activity of displaying, publishing, transmitting, disseminating, and/or access opening of your personal data, will be done based on your consent."
            },
            5: {
                title: "Destroying",
                content: "In accordance with the applicable laws and regulations on personal data protection applicable in Indonesia, your personal data will be destroyed when it has passed the Storing provision that we mention above, or it you request the destruction of your data."
            }
        }
    },
    P10: {
        title: "COOKIES INFORMATION",
        title2: "What are Cookies?",
        content: "Cookies are small pieces of data sent from a website to be stored in your personal devices. They allow the Website to recognize your devices and collect information to adjust the Website content to be in accordance with your preference. We use cookies through third-party service providers to improve your experience while visiting our website.",
        points: {
            title: "What Cookies do We Use?",
            content: "We use the following types of cookies:",
            points: {
                1: {
                    title: "Strictly Necessary Cookies",
                    content: "These cookies are necessary to help make the website function properly, by enabling basic functions such as page navigation. The storage duration is one year (persistent)."
                },
                2: {
                    title: "Statistic Cookies",
                    content: "These cookies track your behaviour of usage in order to know how many times you access certain functions of or your customer journey on our website. The storage duration is between 1-2 years (persistent). In some cases, we might use both types of cookies through third-party service providers."
                },
                3:  {
                    title: "Managing Cookies",
                    content: "You can manage, block, and delete cookies on your browsers. You can learn more about cookies and how to manage them from this link: Allaboutcookies."
                }
            }
        }
    },
    P11: {
        title: "EXTERNAL LINKS",
        content: "This Website may contain links to other websites which we cannot ensure your privacy and security. The inclusion of such links does not guarantee that other websites will provide you with the same standard of personal data protection as per this Privacy Policy."
    },
    P12: {
        title: "CHANGES TO THE PRIVACY POLICY",
        content: "We reserve the right to make any change to the Privacy Policy in order to comply with any applicable law. Hence, we encourage you to check the “Updated Date” of the Privacy Policy."
    },
    P13: {
        title: "CONTACT US",
        content: "If you have any query, comments, or recommendations about the Privacy Policy please submit it to our email {email}. The process might take up to 30 days from the date of your email submission."
    }
};

const id = {
    mainTitlePrivacyMv: "Kebijakan Privasi",
    informationCollectionMv: "Pengumpulan Informasi Pribadi",
    P1: {
        title: "Apa yang kami kumpulkan?",
        content: "Informasi pribadi apa yang kami peroleh dari kamu (“pengungkap”) bergantung pada hubungan kamu dengan kami pada saat pengumpulan data, yaitu tertanggung, penggugat, atau calon pelanggan, serta permintaan kamu – informasinya dapat mencakup hal-hal berikut:",
        points: {
            1: {
                title: "Informasi Pribadi",
                content: "Hal ini termasuk nama, tanggal lahir, usia, jenis kelamin, status perkawinan, dan pekerjaan."
            },
            2: {
                title: "Kontak Pribadi",
                content: "Hal ini termasuk alamat, nomor ponsel, nomor telfon/handphone, alamat email, dan media sosial."
            },
            3: {
                title: "Data Finansial",
                content: "Hal ini termasuk nomor rekening bank, riwayat kredit, penghasilan, dan detail keuangan lainnya."
            },
            4: {
                title: "Informasi Kesehatan",
                content: "Hal ini termasuk berat badan, tinggi badan, rekam medis, polis asuransi lain yang dimiliki, riwayat/sejarah rawat inap, kebiasaan merokok, keadaan kesehatan yang sudah ada sebelumnya (pre-existing conditions), riwayat/sejarah medis keluarga, dan informasi lain yang kamu berikan kepada kami sehubungan dengan riwayat/sejarah kesehatan kamu atau keluarga kamu."
            },
            5: {
                title: "Informasi Kendaraan",
                content: "Hal ini termasuk merek kendaraan, tipe kendaraan, tahun pembuatan, aksesoris, nomor mesin, nomor rangka, plat nomor, STNK atau yang setara dengan itu, BPKB atau yang setara dengan itu dan SIM pengemudi atau yang setara dengan itu, serta detail lainnya."
            }
        }
    },
    P2: {
        title: "Bagaimana kami mengumpulkan hal tersebut?",
        content: "Informasi pribadi kamu akan dikumpulkan dan disimpan, secara langsung atau tidak langsung, ketika:",
        points: {
            1: {
                content: "Kamu membagikannya kepada kami",
            },
            2: {
                content: "Kamu membagikannya kepada kami"
            },
            3: {
                content: "Setiap orang yang terkait dengan kamu atau ditunjuk oleh kamu (termasuk namun tidak terbatas pada pasangan atau anggota keluarga kamu, orang yang namanya berada di bawah polis yang sama denganmu, dan orang lain dengan tujuan validasi permohonan, klaim, dan kompensasi) mengungkapkan kepada kami, dan",
            },
            4: {
                content: "Pihak ketiga yang relevan (termasuk namun tidak terbatas pada perusahaan asuransi kami, perusahaan asuransi atau lembaga keuangan lainnya, badan pengawas seperti Otoritas Jasa Keuangan (OJK), Direktorat Jenderal Pajak pada Kementerian Keuangan Republik Indonesia (DJP), Direktorat Jenderal Kependudukan dan Catatan Sipil pada Kementerian Dalam Negeri Republik Indonesia (DUKCAPIL), Pusat Pelaporan dan Analisis Transaksi Keuangan (PPATK), Kepolisian Negara Republik Indonesia (POLRI) dan sumber terpercaya lainnya, seperti Asosiasi Asuransi Umum Indonesia (AAUI), Asosiasi Asuransi Jiwa Indonesia (AAJI)). Dalam hal (c), ketika informasi mengenai orang lain ('subjek data') dari pengungkap diberikan kepada kami, merupakan tanggung jawab tunggal pengungkap untuk meminta persetujuan dari dan memberi tahu subjek data dari pengungkapan informasi pribadinya dan syarat dan ketentuan Kebijakan Privasi ini."
            },
            5: {
                content: "Kamu melakukan inspeksi kendaraan bermotor bersama kami. "
            }
        }
    },
    P3: {
        title: "Mengapa kami mengumpulkan dan bagaimana kami menggunakan dan mengamankan data tersebut?",
        content: "Informasi pribadi kamu dapat dikumpulkan dan digunakan untuk tujuan berikut:",
        points: {
            1: {
                content: "Untuk menyediakan layanan dan produk kami yang mencakup pelaksanaan kewajiban kontrak kami atau peraturan perundang-undangan yang relevan;"
            },
            2: {
                content: "Untuk meningkatkan layanan dan produk kami yang ada dan mengembangkan layanan dan produk baru;"
            },
            3: {
                content: "Untuk berkomunikasi dengan kamu tentang layanan atau produk kami yang ada atau yang akan datang"
            },
            4: {
                content: "Untuk administrasi website dan operasi bisnis dan"
            },
            5: {
                content: "Untuk menjadi acuan yang dapat kami percayai, untuk tujuan pemenuhan kewajiban yang mana kami harus tunduk padanya, dengan mengumpulkan data pribadi kamu sebagaimana yang disyaratkan dalam peraturan perundang-undangan seperti undang-undang asuransi, perundang-undangan perlindungan data pribadi, dan perundang-undangn terkait lainnya yang berlaku."
            }
        }
    },
    P4: {
        title: "Demi keamanan kamu, kami:",
        points: {
            1: {
                content: "Menggunakan teknologi enkripsi dan tokenisasi untuk melindungi informasi sensitif kamu seperti nomor kartu kredit atau kartu debit yang hanya dapat diakses oleh orang yang berwenang"
            },
            2: {
                content: "Membatasi akses informasi pribadi kamu hanya kepada karyawan kami yang memerlukan akses tersebut;"
            },
            3: {
                content: "Mencegah akses yang tidak sah dengan memperbarui teknologi secara teratur; dan"
            },
            4: {
                content: "Menghapus informasi pribadi kamu ketika tidak lagi diperlukan untuk tujuan yang disebutkan di atas."
            }
        }
    },
    P5: {
        title: "Berapa lama kami menyimpan informasi?",
        content: "Kami akan menyimpan informasi pribadi kamu selama diperlukan untuk tujuan pengumpulan dan penggunaan, kecuali diwajibkan atau diizinkan oleh hukum yang berlaku. Untuk sebagian besar kasus, informasi pribadi kamu akan disimpan selama lima tahun setelah tanggal pengakhiran atau pembatalan polis kamu, sedangkan untuk informasi keuangan sehubungan dengan kamu, seperti jumlah pembayaran premi, klaim, dan pembayaran lainnya, sesuai dengan peraturan perundang-undangan mengenai Dokumen Perusahaan, akan disimpan untuk setidaknya sepuluh tahun setelah tanggal pengakhiran atau pembatalan polis asuransi kamu."
    },
    P6: {
        title: "Akses Pihak Ketiga",
        content: "Untuk melaksanakan kewajiban kontraktual kami terhadap kamu atau kewajiban peraturan kami terhadap regulator dan otoritas, informasi pribadi kamu dapat diungkapkan, dan/atau disebarluaskan kepada pihak ketiga hanya untuk tujuan yang disebutkan terkait. Pihak ketiga ini mungkin termasuk perusahaan asuransi, reasuradur, mitra bisnis kami, lembaga keuangan, regulator, mis.OJK, pihak berwenang mis. DJP, PPATK, POLRI, atau instansi pemerintah lainnya, penyedia layanan lain mis. penyedia layanan penyimpanan data, penyedia layanan call center, penyedia layanan First Notice of Loss, penyedia jasa pembayaran atau penyedia payment gateway, rumah sakit, klinik kesehatan, penyedia jasa kesehatan lainnya, bengkel, penyedia jasa sistem klaim, dan salvage buyer.   Kemudian, untuk tujuan meningkatkan experience kamu dalam menggunakan website kami dan optimisasi traffic dalam akses oleh pengguna, kami juga akan melacak perilaku kamu dengan menggunakan peralatan analytical. Peralatan analytical ini akan disediakan oleh penyedia pihak ketiga yang kami tunjuk, dan kami dengan ini berhak untuk menambah, menghentikan atau mengubah penyedia yang ada saat ini menjadi penyedia jasa analytical pihak ketiga lainnya. Informasi yang kami kumpulkan untuk tujuan ini akan bersifat anonim dan tidak dapat diidentifikasikan kepada kamu. Untuk operasi bisnis kami, di mana kepentingan sah kami tidak kalah pentingnya dengan hak dasar subjek data, informasi pribadi kamu dapat diungkapkan dan/atau disebarluaskan kepada perusahaan grup kami dan pihak ketiga seperti auditor eksternal, penasihat, penyedia layanan survei/analisis data , dan investor."
    },
    P7: {
        title: "Dalam hal apa pun, informasi pribadi kamu tidak akan diungkapkan dan/atau disebarluaskan kepada orang lain selain kasus-kasus dari dua paragraf di atas, tanpa persetujuan kamu, kecuali untuk kasus-kasus berikut:",
        points: {
            1: {
                content: "Dengan ketentuan bahwa hal itu diwajibkan oleh peraturan perundang-undangan yang berlaku;"
            },
            2:  {
                content: "Dengan ketentuan bahwa hal itu diperlukan untuk mencegah keadaan darurat atau melindungi orang"
            },
            3: {
                content: "Untuk kepentingan umum."
            }
        },
        content: "Jika kami mengungkapkan dan/atau menyebarkan informasi pribadi kamu kepada pihak ketiga mana pun, kami akan memberi tahu pihak ketiga tersebut tentang sifat rahasianya dan kewajiban mereka untuk membatasi penggunaan informasi tersebut kepada siapa pun yang terlibat untuk tujuan yang diizinkan sebagaimana diperlukan saja, dan untuk menangani informasi dengan tepat sesuai dengan Kebijakan Privasi ini dan peraturan perundang-undangan yang berlaku di Indonesia mengenai perlindungan data pribadi."
    },
    P8: {
        title: "Transfer lintas batas",
        content: "Untuk memberikan kamu layanan kami terutama untuk dokumentasi, informasi pribadi kamu akan disimpan di Indonesia, sedangkan informasi non-pribadi lainnya akan disimpan di luar Indonesia dengan metode enkripsi dan tokenisasi yang disebutkan sebelumnya. Tidak ada informasi pribadi kamu dengan acuan langsung kepada kamu yang akan disimpan di luar negeri. Meskipun demikian, kami akan mengambil langkah apa pun yang diperlukan untuk memastikan bahwa informasi pribadi kamu disimpan dan diamankan dengan tepat."
    },
    P9: {
        title: "HAK KAMU SEBAGAI SUBJEK DATA",
        content: "Tunduk pada peraturan perundang-undangan yang berlaku di Indonesia mengenai perlindungan data pribadi, dengan menggunakan website kami, menerima layanan kami dan/atau membeli produk kami, perlindungan data pribadi kamu akan mencakup proses sebagai berikut:",
        points: {
            1: {
                title: "Perolehan dan Pengumpulan",
                content: "Sesuai dengan peraturan perundang-undangan di bidang perlindungan data pribadi yang berlaku di Indonesia, perolehan dan pengumpulan data pribadi dilakukan dengan menghormati hak kamu sebagai pemilik data pribadi yang dilakukan dengan cara melakukan penyediaan pilihan atas kerahasiaan atau ketidakrahasiaan data pribadi kamu, serta penyediaan pilihan untuk melakukan perubahan, penambahan, atau pembaruan data pribadi. Dalam hal ini, perolehan dan pengumpulan data pribadi kamu oleh kami akan dilakukan berdasarkan persetujuan kamu, atau berdasarkan peraturan perundang-undangan yang berlaku."
            },
            2: {
                title: "Pengolahan dan penganalisisan",
                content: "Sesuai dengan peraturan perundang-undangan di bidang perlindungan data pribadi yang berlaku di Indonesia, pengolahan dan penganalisisan data pribadi kamu dilakukan hanya mencakup hal-hal yang telah kami nyatakan jelas pada saat memperoleh dan mengumpulkan data pribadi. Hal ini akan dilakukan berdasarkan persetujuan kamu."
            },
            3: {
                title: "Penyimpanan",
                content: "Sesuai dengan peraturan perundang-undangan di bidang perlindungan data pribadi yang berlaku di Indonesia, data pribadi kamu akan disimpan setidaknya selama 5 (lima) tahun setelah kamu selesai menjadi pengguna dalam website kami. Sebagaimana disebutkan di atas, data pribadi kamu akan disimpan dalam pusat data dan pusat pemulihan bencana yang ditempatkan di wilayah Republik Indonesia. Data-data non-pribadi lain, dapat kami simpan di luar wilayah Republik Indonesia, dengan menggunakan tokenisasi dan enkripsi sehingga data yang kami simpan di luar wilayah Republik Indonesia tersebut tidak dapat diidentifikasikan kepada kamu."
            },
            4: {
                title: "Menampilkan, mengumumkan, mengirimkan, menyebarluaskan dan/atau membuka akses",
                content: "Sesuai dengan peraturan perundang-undangan di bidang perlindungan data pribadi yang berlaku di Indonesia, kegiatan menampilkan, mengumumkan, mengirimkan, menyebarluaskan dan/atau membuka akses atas data pribadi kamu, akan dilakukan berdasarkan persetujuan kamu."
            },
            5: {
                title: "Pemusnahan",
                content: "Sesuai dengan peraturan perundang-undangan di bidang perlindungan data pribadi yang berlaku di Indonesia, data pribadi kamu akan dimusnahkan apabila telah melampaui ketentuan Penyimpanan yang disebutkan di atas, atau apabila kamu meminta pemusnahan tersebut kepada kami."
            }
        }
    },
    P10: {
        title: "INFORMASI COOKIE",
        title2: "Apa itu Cookie?",
        content: "Cookie adalah potongan kecil data yang dikirim dari website untuk disimpan di perangkat pribadi kamu. Cookie mengizinkan website untuk mengenali perangkat kamu dan mengumpulkan informasi untuk menyesuaikan konten website agar sesuai dengan preferensi kamu. Kami menggunakan cookie melalui penyedia layanan pihak ketiga untuk meningkatkan pengalamanmu saat mengakses website kami.",
        points: {
            title: "Cookie Apa yang Kami Pakai?",
            content: "Kami menggunakan jenis cookie berikut:",
            points: {
                1: {
                    title: "Cookie yang Sangat Diperlukan (Strictly Necessary Cookies)",
                    content: "Cookie ini diperlukan untuk memastikan website berfungsi secara baik, dengan menyediakan berbagai fungsi dasar seperti halaman navigasi. Durasi penyimpanan Cookie ini adalah satu tahun (persisten)."
                },
                2: {
                    title: "Cookie Statistik",
                    content: "Cookie ini melacak kebiasaanmu dalam menggunakan website, salah satunya untuk mengetahui berapa kali kamu mengakses fungsi tertentu di website kami. Lama penyimpanan antara 1-2 tahun (persisten). Dalam beberapa kasus, kami mungkin menggunakan kedua jenis cookie melalui penyedia layanan pihak ketiga."
                },
                3:  {
                    title: "Pengelolaan Cookie",
                    content: "Kamu dapat mengelola, memblokir, dan menghapus cookie di browser kamu. Pelajari lebih lanjut tentang cookie dan cara mengelolanya dari link ini: Allaboutcookies."
                }
            }
        }
    },
    P11: {
        title: "LINK EKSTERNAL",
        content: "Website ini mungkin berisi tautan ke website lain yang tidak dapat kami pastikan privasi dan keamanan kamu. Adanya tautan tersebut tidak menjamin bahwa website lain akan memberi kamu standar perlindungan data pribadi yang sama sesuai dengan Kebijakan Privasi ini."
    },
    P12: {
        title: "PERUBAHAN KEBIJAKAN PRIVASI",
        content: "Kami berhak untuk membuat perubahan apa pun pada Kebijakan Privasi untuk mematuhi hukum yang berlaku. Oleh karena itu, kamu perlu memeriksa 'Tanggal Diperbarui' Kebijakan Privasi."
    },
    P13: {
        title: "HUBUNGI KAMI",
        content: "Jika kamu memiliki pertanyaan, komentar, atau rekomendasi tentang Kebijakan Privasi silakan kirimkan pertanyaan atau masukanmu ke email kami di {email}. Prosesnya akan membutuhkan waktu hingga 30 hari sejak tanggal email diterima"
    }
};

export default { en, id }