import { reactive } from "vue";
import motorVehicle from "./motor-vehicle";

export default reactive({
  firstPage: "/",
  restServer: {
    url: process.env.VUE_APP_BACKEND_URL,
    username: process.env.VUE_APP_BACKEND_USERNAME,
    password: process.env.VUE_APP_BACKEND_PASSWORD,
    token: ""
  },
  roojaiService: {
    url: process.env.VUE_APP_MICROSERVICE_URL,
    username: process.env.VUE_APP_MICROSERVICE_USERNAME,
    password: process.env.VUE_APP_MICROSERVICE_PASSWORD,
    token: "",
    rjses: null
  },
  odataService: {
    url: process.env.VUE_APP_ODATA_URL,
    username: process.env.VUE_APP_ODATA_USERNAME,
    password: process.env.VUE_APP_ODATA_PASSWORD,
    token: ""
  },
  serverTime: "",
  steps: [],
  policyStartDate: "",
  defaultPreSelected: "",
  ciLabel: "",
  endorsementEffectiveDate: "",
  productModule: "",
  policyEndorsementType: "New Biz",
  policyRenewalInviteCount: "0",
  policyEndDate: "",
  packages: null,
  abtest: "",
  rewardID: "",
  locale:"id",
  isValidated: {
    postcode: null
  },
  selectedPackages: {},
  productQuestions: [],
  questionResponses: [],
  quote: {
    postalCode: "",//geo postal code
    channel: "",
    onlineStage: "",
    onlineScreen: "",
    prefLang: "id",
    isHighestSumInsured : false,
    tentativeStartDate: "Other",
    mastersetId: "",
    googleClientID: "",
    ePolicyDocument: "",
    abTestingVersion: "",
    googleClickId: "",
    opportunityNumber: "TBA",
    sendEmailFlag: false,
    productType: "",
    createdFrom: "Web",
    totalPremium: 0,
    isUpdate: false,
    Start_date: null,
    End_date: null,
    taxDeduction: "No",
    salary: "",
    preferredOption: "",
    approvedCode: ""
  },
  opp: {
    paymentFrequency: "",
    paymentMethod: ""
  },
  phaccount: {
    firstName: "",
    lastName: "",
    email: "",
    PersonHasOptedOutOfEmail: true,
    sendWaStatus: '',
    thaiIdNumber: "",
    phoneNumber: "",
    residentStatus: "",
    passPortNumber: "",
    countryOfIssuance: "",
    dob: "",
    driverAge: "",
    gender: "",
    maritialStatus: "",
    height: "",
    weight: "",
    smoker: "",
    postalCode: "",
    province: "",
    provinceCode: "",
    district: "",
    districtCode: "",
    subDistrictCode: "",
    subDistrict: "",
    soi: "",
    village: "",
    houseNumber: "",
    moo: "",
    occupation: {},
    Educational_Qualification: "",//"Primary or Junior High", "Senior High", "University"
    Company_Revenue: "",//"Less than 1 Bio", "1-10 Bio", "More than 10 Bio"
  },
  omisePublicKey: "",
  payment: {
    isPmntInitiatedBySF: false,
    agentId: ""
  },
  beneficiary: [],
  paymentID: 0,
  paymentMethod: 'Credit Card',
  VAinfo:{
    bank: '',
    totalPayment: 0,
    accountNumber: '',
    frequency: ''
  },
  debitcardInfo: {
    response: null
  },
  renewalPrices:{
    twelveMonthlyInstallmentAmnt: 0,
    totalTwelveMonthlyPremium: 0,
    totalTransactionPremium: 0,
  },
  finalPrice: 0,
  totalPremiumRadar: 0,
  isOTPValid: false,
  countries: [],
  insurers: [],
  coverTypes: [],
  gaLandingPageHistory: [],
  finalNCB : 0,
  finalNcbPct : 0,
  lyPaymentFrequency:"",
  lyPaymentLoading: "",
  suspectFraud: null,
  productPageDisplay: null,
  brokerInfo: {
    firstname: "",
    lasstname: "",
    intermediaryReptID: "",
    intermediaryReptEmail: "",
    intermediaryAccountID: "",
    accountSource: "",
    rid: null,
    useInstallment: false,
    productType: null
  },
  renewalPackages: {},
  paymentCompleted: false,
  isTeleselling: false,
  isRetrieveToPayment: false,
  VirtualAccount: [],
  province: {},
  city: {},
  district: {},
  subDistrict: {},
  provinces: [],
  districts: [],
  subDistricts: [],
  subDistrictsList: [],
  roojaiURL: "https://roojai.co.id",
  enableCoverList: [],
  sfmcsService: {
    url: process.env.VUE_APP_SFMCS_URL,
    token: ""
  },
  decryptReferralLink:"",
  useUrl: null,
  isAgentTraditionalBroker: false,
  isAgentCustomerView: false,
  productSorting:null,
  productList:[],
  isSuperCI: false,
  packageFirstLoad: true,
  radarCallType: "1",
  occupations: null,
  occupationsV2: null,
  enumProductType: {
    PA: "Personal Accident",
    HCP: "Hospital Cash Benefit",
    Health: "Health",
    MV: "MotorCar"
  },
  PAminInstallmentTotalPremium: 360000,
  CIminInstallmentTotalPremium: 1200000,
  HCPminInstallmentTotalPremium: 500000000,
  paymentFrequency: '12 months, no deposit',
  coverName: {
    PA: "Protection-PA",
    HCP: "Hospital Daily Cash"
  },
  isCustomerHaveHighestSumInsured: null,
  isMockup: false,
  defaultSumAssured: "",
  productOptionName: "",
  productOptionNo: "",
  percentageSubAdditionalCoverage: null,
  productAffiliate: null,
  productConfigBefore: null,
  productConfig: null,
  productConfigData : [
    {
      param: "health",
      productType: "Health",
      route: { // config by sequence impact goBack()
        basicinfo: "/ci-input",
        packages: "/packages",
        policydetails1: "/policydetails1",
        payment: "/payment",
        thankyou: "/thankyou",
        thankyou2: "/thankyou2",
        blockpolicy: "/blockpolicy",
        policydetails2: "/policydetails2",
        review: "/review",
        waitingpayment: "/waitingpayment",
        debitcard: "/debitcard"
      },
      showAllPackage: null
    },
    {
      param: "pa",
      productType: "Personal Accident",
      route: {
        basicinfo: "/pa-input",
        packages: "/pa-packages",
        policydetails1: "/pa-policydetails1",
        payment: "/pa-payment",
        thankyou: "/pa-thankyou",
        thankyou2: "/pa-thankyou2",
        blockpolicy: "/pa-blockpolicy",
        policydetails2: "/pa-policydetails2",
        review: "/pa-review",
        waitingpayment: "/pa-waitingpayment",
        debitcard: "/pa-debitcard"
      }
    },
    {
      param: "hcp",
      productType: "Hospital Cash Benefit",
      route: {
        basicinfo: "/hcp-input",
        packages: "/hcp-packages",
        policydetails1: "/hcp-policydetails1",
        payment: "/hcp-payment",
        thankyou: "/hcp-thankyou",
        thankyou2: "/hcp-thankyou2",
        blockpolicy: "/hcp-blockpolicy",
        policydetails2: "/hcp-policydetails2",
        review: "/hcp-review",
        waitingpayment: "/hcp-waitingpayment",
        debitcard: "/hcp-debitcard"
      }
    }
  ],
  utmSource: "insure",
  tp: "nb",
  isTestCaseCreditCard: false,
  testCaseCreditCard : null,
  resultCreateVa: [],
  responseCreateVa: null,
  encryptedOpportunityNumber: "",
  premiumToPay: 0,
  xenditPublicKey: null,
  accountExternalId: 0,
  popUpProofofIncome: false,
  HCPminProofofIncome: 1000000,
  PAminProofofIncome: 1000000,
  motorVehicle,
  carSubModel: null,
  ciProductToPick: null,
  showModalChangeToYearly: false,
  validateRenewalPayment: null,
  currentPendingInstallment: null,
  sfAccountId: null,
});
